/*
    THIS FILE IS LOADED BY OLD FORUM APP PAGES
    It's used to style the open days widget only in them pages.
    Widgets in the clean structure (showthread v2) use the styles as set out in the service documentation
 */

.opendays > .title {
    background: none;
    padding: 0;
}

.opendays > .title h3 {
    font-size: 18px;
    line-height: 26px;
    font-family: Gibson, Arial, sans-serif;
    font-weight: 700;
    color: #444444;
}

.opendays .item-list {
    font-family: Arial, sans-serif;
    font-size: 14px;
    list-style: none;
    margin: 0;
    padding: 0;
    border-top: 2px solid #C5C5C5;
    border-bottom: 2px solid #C5C5C5;
}

.opendays .item-list > li {
    border-top: 1px dotted #E8E8E8;
    padding: 0 0 8px;
}

.opendays .item-list > li:first-child {
    border-top: none;
}

.opendays .item-list > li > a,
.opendays .item-list > li > a:link {
    color: #666;
    display: block;
    text-decoration: none;
}

.opendays .item-list > li > .holder {
    padding: 0 10px;
}

.opendays .item-list .title-block {
    padding-top: 10px;
}

.opendays .item-list .title-block .event-title {
    font-size: 16px;
    font-weight: bold;
}

.opendays .item-list .title-block .event-date {
    color: #4bc2f1;
    font-weight: bold;
}

.opendays .item-list .info-block {
    margin-top: 2px;
    margin-bottom: 5px;
}

.opendays .item-list .info-block span {
    display: block;
    line-height: 16px;
    padding-top: 2px;
}

.opendays .item-list .info-block .secondary,
.opendays .item-list .info-block .level {
    font-size: 13px;
}

.opendays .item-list .info-block .level {
    font-style: italic;
}

.opendays .item-list .bottom-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.opendays .item-list .bottom-block > .date {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.opendays .item-list .bottom-block > .date > span.glyph.calendar {
    display: inline-block;
    width: 20px;
    text-align: center;
}

.opendays .item-list .bottom-block > .date > span.glyph.calendar:before {
    font-size: 16px;
    color: #4BC2F1;
    margin-top: -1px;
    margin-right: 5px;
}

.opendays .item-list .bottom-block > .date > span.event-date {
    font-size: 13px;
    font-weight: 700;
    font-family: Arial, sans-serif;
    color: #444444;
    line-height: 22px;
    white-space: nowrap;
    margin-right: 5px;
}

.opendays .item-list .bottom-block > .button-holder > a:hover,
.opendays .item-list .bottom-block > .button-holder > a {
    background-color: #ffb200;
    border-color: #ec8106;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    cursor: pointer;
    display: inline-block;
    height: 26px;
    line-height: 20px;
    padding: 2px 12px;
    border-width: 1px;
    border-style: solid;
    font-family: Gibson, Arial, sans-serif;
    text-transform: lowercase;
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    white-space: nowrap;
}

.opendays .item-list .bottom-block > .button-holder > a:hover {
    background-color: #fb8c00;
    border-color: #ec8106;
}

.opendays .item-list .bottom-block > .button-holder > a:after {
    padding-left: 6px;
    content: "\e91b";
    display: inline-block;
    font-family: "tsrglyph";
    font-size: 9px;
    vertical-align: baseline;
    width: 5px;
}
