.discussion_btns, .pagination_container, .nav_filler {
    width: 50%;
    float: left;
    background-color: #44a0d4;
    box-sizing: border-box;
    height: 42px;
    line-height: 22px;
    padding: 8px 10px;
}

.discussion_btns .post-new-thread {
    margin-right: 25px;
}

.pagination_container {
    padding: 0 10px;
    color: #ffffff !important;
}

.pagination_container.bottom, .nav_filler.bottom {
    margin-bottom: 10px;
}

.pagination_container a {
    color: #ffffff !important;
}

.pagination_links, .tools_menu {
    float: right;
    box-sizing: border-box;
}

.tools_menu {
    padding-top: 10px;
    margin-right: 10px;
}

.ctl-bar.mod.top {
    margin: 0px !important;
}

#above_postlist {
    margin-top: 10px;
}


@media( max-width: 480px ) {

    .discussion_btns {
        width: 100%;
        float: none;
        background-color: transparent;
        padding: 10px 0px;
        margin-bottom: 10px;
    }

    .discussion_btns .post-new-thread, .discussion_btns .action-guest-reply, .discussion_btns .action-reply {
        display: block;
        top: -10px;
    }

    .discussion_btns .post-new-thread {
        float: left;
        margin-right: 0;
    }

    .discussion_btns .action-guest-reply, .discussion_btns .action-reply {
        float: right;
    }

    .pagination_container {
        width: 100%;
        float: none;
        clear: both;
        padding: 0;
    }

    .pagination_links {
        float: left;
    }

    .tools_menu {
        float: right;
        width: 25%;
        text-align: right;
        padding: 0px 0px 0px 10px;
        margin-right: 0px;
    }

    .tools_menu .thread-tools.interact {
        background-color: transparent;
        border: none;
        box-shadow: none;
        background-image: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/interact/tools_white.png");
        background-size: 25px;
        background-repeat: no-repeat;
        background-position: center;
        width: 25px;
        height: 25px;
        padding: 6px 8px;
        margin-top: 3px;
    }

    .tools_menu .thread-tools.interact:hover {
        background-color: transparent;
        border: none;
    }

    .tools_menu .thread-tools.interact .background {
        display: none !important;
    }

    .tools_menu .thread-tools.interact.hover {
        background-color: #ffffff;
        background-image: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/interact/tools_blue.png");
        background-size: 25px;
        background-repeat: no-repeat;
        background-position: center;
        margin: 3px 7px 0px 7px;
        box-shadow: 0 2px 2px rgba(125, 125, 125, 0.5);
        border: 1px solid #ccc;
    }

    .tools_menu .thread-tools.interact .menu {
        margin-top: 0px;
        right: -1px !important;
        border-top: none;
        width: 200px;
    }

    .tools_menu .thread-tools.interact > span {
        display: none !important;
    }

    .tools_menu .thread-tools.interact:before,
    .tools_menu .thread-tools.interact:after {
        display: none;
    }

    .pagination_links {
        width: 75%;
    }

    .nav_filler {
        display: none;
    }
}