/**
* Facebook sign in CSS
* @author A TSR Developer
* @device all
*/

.fbsignin > .fbbutton {
    background: #5b8ab8;
    background: linear-gradient(to bottom, #5b8ab8 0%, #4260a6 100%);
    border-radius: 4px;
    color: white;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    padding-right: 10px;
    transition: all 0.2s;
    vertical-align: top;
    margin-top: -2px;
}

.fbsignin > .fbbutton:before {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/miscellaneous/fblogo.png") no-repeat;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    content: "\a0";
    display: inline-block;
    height: 40px;
    margin-right: 10px;
    vertical-align: top;
    width: 33px;
}

.fbsignin > .fbbutton:hover {
    background: #40a0d4;
    background: linear-gradient(to bottom, #40a0d4 0%, #5a8bb9 100%);
}

 .fberror {
    color: #c80d18;
    display: none;
    line-height: 18px;
    margin-bottom: 8px;
}

.fberror:before {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/miscellaneous/sprite-ordered-form.png") no-repeat 0 -73px;
    content: "\a0";
    display: inline-block;
    height: 14px;
    vertical-align: top;
    width: 18px;
    margin: 2px 6px 2px 0;
}

@media (-webkit-min-device-pixel-ratio: 1.25),
       (min-resolution: 120dpi) {
    .fbsignin > .fbbutton:before {
        background-image: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/miscellaneous/fblogo@2x.png");
        background-size: 33px 33px;
    }

     .fberror:before {
        background-image: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/miscellaneous/sprite-ordered-form@2x.png");
        background-size: 29px 190px;
    }
}
