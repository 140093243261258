/**
* File containing glyph font definition and classes for various icons.
* @author A TSR Developer
* @device all
*/

@font-face {
    font-family: "tsrglyph";
    src: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/tsrglyph/tsrglyph.eot?2u6l1");
    src: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/tsrglyph/tsrglyph.eot?2u6l1#iefix") format("embedded-opentype"),
         url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/tsrglyph/tsrglyph.woff2?2u6l1") format("woff2"),
         url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/tsrglyph/tsrglyph.woff?2u6l1") format("woff"),
         url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/tsrglyph/tsrglyph.ttf?2u6l1") format("truetype"),
         url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/tsrglyph/tsrglyph.svg?2u6l1#tsrglyph") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.glyph:before {
    display: inline-block;
    font-family: "tsrglyph";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 13px;
    speak: none;
    vertical-align: top;
}

/* Copied from modules/tsrglyph/style.css
 * See http://docs.tsrdev.co.uk/display/TSR/Glyphs+font */


.glyph.cross:before {
  content: "\ea0f";
    color: #fff;
}
.glyph.clock:before {
  content: "\e94f";
    color: #fff;
}
.glyph.plus:before {
  content: "\ea0a";
    color: #fff;
}
.glyph.star:before {
    content: "\e904";
}
.glyph.visitor-message:before {
    content: "\e905";
}
.glyph.connect-icon:before {
    content: "\e903";
}
.glyph.notification-icon:before {
    content: "\e902";
}
.glyph.search-arrow-icon:before {
    content: "\e901";
}
.glyph.search-icon-white:before {
    content: "\e900";
}
.glyph.arrow-down:before {
    content: "\e600";
}
.glyph.arrow-left:before {
    content: "\e601";
}
.glyph.arrow-right:before {
    content: "\e602";
}
.glyph.arrow-up:before {
    content: "\e603";
}
.glyph.arrow-right-unfilled:before {
    content: "\e91b";
    color: #fff;
}
.glyph.arrow-left-unfilled:before {
    content: "\e91c";
    color: #fff;
}
.glyph.bell:before {
    content: "\e604";
}
.glyph.envelope:before {
    content: "\e605";
}
.glyph.home:before {
    content: "\e609";
}
.glyph.menu:before {
    content: "\e608";
}
.glyph.notepad:before {
    content: "\e606";
}
.glyph.person:before {
    content: "\e607";
}
.glyph.search:before {
    content: "\e610";
}
.glyph.calendar:before {
    content: "\e906";
    color: #fff;
}
.glyph.bin:before {
    content: "\e9ac";
}
.glyph.badge-icon:before {
    content: "\e907";
}
.glyph.edit-icon:before {
    content: "\e908";
}
.glyph.quote-icon:before {
    content: "\e909";
    color: #fff;
}
.glyph.rep-gem-icon:before {
    content: "\e90a";
    width: 3px;
}
.glyph.rep-icon:before {
    content: "\e90b";
}
.glyph.reply-icon:before {
    content: "\e90c";
    color: #fff;
}
.glyph.report-icon:before {
    content: "\e90d";
}
.glyph.submit-icon:before {
    content: "\e90e";
    color: #fff;
}
.glyph.tools-icon:before {
    content: "\e90f";
    color: #fffffe;
}
.glyph.star-outline-icon:before {
    content: "\e910";
    color: #fff;
}
.glyph.star-icon:before {
    content: "\e911";
    color: #fff;
}
.glyph.prev-page:before {
    content: "\ea1f";
    color: #fff;
}
.glyph.next-page:before {
    content: "\ea20";
    color: #fff;
}
.glyph.first-page:before {
    content: "\ea23";
    color: #fff;
}
.glyph.last-page:before {
    content: "\ea24";
    color: #fff;
}
/* FORUM ICONS */
.glyph.tick:before {
    content: "\e912";
    color: #9CC46A;
}
.glyph.question:before {
    content: "\e913";
    color: #71D1A6;
}
.glyph.book:before {
    content: "\e915";
    color: #3AA6D0;
}
.glyph.bookmark:before {
    content: "\e916";
    color: #4BC2F1;
}
.glyph.people:before {
    content: "\e917";
    color: #F88E68;
}
.glyph.car:before {
    content: "\e918";
    color: #3AA6D0;
}
.glyph.chat:before {
    content: "\e919";
    color: #3AA6D0;
}
.glyph.paint-palette:before {
    content: "\e91a";
    color: #AD8DF7;
}
.glyph.globe:before {
    content: "\e923";
    color: #9CC46A;
}
.glyph.entertainment:before {
    content: "\e924";
    color: #3AA6D0;
}
.glyph.prayer:before {
    content: "\e925";
    color: #64C9D9;
}
.glyph.coathanger:before {
    content: "\e926";
    color: #0083B8;
}
.glyph.film:before {
    content: "\e927";
    color: #E66171;
}
.glyph.running-man:before {
    content: "\e932";
    color: #AD8DF7;
}
.glyph.cutlery:before {
    content: "\e933";
    color: #4BC2F1;
}
.glyph.football:before {
    content: "\e934";
    color: #666666;
}
.glyph.pacman:before {
    content: "\e935";
    color: #F8CE46;
}
.glyph.game-controller:before {
    content: "\e936";
    color: #71D1A6;
}
.glyph.heartbeat:before {
    content: "\e937";
    color: #71D1A6;
}
.glyph.announcement:before {
    content: "\e938";
    color: #E66171;
}
.glyph.detective:before {
    content: "\e939";
    color: #566F7E;
}
.glyph.smiley-face:before {
    content: "\e93a";
    color: #F8CE46;
}
.glyph.money:before {
    content: "\e93b";
    color: #F8CE46;
}
.glyph.music:before {
    content: "\e93c";
    color: #F0777B;
}
.glyph.heart:before {
    content: "\e93d";
    color: #F0777B;
}
.glyph.trophy:before {
    content: "\e93e";
    color: #F88E68;
}
.glyph.tsr:before {
    content: "\e93f";
    color: #4BC2F1;
}
.glyph.at-symbol:before {
    content: "\e943";
    color: #0083B8;
}
.glyph.map:before {
    content: "\e944";
    color: #64C9D9;
}
.glyph.television:before {
    content: "\e945";
    color: #0083B8;
}
.glyph.mortar-board:before {
    content: "\e946";
    color: #566F7E;
}
