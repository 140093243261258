/**
* Relates to the homepage carousel. Also used on the CMS for editing.
* @author A TSR Developer
* @device al
*/

/* Pods */

.pod {
    border: 1px solid #e4e4e0;
    box-sizing: border-box;
    color: #414141;
    height: 171px;
    overflow: hidden;
    position: relative;
    width: 300px;
}

.pod.double,
.pod.double .pod-bg {
    height: 356px;
}

/* Pod roll - appears on the unexpanded pod (usually) */

.pod .roll {
    background: white;
    background: rgba(255, 255, 255, 0.8);
    box-sizing: border-box;
    height: 100%;
    margin-bottom: 4.167em;
    padding: 0 12px;
    position: absolute;
        bottom: -100%;
        left: 0;
    width: 100%;
}

.lte8 .pod .roll {
    background: transparent;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#ccffffff,endColorstr=#ccffffff);
}

.pod .roll h2 {
    margin: 0.857em 0 0.357em;
}

.pod .roll h3 {
    margin: 0.5em 0 0.857em;
}

.pod .roll p {
    margin: 0.416em 0;
}

.pod.rollable .roll {
    transition: all 0.6s;
}

.pod.double.rollable .roll {
    transition: all 0.8s;
}

.pod.rollable:hover .roll {
    bottom: 0;
    margin-bottom: 0;
    padding-top: 30px;
}

.pod.expanded.rollable:hover .roll,
.pod.rollable.no-transition:hover .roll {
    /* c.f. .pod .roll */
    bottom: -100%;
    margin-bottom: 3.833em;
}

.pod.video.expanded .roll {
    background: none;
    width: auto;
    z-index: 1;
}

.pod.video.expanded .roll h2 {
    margin-top: 4px;
}

.pod.video.expanded .roll p {
    display: none;
}

/* Pod static - stuff that appears on unexpandable unrollable pods */

.pod .static {
    box-sizing: border-box;
    padding: 16px;
    position: absolute;
        top: 0;
        left: 0;
    width: 100%;
}

.pod .static.end {
    top: auto;
    bottom: 0;
}

.pod .static h2 {
    color: #069;
}

.pod .static p {
    font-size: 1.083em;
    line-height: 1.462em;
}

.pod .static .interact {
    display: block;
    text-align: center;
}

/* Pod static link */

.pod .static-link {
    color: #333;
    text-decoration: none;
}

/* Pod miscellaneous items */

.pod.rollable.no-transition .roll,
.pod.no-transition .expander {
    transition: none;
}

.pod h2,
.pod h3 {
    line-height: 1em;
}

.pod h2 {
    font-size: 1.16em;
}

.pod h3 {
    font-size: 1em;
    font-weight: normal;
}

.pod .pod-bg {
    height: 169px;
    width: 298px;
}

.pod .cms-button.edit {
    position: absolute;
        top: 5px;
        left: 0;
    z-index: 1000;
}

/* Pod - Join TSR (default) */

#pod-jointsr-default {
    float: none;
    margin: 0 0 15px;
    overflow: visible;
}

#pod-jointsr-default {
    height: 211px;
    margin-top: 11px;
}

#pod-jointsr-default .pod-bg {
    height: 176px;
    position: absolute;
        top: -12px;
        right: 6px;
    width: 131px;
    z-index: 1;
}

#pod-jointsr-default .static p {
    color: #44a0d4;
    margin-right: 135px;
}
