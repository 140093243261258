/**
* Rules relating to some sort of generic notices. Also contains rules for a registration form in said popup.
* @author A TSR Developer
* @device all
*/

#notices {
    position: relative;
}

.notice {
    background-color: #44a0d4;
    color: white;
    font-size: 15px;
    margin-bottom: 16px;
    padding: 6px 16px 16px;
    position: relative;
}

.notice.indeterminate,
.notice.dismissed {
    display: none;
}

.notice .action-dismiss {
    color: white;
    font-size: 20px;
    line-height: 24px;
    position: absolute;
        top: 0;
        right: 0;
    width: 24px;
}



.notice a,
.notice a:hover,
.notice a:link,
.notice a:visited {
    color: white;
    text-decoration: underline;
}

.notice > h2,
.notice > h3,
.notice > p,
.notice > ul {
    margin: 10px 0 0;
}

.notice > h2 {
    font-size: 1.533em;
}

.notice > h2 > em {
    text-transform: uppercase;
}

.notice > h3 {
    font-weight: bold;
}

.notice > p,
.notice > ul {
    margin-right: 160px;
}

.notice ul {
    margin-left: 1em;
}

.notice ul > li {
    list-style: disc outside;
    margin: 6px 0 0;
}

.notice .interact {
    font-size: 1.067em;
    text-transform: lowercase;
    padding: 0 16px;
    position: relative;
    z-index: 1;
}

.notice .notice-action {
    position: absolute;
        right: 16px;
        bottom: 16px;
}

.notice .notice-action > a,
.notice .notice-action > span {
    clear: right;
    float: right;
}

.notice .notice-action > span {
    margin-top: 10px;
}

/* Notice styles */

.notice.laptop:after {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/register/laptop.png") no-repeat 0 0;
    content: "\a0";
    height: 121px;
    position: absolute;
        bottom: 8px;
        right: 10px;
    width: 202px;
}

.notice.bubbles:after {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/register/bubbles.png") no-repeat 0 0;
    content: "\a0";
    height: 100px;
    position: absolute;
        bottom: 0px;
        right: 0px;
    width: 269px;
}

.notice.laptop > p {
    margin-right: 0;
}

.notice.laptop > ul {
    margin-right: 268px;
}

.notice.laptop .notice-action {
    bottom: 50px;
    right: 144px;
}

.notice.newregbar {
	height:100px;
	padding: 0px;
	background-color: #eef3f8;
	color: #505050;
}

.notice.newregbar h2 {
	font-family: "Museo Sans Cyrillic", Arial, Geneva, Lucida, sans-serif !important;
	font-size: 16px;
	padding: 14px 0px 0px 14px;
}
.notice.newregbar ul {
	margin-top:2px;
	margin-left:31px;
}

.notice.newregbar ul li {
	font-size:13px;
	list-style-image: url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/register/bullet-star.png);
	margin: 2px 0 0;
}

.notice.newregbar .notice-action {
	right:163px;
	bottom:29px;
    z-index:5;
}

.notice.newregbar .notice-action img#retina-button {
    display:none;
}

.notice.newregbar .action-dismiss {
	z-index:1;
}

.notice.nametag:after {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/register/nametag.png") no-repeat 0 -26px / 221px 182px;
    content: "\a0";
    height: 156px;
    position: absolute;
        top: 0;
        right: 27px;
    width: 221px;
}

.notice.nametag > h2 {
    margin-right: 240px;
}

.notice.nametag > p {
    margin-right: 200px;
}

.notice.nametag > ul {
    margin-right: 374px;
}

.notice.nametag .notice-action {
    bottom: 45px;
    right: 213px;
}

/* Remove Notice Styles, javascript in ready.js adds this class */
.notice.nostyle {
    background: none;
    padding: 0px;
    margin: 0px;
}

/* OPTIMIZELY TEST - THREAD PAGE REGISTRATION PROMPT */
.notice .rep_prompt {
    background-color: rgb(93, 138, 184);
    padding: 15px;
}

.notice .rep_prompt#bar_prompt {
    color: rgb(255, 255, 255);
}

.notice .rep_prompt > span, .rep_prompt span a {
    font-size: 15px;
}

.notice .rep_prompt#bar_prompt a {
    color: rgb(255, 255, 255);
    text-decoration: underline;
    padding: 1px 0px;
}

.notice .rep_prompt a.arrow-right {
    float: right;
    font-size: 13px;
    top: -2px;
}

@media screen and (max-width: 1350px),
       (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 1688px),
       (min-resolution: 120dpi) and (max-width: 1620px) {
    .notice.nametag {
        min-height: 230px;
    }

    .notice.nametag > ul {
        margin-right: 195px;
    }

    .notice.nametag .notice-action {
        bottom: 16px;
        right: 27px;
    }
}



/** NEW NOTICES **/

.notice.forum-notice {
    background-color: #EEFAFC;
    color: #444444;
}

.notice.forum-notice div.clear {
    width: 100%;
    height: 0px;
    clear: both;
}

.notice.forum-notice .action-dismiss {
    color: #CCCCCC;
    top: 5px;
    background: none;
    text-indent: 0;
}

.notice.forum-notice h2 {
    margin-top: 5px;
}

.notice.forum-notice ul {
    margin: 10px 0;
}

.notice.forum-notice ul li {
    list-style-type: none;
    width: 29%;
    float: left;
}

.notice.forum-notice ul li a {
    color: #444444;
    text-decoration: none;
}

.notice.forum-notice ul li a.bubble {
    color: #ffffff;
}

.notice.forum-notice ul li a:hover {
    text-decoration: underline;
}

.notice.forum-notice ul li.chat {
    width: 24%;
}

.notice.forum-notice ul li.cta {
    width: 18%;
    text-align: right;
    float: right;
    margin-right: 0;
    margin-top: 0;
}

.notice.forum-notice ul li span.circle-arrow {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    top: -1px;
    border: none;
    width: 18px;
    height: 18px;
    background-color: #FFB200;
}

.notice.forum-notice ul li span.circle-arrow:after {
    height: 19px;
    width: 19px;
    font-size: 10px;
    text-align: center;
    padding-left: 1px;
    margin-top: 4px;
    vertical-align: top;

    display: inline-block;
    font-family: "tsrglyph";
    font-size: 9px;
    vertical-align: baseline;
    width: 5px;
    padding-left: 8px;
    content: "\e91b";
    color: #ffffff;
}


@media (min-width: 1165px) {
    .notice.forum-notice ul li.question {
        width: 24%;
    }

    .notice.forum-notice ul li.chat {
        width: 26%;
    }

    .notice.forum-notice ul li.people {
        width: 34%;
    }

    .notice.forum-notice ul li.cta {
        width: 15%;
    }
}

.notice.forum-notice ul li span.glyph {
    float: left;
    height: 100%;
    margin-right: 5px;
}

.notice.forum-notice ul li span.glyph:before {
    font-size: 17px;
    color: #4bc2f1;
}

.notice.forum-notice div.actions > .action-dismiss {
    position: relative;
    width: 40%;
    float: left;
    font-size: 14px;
}

.notice.forum-notice div.actions {
    display: none;
    margin-top: 10px;
}

.notice.forum-notice div.actions > .bubble.arrow-right {
    float: right;
    margin-top: 2px;
}


@media (max-width: 1045px) {
    .notice.forum-notice ul li span.circle-arrow { display: none; }
}


@media (max-width: 480px) {
    .notice.forum-notice,
    .notice.forum-notice div.actions {
        display: block;
    }

    .notice.forum-notice ul li {
        width: 100% !important;
        float: none;
    }

    .notice.forum-notice ul li span.glyph:before {
        width: 25px;
    }

    .notice.forum-notice ul li span.circle-arrow { display: inline-block; }

    .notice.forum-notice div.actions > .action-dismiss {
        text-align: left;
    }

    .notice.forum-notice > .action-dismiss,
    .notice.forum-notice .desktop-only {
        display: none;
    }

}