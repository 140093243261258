/**
* Contains rules for sprite sheeted images and general colour directives.
* @author A TSR Developer
* @device all
*/

table.tborder > thead > tr > td.tcat { color: #FFF !important; background-color: #7B63DB !important; border: 1px none !important; }
.vbmenu_control,
.ui-dialog .ui-widget-header,
#careersFeaturedContent #careerFCTop .tab-heads li.ui-tabs-selected,
.bar,
#member-tabs ul.ui-tabs-nav li.ui-tabs-selected { color: #FFF !important; background-color: #6652B8 !important; border: 1px none !important; }
.navbar_button { background: #ffffff url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/custom/button_grad.gif) repeat-x !important; }

#member-tabs ul.ui-tabs-nav li, table.arcadelight td.tcat, table.arcadelight td.tmenu { color: #444 !important; background: #ebf4f9 url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/custom/str_forumbar_light.gif) repeat-x scroll left top !important; }
table.arcadelight td.tcat { border-bottom: 2px solid #6652B8 !important; }
table.arcadelight td.tmenu { color: #6652B8 !important; }

body a, body a:link, body a:visited, #gutter_content .widget .tcat a, .clickable, .navbar a  { color: #7B63DB; }
.navbar a:hover, body a:hover, #calendarpicker .popupbody li > a:hover, #page_forms .form_edit .popupbody li > a:hover { color: #6652B8; }
table.lightwidget .tcat a { color: #6652B8 !important; }

.thead a, .thead a:link, .thead a:visited { color: #6652B8; }
.tfoot a, .tfoot a:link, .tfoot a:visited { color: #6652B8; }
.vbmenu_option a:link, .vbmenu_option_alink, #menu.vbmenu_popup,
.vbmenu_option a:visited, .vbmenu_option_avisited,
#primary_content .popupbody li.tmenu > a:hover,
#primary_content .popupbody li.vbmenu_hilite > a { color: #7B63DB !important; }

/** Sprites (bars & others) **/
#nav_menu { background: #019cd4 url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/custom/bar_repeatx_sprite_1.png) repeat-x; }
.sprite_bars_repeatx { 
    padding-left: 10px;
    padding-right: 10px;
    background-color: #3BA7D2;
}
.sprite_bars { background: url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/custom/bar_sprite_1.png) no-repeat; }
.sprite_tab  { background-image: url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/custom/tab_sprite.png); width: 68px; height: 24px; }
.sprite_widget_buttons { background: url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/custom/widget_buttons_sprite_1.png) no-repeat; }
.sprite_customise_tsr_button { background-position: 0 0; }
.sprite_forum_buttons { background: url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/button/forum_buttons_sprite.png) no-repeat; }
.sprite_thread_buttons { background: url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/button/thread_buttons_sprite.png) no-repeat; }
.sprite_group_buttons { background: url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/custom/group_buttons_sprite.png) no-repeat; }
.sprite_tournament   { background: url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/button/tournament_sprite.png) no-repeat; }
input.sprite_tournament   { background: url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/button/tournament_sprite.png) no-repeat !important; }
.sprite_chat_buttons { background: url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/custom/chat_buttons_sprite.png) no-repeat; }
.sprite_step_number { background: url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/custom/step_number_sprite.png) no-repeat; }

.arcade_bullet { color: #7B63DB; margin-bottom: 0.5em;}

#uniprofiles_content .ui-tabs-nav li.ui-tabs-selected { background: #006da8; color: #fff; }
#uniprofiles_content .ui-tabs-nav li a:hover { background: #c8e8fa; }
#uniprofiles_content .ui-tabs-panel { border-top: 2px solid #006da8; }
.ui-dialog .ui-dialog-titlebar { background: #136793; border: 1px solid #006da8; }

.up_hrule { border-top: 1px dashed #006da8; }
.up-tabs-content h3 { color: #006da8; }
.up_sb_box_body { border-top: 2px solid #006da8; }
.up_stats_table tr td.up_stat_term { border-right: 1px solid #006da8; }
div.up_img_border, div.up_day_img_border { border: 1px solid #006da8; }
.up_posts a { color: #006da8; }
.web_pages { background: url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/custom/web_pages.gif); }

.theme_color { color: #006EAB; }
.theme_border { border: 1px solid #019cdf !important; }
.theme_border_bottom { border-bottom: 1px solid #019cdf; padding:5px 0px; }
.theme_border_bottom_thick { border-bottom: 2px solid #7B63DB !important }
.theme_background { background-color: #ED7E13; }
#clearingpage .fill-back { background: #006da8; }
#clearingpage .fill-back-light { background: #ECF5F4; }
#clearingpage .dark-border { background: #136793; }

#profile_tabs .tab_list { border-bottom: 2px solid #7B63DB; }
#visitor_messaging #message_list .visitor_message_avatar_margin a font { color: #7B63DB; }
#friends_mini .block_title { border-bottom: 2px solid #7B63DB !important; }
#friends .info_bar a font { color: #7B63DB; }
#albums .block_title { border-bottom: 2px solid #7B63DB !important; }
#groups .block_title { border-bottom: 2px solid #7B63DB !important; }
.album_list .private { background: #7B63DB; }

.subs_main_left { background-image:url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/custom/subs_main_l.jpg"); }
.subs_main_right { background-image:url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/custom/subs_main_r.gif"); }
.subs_main_right_top h1, .subs_main_right_top h2 { color:#006DA8; }
.subs_benefits_title { background-color:#E77514; }
.subs_benefits_content li { background-image:url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/custom/subs_benefits_li.gif"); }
.subs_bit_top { background-color: #E77514; }
.subs_bit_bottom, .buy_more_credits { border: 1px solid #E77514; }
.subs_bit_bottom_left { border-right: 1px solid #E77514; }
.subs_bit_bottom_right, .buy_more_credits { background-color: #f5e2c3; }
.subs_submit, .buy_more_credits { color: #ea7616 !important; }
.riches_balance { color:#ED7E13; }
#signuptoday p a { color:#ED7E13; }
.subs_alternative a, .buy_more_credits { color:#ED7E13 !important; }

#jobsAcareerspage .featuredrecruiterbox { border:1px solid #136793; }
#jobsAcareerspage .barclaycapital { border-top:4px solid #136793; }
#jobsAcareerspage .fill-back { background:none repeat scroll 0 0 #006DA8; }
#careersFeaturedContent #careerFCTop { border-bottom:4px solid #136793; }
#jobsAcareerspage .arrow, #jobsAcareerspage .arrow-medium { background:url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/custom/goto.gif) no-repeat scroll 0 0 transparent; }
#jobsAcareerspage .dark-border { border:1px solid #136793; }
#careersFeaturedContent #careerFCTop img { border-right:1px solid #136793; border-top:1px solid #136793; }

.notice_arrow { background: url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/custom/regbar_arrow.gif) no-repeat; }
.navbar_notice_bg { background: url('//static.thestudentroom.co.uk/67e2a42d66799/forum/images/custom/regbar_bg.gif') repeat-x bottom; }

.right_groups_col .tcat, .left_groups_col .tcat { border-bottom: 2px solid #006da8 !important; }

.input-button, .input-reset, a.link-button, #neweventbutton, { border: 1px solid #e97011; background: #f08516 url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/custom/button_grad.gif) repeat-x; }
.sprite_button { border: 1px solid #e97011; background: url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/custom/button_sprite.png) no-repeat !important; }
.login { border: 1px solid #e97011; background: url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/custom/login_sprite.png) no-repeat; }

#page_infraction .panel .warning_header { color: #006DA8; }

#imod_menu td.vbmenu_option { color: #006DA8 !important; }

.btn.save-colour{ background-position: 0 0; }
.btn.save-main{ background-position: 0 -290px; }
.btn.save-other{ background-position: 0 -43px; }
.widget-sidebar{ background-position: 0 -629px; }
.btn.up{ background-position: 0 -184px; }
div.tabs.ui-tabs .ui-tabs-nav li.ui-state-active a{ background-position: 0 0 !important; }
span.what{ background-position: 0 -86px; }
span.dont-show{ background-position: 0 -114px; }
div.question{ background-position: 0 -218px; }
span.title, span.what a, span.reset, h2#customise-page{ color: #006da8; }
div.line{ background-color: #006da8; }

#page_memberlist #charnav dd a:hover { color: #006DA8; }

#page_calendar #pagetitle h1 { color: #006DA8; }
#page_calendar .month .events.today { border: 1px solid #ED7E13; }
#page_calendar .tabslight dd a { background: #006ba6; }
#page_calendar .tabslight dd.selected a, #page_calendar .tabslight dd a:hover { background: #0394cd; }
#page_calendar #calendarpicker { border: 1px solid #0394cd; }
#page_calendar .popupmenu a.popupctrl { background-image: url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/custom/downarrow.gif); }
#page_calendar #eventtabs dd a, 
#page_calendar #recurtabs dd a { background: url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/custom/calendar_event_tabs_sprite.png) no-repeat; }
#page_calendar .weekcontrols a.textcontrol,
#page_calendar #monthjump a.textcontrol { background: url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/custom/calendar_button_sprite.png) no-repeat; }
#page_calendar .birthdays .posthead { background: #019cd4 url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/custom/str_forumbar.gif) repeat-x top; }
#page_calendar .eventfoot .textcontrols a.editevent { background: url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/button/edit_40b.png) no-repeat left center; }
#page_calendar .eventfoot .textcontrols a.editevent:hover { background: url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/button/edit_40b-hover.png) no-repeat left center; }

.mgc_cb_evo_block .blockrow .chat_box_container { border-top: 2px solid #006DA8; margin-top: 1px; }

/*******************************************************************************
 ** VB4 overrides */

/* HEADER */
body .toplinks { background: #019cd4 url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/custom/str_forumbar.gif) repeat-x top; }

/* WIDGETS */
body .cms_widget_header { background: #019cd4 url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/custom/str_forumbar.gif) repeat-x top; }

/* FORUM */
body .forumhead,
body .blockhead,
body .forum_info .blockhead,
body .wgo_block .blockhead,
body .threadlisthead,
body .postbit .posthead,
body .postbitlegacy .posthead,
body .eventbit .eventhead { 
    background-color: #7B63DB !important; 
    background: none;
    border-radius: 0px;
    height: 35px;
    display: flex;
    align-items: center;
    padding-left: 10px;
}
/*
#page_showthread .toolsmenu .popupgroup .popupbody a, #page_forumdisplay .toolsmenu .popupgroup .popupbody a { color: #006DA8; }
*/
/* USER PROFILE */
body dd.userprof_module { background: #019cd4 url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/custom/str_forumbar.gif) repeat-x top; }

/* SEARCH */
body #searchtypeswitcher li.selected a { background: #019cd4 url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/custom/str_forumbar.gif) repeat-x top; }

.sprite_button:hover { border: 1px solid #e97011; background: url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/custom/button_sprite.png) no-repeat; }
.button:hover { background: #f08516 url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/custom/button_grad.gif) repeat-x; }

/* SHOWGROUPS */
#page_showgroups .groupbit .postcontrols a, #page_showgroups .groupbit .postcontrols a:hover { background-color: #E97011; border-color: #E97011; }

/* Memberlist */
#memberlist_table .columnsort a:hover,
#page_memberlist .blockhead a:hover { color: #6652B8; }

/* Forms */
#page_forms .form .button, 
#page_forms .newcontent_textcontrol, 
#page_forms .form_edit #charnav dd a,
#page_forms .form_edit .popupmenu .popupctrl { 
    background-color: #99FFA8 !important; 
    color: #272727 !important;
    background: #99FFA8 !important;
}

#page_forms .newcontent_textcontrol:hover, 
#page_forms .form .button:hover,
#page_forms .form_edit #charnav dd a:hover {
    background-color: #63CD73 !important; 
}

/* Moderation */
#page_moderation .inline_header { background: #019cd4 url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/custom/str_forumbar.gif) repeat-x top; } 

/* Bookmarks (alternative for Explore TSR button) */
.bookmarks .bookmark_links span { color: #7B63DB; }

#page_vbcms blockquote {
    border: 1px solid #7B63DB !important;
}

#page_vbcms .bg--quote {
    background-color: #DED8F6 !important;
}
