/**
* A collection of CSS relating to the header.
* @author A TSR Developer
* @device mixed
*/

/* Main structure */
@media screen and (min-width: 481px) {
    #page_connect #user-menu ul.menu,
    #page_register #user-menu ul.menu,
    #page_login #user-menu ul.menu {
        width: 980px !important;
        padding-right: 5px;
    }
}

#page_connect #user-menu ul.menu,
#page_register #user-menu ul.menu,
#page_login #user-menu ul.menu {
    padding-right: 5px;
}
#header ul, #header li {
    list-style: none;
}

#header {
    list-style: none;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    -webkit-text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    padding-top: 0px;
    -webkit-transition: padding-top 0.3s;
    -moz-transition: padding-top 0.3s;
    -ms-transition: padding-top 0.3s;
    -o-transition: padding-top 0.3s;
    transition: padding-top 0.3s;
}

#header > .page-section {
    background-color: white;
}

#logo,
#logo-flair {
    float: left;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
}

#page_member #user-menu #logo {
    display: none;
}

#logo {
    width: 122px;
}

#logo-flair {
    width: 114px;
}

#logo > img,
#logo-flair > img {
    max-height: 100%;
    max-width: 100%;
    vertical-align: middle;
}

#logo > .default {
    height: 120px;
    width: 133px;
}

#logo > .retina,
#logo > .logo-mobile,
#logo > .logo-mobile-2x,
#logo-flair > .retina {
    display: none;
}

.advert-header {
    float: right;
    overflow: hidden;
    text-align: center;
}

#header .advert-block {
    float: right;
    height: 90px;
    margin: 9px 10px 7px 0;
    position: relative;
    width: 970px;
    z-index: 150;
}

/* Correct the width if takeover is enabled */

.takeover-enabled #header.fixed {
    left: 50%;
    margin-left: -491px;
    right: auto;
}

.takeover-enabled #header > .page-section {
    width: 980px;
}

/* Header - User menu (really effing complicated!) */

#header {
    background-color: #7B63DB;
    min-width: 100% ;
    text-align: right;
}

@media screen and (max-width: 480px), screen and (max-device-width: 480px),
       (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 600px),
       (min-resolution: 120dpi) and (max-width: 600px) {
    #user-menu {
        box-shadow: none !important;
        position: absolute;
    }
}

.takeover-wrapper #user-menu,
#user-menu,
.takeover-wrapper .mod-header {
    height: 50px;
    width: 100%;
    margin: 0 auto 0;
}

.takeover-wrapper #user-menu.member {
    padding-top: 5px;
    height:45px;
}

.takeover-enabled #page_showthread #header_container,
.takeover-enabled #page_showthread #main-menu {
    width: 1300px;
    margin-left: calc((100% - 1300px) / 2);
}

.takeover-enabled #user-menu {
    min-width: 0;
}

.takeover-wrapper #user-menu,
#user-menu {
    background-color: #7B63DB;
    position: fixed;
    top: 0;
    left: auto;
    transition: box-shadow 0.2s;
    z-index: 9999;
}

.takeover-wrapper #page_index .mod-header,
.takeover-wrapper #page_forumdisplay .mod-header,
.takeover-wrapper #page_showthread .mod-header {
    width: 100% !important;
    margin: 0 auto 0;
}
.takeover-enabled #page_index #user-menu,
.takeover-enabled #page_forumdisplay #user-menu,
.takeover-enabled #page_showthread #user-menu {
    min-width: 980px;
    max-width: 1300px;
    width: inherit;
    margin: 0 auto;
    right: 0;
    left: 0;
}



#user-menu.fixed {
    /* This fixes some painting issues in Chrome */
    -webkit-transform: translateZ(0);
}

#user-menu > ul {
    white-space: nowrap;
}

#user-menu ul li .compact {
    display: none;
}

#user-menu > ul > li > ul {
    background-color: #fff;
    display: none;
    padding: 5px 0;
    text-align: left;
    white-space: nowrap;
    z-index: 201;
}

#user-menu > ul > li > ul {
    position: absolute;
    left: 0;
}

#user-menu > ul > .user ul {
    background-color: #6652B8;
    width: 230px;
}

#user-menu > ul > .expandable ul {
    box-shadow: 0 10px 18px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 10px 18px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0 10px 18px rgba(0, 0, 0, 0.25);
}

#user-menu > ul > .user span,
#user-menu > ul > .user li a,
#user-menu > ul > .user .arrow-right {
    color: #fff;
    font-size: 15px;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    -webkit-text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}

#user-menu > ul > .user .bubble {
    height: 27px;
    width: 87px;
    padding: 0;
}

#user-menu > ul > .user .bubble .arrow-right:after {
    padding: 0 0 0 5px;
    background-position: -129px 8px;
}

#user-menu > ul > .user .arrow-right:hover {
    text-decoration: none;
}

#user-menu > ul > li > ul {
    position: absolute;
    left: -300px;
}

#user-menu > ul > li.user > ul {
    position: absolute;
    left: -163px;
}

.nojs #user-menu li:hover > ul {
    display: block;
}

#user-menu > ul > li.user li p.close-submenu {
    display: none;
}

#user-menu > ul > li,
#user-menu > ul > .user li {
    position: relative;
}

#user-menu > ul > li {
    display: inline-block;
    vertical-align: top;
    white-space: normal;
    background-color: #7B63DB;
}

#user-menu  > ul > li.menu {
    display: none;
}

#user-menu .user > span:after {
    vertical-align: top;
    margin-top: 18px;
}

#page_vbcms #user-menu .user > span:after {
    margin-top: 15px;
}


#user-menu .user > span > img {
    border: 1px solid #7B63DB;
    display: inline-block;
    height: 30px;
    margin-top: 6px;
    width: 30px;
    vertical-align: top;
    border-radius: 50%;
}

#user-menu .user > span > .text {
    display: inline-block;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
    white-space: nowrap;
    font-size: 14px;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    -webkit-text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}

#user-menu .user li > a,
#user-menu .user li > span {
    color: #369;
    cursor: pointer;
    display: block;
    padding: 0 10px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

#user-menu > ul > li > a,
#user-menu > ul > li > span {
    color: white;
    cursor: pointer;
    display: block;
    padding: 0 12px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    z-index: 201;

}

#user-menu > ul > .headthread-open > span {
    font-family: Arial !important;
    font-weight: 700 !important;
    letter-spacing: 0.84px;
    height: 37px;
    font-size: 14px;
    background-color: #99FFA8;
    border-radius: 35px !important;
    color: #272727 !important;
    display: flex !important;
    align-items: center !important;
    margin-top: 1px;
    justify-content: space-between;
}

#user-menu > ul > .headthread-open > span > img {
    margin-right: 5px;
}

#user-menu > ul > .headthread-open > span:hover {
    background-color: #63CD73;
}

#user-menu .headthread-open.expanded > span {
    background-color: #63CD73;
}

#user-menu ul li.headthread-open > span > span.text.mobile {
    display: none;
}

#user-menu > ul > .trafficdriver span {
    height: 44px;
    font-size: 14px;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    -webkit-text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}

#user-menu > ul > .trafficdriver.desktop a:hover {
    background-color: #0093CF;
    text-decoration: none;
}

#user-menu .glyph:before {
    margin-right: 2px;
}

#user-menu > ul > .go-home {
    text-align: center;
}

#user-menu > ul > .go-home > a {
    border-radius: 10px;
    height: 20px;
    padding: 6px 0;
    width: 44px;
}

#user-menu > ul > .go-home > a:hover {
    text-decoration: none;
}

#user-menu .bucket .unread-count {
    min-width: 10px;
    text-align: center;
    background-color: #FFA600;
    border-radius: 25px;
    color: #272727;
    font-size: 8px;
    font-weight: bold;
    line-height: 1;
    padding: 2px;
    left: 22px;
    top: 5px;
    position: absolute;
}

#pmBadge {
    left: 28px !important;
}

#user-menu .bucket .glyph:before {
    font-size: 16px;
    font-weight: normal;
}

#user-menu .bucket.zero .unread-count {
    display: none;
}

#user-menu > ul > li.expandable > span {
    position: relative;
    z-index: 202;
}

#user-menu .expanded > span {
    border-radius: 8px 8px 0 0;
    background-color: #6652B8;
}

#user-menu > ul > li.user > ul > li > ul {
    display: none;
}

#user-menu ul ul li.expandable > span {
    padding-right: 15px;
}

#user-menu > ul > li .bubble {
    text-align: center;
    min-height: 27px;
    width: auto;
}

#user-menu > ul > .user .bubble {
    height: 27px !important;
    width: 87px !important;
    padding: 0 !important;
    margin: 5px 0 5px 10px;

}

#user-menu > ul > li .action .bubble {
    width: auto !important;
    min-height: 17px !important;
    height: 17px;
    line-height: 14px;
    text-align: center;
}

#user-menu > ul > li .action .arrow-right:after {
    vertical-align: middle;
}

#user-menu ul ul li.expandable .arrow-right:after {
    color: #fff;
    position: absolute;
    top: -3px;
    right: 12px;
}

#user-menu .user .expanded,
#user-menu .user .expandable {
    margin-left: 0;
}

#user-menu ul ul li.expanded > span {
    background-color: #6652B8;
    color: white;
    z-index: 202;
}

#user-menu .user li a,
#user-menu .user li span {
    line-height: 30px;
    margin: 0 6px;
    padding: 0 8px;
}

#user-menu .user ul li:first-child a,
#user-menu .user ul li:first-child span {
    border-top: none;
}

#user-menu .user ul li a.no-border {
    border: none;
}

#user-menu .user li > a > span {
    line-height: inherit;
    margin: 0;
    padding: 0;
}

#user-menu .user ul li a:hover,
#user-menu .user ul li a span:hover {
    text-decoration: underline;
}

#user-menu > ul > li > a:hover,
#user-menu .user li a:hover,
#user-menu .user li.expandable > span:hover {
    text-decoration: underline;
}

#user-menu .user li.expandable > ul {
    background-color: #6652B8;
}

#user-menu .user ul li span.highlight {
    color: #fff;
    cursor: default;
    display: block;
    font-size: 15px;
    margin-bottom: -1px;
    position: relative;
    margin-top: 15px;
    z-index: 1;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    -webkit-text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}

#user-menu .user ul li.title span.highlight {
    margin-top: 0;
}

#user-menu .bucket .action .bubble {
    margin: 0;
}

#user-menu .user ul ul {
    border-top-width: 1px;
    -webkit-box-shadow: 3px 3px 10px -5px rgba(0, 0, 0, 0.3);
    padding: 5px 0;
    box-shadow: 3px 3px 10px -5px rgba(0, 0, 0, 0.3);
    position: absolute;
    top: -5px;
    left: 100%;
    z-index: 202;
}

#user-menu .user ul ul.opposite {
    border-radius: 5px 0 0 5px;
    -webkit-box-shadow: -3px 3px 10px -5px rgba(0, 0, 0, 0.3);
    box-shadow: -3px 3px 10px -5px rgba(0, 0, 0, 0.3);
    left: auto;
    right: 100%;
}

#user-menu > ul > li > span > .text {
    padding: 0;
}

#user-menu > ul > li > span > .text {
    display: inline;
}

#user-menu .user ul li.highlight > a {
    background-color: #3bb5e0;
    border-top: none;
    color: white;
    font-weight: bold;
    text-align: center;
}

#user-menu .user ul li.highlight > a:hover {
    background-color: #348fc4;
}

#user-menu ul li.menu-left {
    float: left;
    margin: 0 0 0 6px;
}

#page_vbcms #user-menu ul li.menu-left {
    float: left;
    margin: 8px 0 0 6px !important;
}


/* Notifications and Inbox menus (buckets) */

#user-menu .bucket {
    border-radius: 0;
    font-size: 1em;
    text-align: left;
}

#user-menu .bucket > ul {
    padding: 0;
    width: 400px !important;
    background-color: #6652B8;
    border-radius: 8px;
}

#user-menu .bucket .items {
    max-height: 305px;
    font-size: 14px;
    overflow: hidden;
    overflow-y: auto;
    scrollbar-color: #bdb1ed #F8F5FF;
    scrollbar-width: thin;
}

#user-menu .bucket .notification {
    border-bottom: 1px solid #d4eef7;
    padding: 5px 6px 5px 4px;
}

#user-menu .bucket .notification span {
    font-weight: normal !important;
}

#user-menu .bucket .notification.new span {
    font-weight: bold !important;
}

#user-menu .bucket .notification:last-child {
    border-bottom: 0;
}

#user-menu .bucket .notification > a {
    padding: 3px 4px 3px 0;
}

#user-menu .bucket .notification > a > .info span {
    font-weight: normal !important;
}

#user-menu .bucket .notification.new > a > .info span {
    font-weight: bold !important;
}

#user-menu .bucket .notification > a .notificationImage {
    float: left;
    background-color: white;
    border: 1px solid #c3c5c6;
    display: inline-block;
    height: 30px;
    margin: 0 10px 0 0;
    width: 30px;
    vertical-align: top;
}

#user-menu .bucket .notification > a .repreceived {
    background-image: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/icons/notifications/Rep.png");
}

#user-menu .bucket .notification > a:before {
    content: none;
    width: 0;

}

#user-menu .bucket .notification > a > .title {
    color: #3086bd;
}

#user-menu .bucket .notification > a > .info {
    color: #555;
}

#user-menu .bucket .no-data {
    color: #a0a0a0;
    font-size: 14px;
    font-style: italic;
    padding: 12px 0;
    text-align: center;
}

#user-menu .bucket .action {
    padding: 9px 7px;
    text-align: right;
    display: flex;
    justify-content: space-between;
}

#user-menu .bucket .action .link {
    color: #99FFA8;
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    padding: 0 5px;
    vertical-align: top;
    letter-spacing: 4%;
}

#user-menu .bucket .action .link:hover {
}

#user-menu .bucket .action .link.dismiss {
    float: left;
}

#user-menu .bucket .action .link.dormant,
#user-menu .bucket .action .link.inactive {
    display: none;
}

#user-menu .bucket .action .bubble {
    margin-left: 10px;
    vertical-align: top;
}

#user-menu .signin a,
#user-menu .register a {
    margin: 1px 10px 0 0;
    line-height: 27px;
    font-size: 15px;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    -webkit-text-shadow: rgba(0, 0, 0, .01) 0 0 1px;

}

#user-menu .signin .bubble.secondary {
    background-color: #2fa7d6;
    border-color: #38a0de;
}

#user-menu .signin .bubble.secondary:hover {
    background-color: #3392ca;
    border-color: #2f88bc;
}


/* Header - Top Leaderboard */

#top_leaderboard_container {
    height: 115px;
    margin: 45px auto 0 auto;
    position: relative;
    max-width: 1300px;
    min-width: 980px;
    width: auto;
}

#page_showthread #top_leaderboard_container {
    max-width: 1400px;
}

.takeover-enabled #page_showthread #top_leaderboard_container {
    max-width: 1300px;
}

#page_home.page #top_leaderboard_container,
#page_vbcms.page #top_leaderboard_container,
#page_connect.page #top_leaderboard_container,
#page_opendaycal.page #top_leaderboard_container,
#page_uniatoz.page #top_leaderboard_container,
#page_unichoice.page #top_leaderboard_container,
#page_login.page #top_leaderboard_container,
#page_register.page #top_leaderboard_container {
    max-width: 980px;
    box-sizing: border-box;
}

#header #top_leaderboard_container .advert-block {
    float: right;
    margin-right: 9px;
    visibility: hidden;
    width: 728px;
}

/* Header - Search bar */

#user-menu > ul > .search {
    width: 250px;
}

#page-search {
    display: table-cell;
    height: 28px;
    text-align: left;
    vertical-align: middle;
}

#page-search fieldset {
    border: none;
    padding: 0;
}

#page-search .searchBarContainer {
    position: relative;
    width: 320px;
    padding: 5px 10px;
}

#page-search .search-bar {
    background-color: white;
    display: inline-block;
    position: relative;
    margin: 3px 7px 0px 7px;
    width: 225px;
    height: 34px;
    padding: 0 5px 0 5px;
    border-radius: 4px;
}

#page-search .search-bar .imageSubmit {
    float: left;
    margin: 3px;

    width: 23px !important;
    height: 23px !important;
    padding: 0 !important;
}

#page-search .search-bar > input {
    float: left;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    -webkit-text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}

#page-search button,
#page-search input,
#page-search label {
    margin: 0;
}

#page-search button,
#page-search input {
    background: none;
    border: none !important;
}

#page-search .search-bar button {
    cursor: pointer;
    padding: 3px;
    position: absolute;
        top: 0;
        right: 0;
}

#page-search .search-bar button > .glyph {
    color: #272727;
    display: flex;
    width: 29px;
    height: 28px;
    align-items: center;
    background-color: #99FFA8;
    justify-content: center;
}

#page-search .search-bar button:hover > .glyph {
    color: #272727;
}

#page-search .search-bar button > .glyph:before {
    line-height: 1;
    font-size: 18px;
    width: 14px;
}

#page-search {
    padding-left: 2px;
}

#page-search button::-moz-focus-inner {
    border: none;
    padding: 0;
}

#page-search #ps-query {
    padding: 0 0 0 2px;
    z-index: 2;

}

#page-search #ps-query input {
    width: 185px;
    font-size: 14px;
    color: #999;
    height: 34px;
    display: block;
    padding: 0 5px 0 5px;
    font-weight: normal;

}

#page-search .searchBarContainer {
    margin-left: 2px;
}

#page-search .expandedSearchTools {
    display: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.33);
    font-size: 14px;
    background-color: #44a0d4 !important;
    width: 250px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    padding: 38px 0 0;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    -webkit-text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}

#page-search .expandedSearchTools #betaSearchLink {
    background: #3086bd;
}

#page-search .expandedSearchTools .betaMessage {
    font-size: 15px;
    cursor: pointer;
}

#page-search .expandedSearchTools .betaMessage:hover {
    text-decoration: underline;
}

#page-search .expandedSearchTools > div {
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: 48px;
    line-height: 28px;
    padding: 10px 12px;
    color: #fff;
}

#page-search .expandedSearchTools a {
    margin: 0;
    text-decoration: none;
    float: left;
    display: inline;
    color: #fff;
}

#page-search .expandedSearchTools .betaNew {
    font-weight: bold;
}

#page-search .expandedSearchTools button {
    float: right;
    margin: 0;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

#page-search .expandedSearchTools button > .bubble {
    min-height: 0;
}

#page-search .expandedSearchTools a:hover {
    text-decoration: underline;
}

#page-search input:focus {
    outline: none;
}

#page-search label {
    color: #8b8b8b;
    line-height: 28px;
    vertical-align: top;
}

/* Header - Main menu */

@media screen and (min-width: 481px),
       screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 801px),
       screen and (min-resolution: 120dpi) and (min-width: 801px) {

    /* DY-6 - Collapsed header */
    #header.header-collapsed #user-menu-logo {
        height: 93px;
        position: fixed;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        z-index: 1000;
    }
    #header.header-collapsed > .page-section {
        width: 100% !important;
        max-width: 100% !important;
    }
    #header.header-collapsed #user-menu > ul > li.go-home > a:not(.home) {
        background: none;
    }
    #header.header-collapsed #user-menu > ul > li.go-home a.home {
        opacity: 100;
        webkit-transition: opacity 0.3s;
        -moz-transition: opacity 0.3s;
        -ms-transition: opacity 0.3s;
        -o-transition: opacity 0.3s;
        transition: opacity 0.3s;
    }
    #header.header-collapsed.scrolling #user-menu > ul > li.go-home a.home {
        opacity: 1;
    }
    #header.header-collapsed #user-menu > ul {
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    #header.header-collapsed.header-closed #user-menu > ul > li:not(.go-home) {
        padding-top: 0;
    }
    #header.header-collapsed .advert-block {
        float: none;
        margin: 9px auto;
    }
    #header.header-collapsed #user-menu #logo {
        position: absolute;
        left: 0;
        top: 20px;
        z-index: 500;
        opacity: 1;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;

        display: none;

    }

    #header #top_leaderboard_container #logo {
        position: absolute;
        left: 0px;
        top: -45px;
        padding-top: 10px;
        padding-left: 10px;
        z-index: 10000;
        opacity: 1;
    }


    #header.header-collapsed.scrolling #user-menu #logo {
        opacity: 0;
        z-index: -1;
        height: 40px;
        width: 43px;
        top: -10px;
        left: 10px;
    }
    #header.header-collapsed #user-menu #logo img {
        width: auto;
        height: auto;
        max-height: 93px;
    }
    #header.header-collapsed #user-menu #logo img[src$="staging-os-logo.png"] {
        position: relative;
        top: -10px;
    }
    #header.header-collapsed .page-section > .advert-block {
        display: none;
    }

    #logo,
    #logo-flair {
        float: left;
        text-align: center;
        text-decoration: none;
        white-space: nowrap;
    }

    #logo {
        width: 150px;
    }

    #user-menu ul.mobile #logo {
        display: none;
    }

    #logo-flair {
        width: 150px;
    }

    #logo > img,
    #logo-flair > img {
        max-height: 100%;
        max-width: 100%;
    }

    #logo > .default {
        height: 120px;
        width: 133px;
        margin: 12px 0 0 0;
    }

    img.default.standard {
        margin: 12px 0 0 0;
    }


    #main-menu {
        background-color: #7B63DB;
        clear: both;
        display: block;
        height: 50px;
        position: relative;
        text-align: center;
        vertical-align: bottom;
        white-space: normal;
        z-index: 100;
    }

    #main-menu > ul {
        display: inline-block;
        height: 50px;
        margin: 0 auto;
        max-width: 1046px;
    }

    #main-menu li.search-box {
        display: none;
    }

    .page.fixed-width #main-menu > ul {
        max-width: 980px;
        position: relative;
        vertical-align: top;
    }
    #page_showthread.page.fixed-width #main-menu > ul,
    #page_forumdisplay.page.fixed-width #main-menu > ul,
    #page_index.page.fixed-width #main-menu > ul {
        max-width: 980px !important;
        display: inline-block;
    }

    #main-menu > ul > li {
        display: table-cell;
        height: 50px;
        vertical-align: top;
    }

    .desktop-variable #main-menu > ul > li {
        width: 1px;
    }

    .desktop-variable #main-menu > ul > .display-desktop-fixed,
    .desktop-fixed #main-menu > ul > .display-desktop-variable{
        display:none;
    }

    #main-menu > ul > .display-all,
    .desktop-fixed #main-menu > ul > .display-desktop-fixed,
    .desktop-variable #main-menu > ul > .display-desktop-fixed {
        display: table-cell;
    }

    #main-menu > ul > .display-all.search-beta {
        display: none;
    }

    #main-menu > ul > .display-all.search-beta a {
        background: #3fabea;
    }


    #main-menu > ul > li.nowrap > a {
        white-space: nowrap;
    }

    .fixed-width #main-menu > ul > li.nofixed {
        display: none;
    }

    #main-menu > ul > li > a {
        color: white;
        display: table-cell;
        font-size: 15px;
        font-weight: bold;
        height: 46px;
        padding: 0 18px;
        text-decoration: none;
        vertical-align: middle;
        line-height: 16px;
    }

    .fixed-width #main-menu > ul > li > a {
        padding: 0 17px;
    }

    #main-menu > ul > li.active > a {
        background-color: #6652B8;
        border-bottom: 4px solid #99FFA8;
    }

    #main-menu > ul > li.hover > a {
        background-color: #6652B8;
        border-bottom: 4px solid #99FFA8;
    }

    #main-menu > ul > li > a .contentchange {
        display: none;
    }

    #main-menu > ul > li > a:hover .contentchange {
        display: block;
    }

    #main-menu .submenu.expanded {
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.33);
    }

    #main-menu .submenu {
        background-color: #6652B8;
        border-top: 2px solid #99FFA8;
        display: none;
        font-size: 14px;
        font-weight: normal;
        min-height: 300px;
        text-align: left;
        position: absolute;
        top: 100%;
        z-index: 101;
        max-height:300px;
        overflow:hidden;
        padding-bottom:5px;
        right: calc((100% - 1046px) / 2 );
        left:calc((100% - 1046px) / 2 );
        width:983px;
    }

    .desktop-variable #main-menu .submenu.expanded{ box-shadow: 0 5px 5px rgba(0, 0, 0, 0.33);}

    .page.fixed-width  #main-menu .submenu {
        width:980px;
        right: calc((100% - 980px) / 2 );
        left:calc((100% - 980px) / 2 );
    }
    #page_index.page.fixed-width  #main-menu .submenu,
    #page_forumdisplay.page.fixed-width  #main-menu .submenu,
    #page_showthread.page.fixed-width  #main-menu .submenu {
        width: 1046px;
        right: calc((100% - 1046px) / 2 );
        left: calc((100% - 1046px) / 2 );
    }

    #main-menu .snippet-ul li {
        list-style: none;
    }

    #main-menu .submenu:before {
        height: 10px;
        position: absolute;
        top: -10px;
        right: 0;
        left: 0;
    }

    .nojs #main-menu .submenu {
        min-width: 400px;
    }

    .nojs #main-menu li:hover > .submenu {
        display: block;
    }

    #main-menu .submenu .column {
        box-sizing: border-box;
        float: left;
        padding: 6px 10px 16px 10px;
        vertical-align: top;
        width: 25%;
        list-style: none;
    }


    #main-menu .submenu a {
        color: #FFFFFF;
    }

    #main-menu .submenu h2,
    #main-menu .submenu h3,
    #main-menu .submenu .heading,
    #main-menu .submenu .subheading,
    #main-menu .submenu ul,
    #main-menu .submenu .promotion {
        margin: 0;
    }

    #main-menu .submenu h2,
    #main-menu .submenu .heading,
    #main-menu .submenu .promotion {
        padding: 5px 7px;
    }

    #main-menu .submenu h2,
    #main-menu .submenu .heading {
        color: #FFFFFF;
        font-size: 16px;
        font-weight: bold;
    }

    #main-menu .submenu h3,
    #main-menu .submenu .subheading {
        font-size: 1em;
    }

    #main-menu .submenu ul {
        padding: 0;
    }

    #main-menu .submenu ul li {
        position: static;
    }

    #main-menu .submenu h3,
    #main-menu .submenu .subheading,
    #main-menu .submenu ul li a,
    #main-menu .submenu .snippet-ablock {
        display: block;
        line-height: 16px;
        padding: 5px 7px;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
    }

    #main-menu .submenu ul li a {
        background-color: transparent;
    }

    #main-menu .submenu h3,
    #main-menu .submenu .subheading {
        border-bottom: 1px solid #edede6;
        color: #808080;
    }

    #main-menu .submenu .column:first-child h3,
    #main-menu .submenu .column:first-child .subheading {
        border-bottom-color: #edede6;
        color: white;
    }

    #main-menu .submenu ul + .snippet-ablock {
        margin: 0;
    }

    #main-menu .submenu .snippet-ablock strong {
        background-color: #eff7f9;
        display: block;
        font-weight: normal;
        margin: -5px -7px;
        padding: 5px 7px;
    }

    #main-menu .submenu .promotion {
        color: #b4b4b4;
    }

    #main-menu .submenu .promotion .image {
        float: left;
        margin: 0 10px 10px 0;
        border: 1px solid #b4b4b4;
    }

    #main-menu .submenu .promotion img.image,
    #main-menu .submenu .promotion .image img {
        height: 64px;
        width: 64px;
    }

    #main-menu .forumlist {
        display: none;
    }

    #main-menu ul.mobilenav {
        display: none;
    }

    .noads #main-menu > ul > li:first-child > a,
    .noads #main-menu > ul > li.hover:first-child > a:after {
        z-index: 401;
    }

    .noads #main-menu > ul > li:first-child > a span {
        z-index: 402;
    }

    #main-menu > ul.extra {
        margin-bottom: 24px;
    }

    #main-menu .extra-menu {
        background-color: #93c762;
        -webkit-box-shadow: 0 -2px 3px -1px rgba(74, 100, 49, 0.3);
        box-shadow: 0 -2px 3px -1px rgba(74, 100, 49, 0.3);
        display: none;
        font-family: Arial, Geneva, Lucida, sans-serif;
        font-size: 0.923em;
        font-weight: bold;
        position: absolute;
        top: 40px;
        right: 0;
        left: 0;
        text-align: center;
    }

    #main-menu .active .extra-menu {
        display: block;
    }

    #main-menu .extra-menu > div {
        display: table;
        table-layout: fixed;
        width: 100%;
    }

    #main-menu .extra-menu ul {
        display: table-row;
    }

    #main-menu .extra-menu ul li {
        border-left: 1px solid white;
        display: table-cell;
        line-height: 24px;
        vertical-align: top;
    }

    #main-menu .extra-menu ul li:first-child {
        border-left: none;
    }

    #main-menu .extra-menu ul li a {
        color: white;
        display: block;
        padding: 0 10px;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
    }

    #main-menu .extra-menu ul li.active a,
    #main-menu .extra-menu ul li:hover a {
        background-color: #7bb543;
        text-decoration: none;
    }
}
@media screen and (min-width: 1300px) {
    #page_index.page.fixed-width #main-menu > ul > li > a,
    #page_showpost.page.fixed-width #main-menu > ul > li > a,
    #page_forumdisplay.page.fixed-width #main-menu > ul > li > a,
    #page_showthread.page.fixed-width #main-menu > ul > li > a {
        padding: 0 18px;
    }
    #page_index.page.fixed-width #main-menu > ul,
    #page_forumdisplay.page.fixed-width #main-menu > ul,
    #page_showthread.page.fixed-width #main-menu > ul {
        max-width: 1046px !important;
    }
    #page_index .desktop-fixed #main-menu > ul > .display-desktop-variable,
    #page_forumdisplay .desktop-fixed #main-menu > ul > .display-desktop-variable,
    #page_showthread .desktop-fixed #main-menu > ul > .display-desktop-variable,
    #page_index .desktop-variable #main-menu > ul > .display-desktop-fixed,
    #page_forumdisplay .desktop-variable #main-menu > ul > .display-desktop-fixed,
    #page_showthread .desktop-variable #main-menu > ul > .display-desktop-fixed {
        display: table-cell;
    }
}
@media screen and (max-width: 1499px) {
    .takeover-enabled #page_index.page.fixed-width  #main-menu .submenu,
    .takeover-enabled #page_forumdisplay.page.fixed-width  #main-menu .submenu,
    .takeover-enabled #page_showthread.page.fixed-width  #main-menu .submenu {
        width: 980px;
        right: calc((100% - 980px) / 2 );
        left: calc((100% - 980px) / 2 );
    }
}
@media screen and (max-width: 1800px) {
    .takeover-enabled #page_index.page.fixed-width #main-menu > ul > li.display-desktop-variable:nth-child(n+12),
    .takeover-enabled #page_showpost.page.fixed-width #main-menu > ul > li.display-desktop-variable:nth-child(n+12),
    .takeover-enabled #page_forumdisplay.page.fixed-width #main-menu > ul > li.display-desktop-variable:nth-child(n+12),
    .takeover-enabled #page_showthread.page.fixed-width #main-menu > ul > li.display-desktop-variable:nth-child(n+12) {
        display: none;
    }
}
@media screen and (max-width: 1200px) {
    .desktop-variable #main-menu > ul > li {
        width: auto;
    }

    #main-menu > ul > .display-all.search-beta {
        display: none;
    }

    #main-menu > ul > .display-all.search-beta a {
        background: #3fabea;
    }
}

/* Header - Bookmarks */

#bookmarks ul {
    float: left;
    width: 205px;
}

#bookmarks .heading + ul {
    margin-right: 10px;
}

#bookmarks .edit {
    color: #FFFFFF !important;
    margin: 16px 0 0;
    padding: 5px 7px;
    position: absolute;
    bottom: 5px;
    right: 30px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    width: 191px;
}

#bookmarks .edit:after {
    content: " \00bb";
}

#bookmarks .invisible {
    visibility: hidden;
}

/* Header - Expandable menu underlay */

.expandable-underlay {
    display: none;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
}

/* Subnavigation */

.subnav,
#wiki_content .subnav {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/base/white.gif") repeat-x 0 30px #f2f2ed;
    margin: 0 0 15px;
    max-height: 61px;
}

.subnav li,
#wiki_content .subnav li {
    border-right: 1px solid white;
    display: inline-block;
    margin: 0 0 1px;
}

.subnav li > a,
.subnav li > span,
.subnav li.blob > a:hover,
.subnav li.blob > span:hover {
    background-color: transparent;
    color: #369;
}

.subnav li > a,
.subnav li > span {
    display: block;
    line-height: 30px;
    padding: 0 12px;
}

.subnav li.blob > a,
.subnav li.blob > span {
    padding: 0 7px;
}

.subnav li > a:hover,
.subnav li > span:hover {
    background-color: #ddddd2;
    color: #505050;
}

.bubble.secondary.light {
    margin: 10px;
    width: 85px;
    height: 28px;
}

.gibsonSemibold {
    font-family: Gibson, "Myriad Web Pro", "Myriad Web", "Myriad Std", "Frutiger LT Std", "Segoe UI", Arial, sans-serif;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.5px;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    -webkit-text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}

.glyph.menu:before {
    display: none;
}

.register-home-form .ordered-form label span,
.register-home-form .ordered-form .info,
#regusername-ghost {
    display: none;
}

/** header **/
/* Notification item */

#user-menu .bucket .notification {
    border-bottom: 1px dotted #dbdcdd;
    min-height: 40px;
}

#user-menu .bucket .notification > a {
    display: block;
    padding: 3px 4px 3px 44px;
    position: relative;
}

#user-menu .bucket .notification > a:hover {
    text-decoration: none;
}

#user-menu .bucket .notification > a:hover > .title {
    text-decoration: underline;
}

#user-menu .bucket .notification > a > span {
    display: block;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
}

#user-menu .bucket .notification > a > .title {
    color: #FFFFFF;
    font-weight: normal;
}

#user-menu .bucket .notification.new > a > .title {
    color: #FFFFFF;
    font-weight: bold;
}

#user-menu .bucket .notification > a > .info {
    color: #FFFFFF;
}

/* Notification item - icons */

#user-menu .bucket .notification > a:before {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/base/sprite-notifications-alternate.png") no-repeat 0 0;
    content: "\a0";
    height: 32px;
    position: absolute;
    top: 4px;
    left: 6px;
    width: 32px;
    opacity: 0.6;
}

#user-menu .bucket .notification.new > a:before {
    opacity: 1;
}

#user-menu .bucket #pmList .notification.pm > a:before {
    background: none !important;
}

#user-menu .bucket .notification.pm.new > a:before {
    background-position: 0 9px;
}

#user-menu .bucket .notification.friend > a:before {
    background-position: -1px -24px;
}

#user-menu .bucket .notification.socgroupreq > a:before {
    background-position: 0 -57px;
}

#user-menu .bucket .notification.pc > a:before {
    background-position: 0 -91px;
}

#user-menu .bucket .notification.quote > a:before,
#user-menu .bucket .notification.quote_legacy > a:before {
    background-position: 0 -120px;
}

#user-menu .bucket .notification.pprm > a:before {
    background-position: 0 -154px;
}

#user-menu .bucket .notification.repreceived > a:before,
#user-menu .bucket .notification.repreceived_legacy > a:before {
    background-position: 0 -186px;
}

#user-menu .bucket .notification.socgroupinv > a:before {
    background-position: 0 -217px;
}

#user-menu .bucket .notification.vm > a:before {
    background-position: 0 -248px;
}

#user-menu .bucket .notification.threadwatched > a:before {
    background-position: -1px -281px;
}

#user-menu .bucket .notification.follow > a:before {
    background-position: -1px -313px;
}

#user-menu .bucket .notification.alert > a:before {
    background-position: 0 -345px;
}

#user-menu .bucket .notification.pm > a:before {
    background-position: -1px -377px;
}

#user-menu .bucket .notification.connect > a:before {
    background-position: -1px -412px;
}
#user-menu .bucket .notification.threaddeleted > a:before,
#user-menu .bucket .notification.postdeleted > a:before,
#user-menu .bucket .notification.threadreview > a:before,
#user-menu .bucket .notification.postreview > a:before {
    background-position: -1px -441px;
}

#user-menu .bucket .notification.postapproved > a:before {
    background-position: -1px -473px;
}

#user-menu .bucket .notification.threadapproved > a:before {
    background-position: -1px -505px;
}

#user-menu .bucket .notification.badge > a:before {
    background-position: -1px -537px;
}

#user-menu .bucket .notification.mention > a:before {
    background-position: -1px -569px;
}

#user-menu .bucket .notification.tagpost > a:before {
    background-position: -1px -604px;
}

#user-menu .bucket .notification.goldmember_add > a:before,
#user-menu .bucket .notification.goldmember_remove > a:before {
    background-position: -1px -635px;
}

#user-menu .bucket .notification.quote_anon > a:before {
    background-position: -1px -664px;
}

#user-menu .bucket .notification.unanswered > a:before {
    background-position: -1px -696px;
}

#user-menu .bucket .notification.dpc_continue > a:before {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/base/notify-dpc.svg") no-repeat 0 0;
    left:14px;
    top:11px;
}

#user-menu .bucket .notification.threadapproved .dateline,
#user-menu .bucket .notification.postapproved .dateline,
#user-menu .bucket .notification.threaddeleted .dateline,
#user-menu .bucket .notification.postdeleted .dateline,
#user-menu .bucket .notification.threadreview .dateline,
#user-menu .bucket .notification.postreview .dateline {
    display: none;
}

#user-menu .bucket .notification .title,
#user-menu .bucket .notification .info span {
    font-size: 14px;
}

#user-menu .bucket .notification .info span,
#user-menu .bucket .notification.pm.new .info .dateline {
    font-weight: bold;
}

/* Notification item - with image */

#user-menu .bucket .notification.image > a {
    padding-left: 0;
}

#user-menu .bucket .notification.image > a:before {
    content: none;
}

#user-menu .bucket .notification.image > a > img {
    background-color: white;
    border: 1px solid #c3c5c6;
    float: left;
    height: 32px;
    margin: 0 7px 0 5px;
    width: 32px;
}

#search-beta-banner {
    display: none;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: 48px;
    line-height: 28px;
    padding: 10px 12px;
    color: #fff;
    background: #32b3e6;
}

#search-beta-banner .beta-message {
    font-size: 15px;
}

#search-beta-banner .beta-new {
    font-weight: bold;
}

#beta-button {
    float: right;
}

#beta-button .bubble.secondary {
    background-color: #3086bd;
    border-color: #3086bd;
}

/* Retina images */
img.default.standard
img.custom.standard {
    margin: 12px 0 0 0;
    display: inline;
    width:227px;
}

img.custom.standard {
    margin: 12px 0 0 0;
    width:227px;
}

img.retina {
    display: none;
}

.blue_bg_logo img {
    width: 150px;
}

@media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
    #logo > .standard,
    #logo-flair > .standard {
        display: none;
        width:150px;
    }

    img.default.standard {
        display: none;
        width:150px;
    }

    img.custom.standard {
        display: none;
    }

    img.retina {
        display: inline;
        width:150px;
        margin: 3px 0 0 0;
    }
    img.logo-mobile-2x
    img.logo-mobile{
        margin: 0px 0 0 0!important;
    }

    #logo > .default.retina {
        margin: 3px 0 0 0;
    }

    #logo > .default.retina,
    #logo > .custom.retina,
    #logo-flair > .retina {
        display: inline;
        width:150px;
    }
}


@media screen and (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px),
       screen and (min-resolution: 120dpi) and (max-width: 480px) {

    img.retina {
        display: inline;
        width: 133px;
        margin: 0 0 0 0;
    }

    #user-menu .bucket .notification > a:before {
        background-image: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/base/sprite-notifications@2x.png");
        background-size: 32px 707px;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 481px),
       screen and (min-resolution: 120dpi) and (min-width: 481px) {
    #user-menu .bucket .notification > a:before {
        background-image: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/base/sprite-notifications-alternate@2x.png");
        background-size: 32px 737px;
    }

    #user-menu .bucket .notification.pm.new > a:before {
        background-position: 0 9px;
    }
    
    #user-menu .bucket .notification.friend > a:before {
        background-position: -1px -24px;
    }
    
    #user-menu .bucket .notification.socgroupreq > a:before {
        background-position: 0 -57px;
    }
    
    #user-menu .bucket .notification.pc > a:before {
        background-position: 0 -91px;
    }
    
    #user-menu .bucket .notification.quote > a:before,
    #user-menu .bucket .notification.quote_legacy > a:before {
        background-position: 0 -122px;
    }
    
    #user-menu .bucket .notification.pprm > a:before {
        background-position: 0 -158px;
    }
    
    #user-menu .bucket .notification.repreceived > a:before,
    #user-menu .bucket .notification.repreceived_legacy > a:before {
        background-position: 0 -191px;
    }
    
    #user-menu .bucket .notification.socgroupinv > a:before {
        background-position: 0 -222px;
    }
    
    #user-menu .bucket .notification.vm > a:before {
        background-position: 0 -254px;
    }
    
    #user-menu .bucket .notification.threadwatched > a:before {
        background-position: 0 -288px;
    }
    
    #user-menu .bucket .notification.follow > a:before {
        background-position: -1px -320px;
    }
    
    #user-menu .bucket .notification.alert > a:before {
        background-position: 0 -352px;
    }
    
    #user-menu .bucket .notification.pm > a:before {
        background-position: -1px -386px;
    }
    
    #user-menu .bucket .notification.connect > a:before {
        background-position: -1px -421px;
    }
    #user-menu .bucket .notification.threaddeleted > a:before,
    #user-menu .bucket .notification.postdeleted > a:before,
    #user-menu .bucket .notification.threadreview > a:before,
    #user-menu .bucket .notification.postreview > a:before {
        background-position: -1px -451px;
    }
    
    #user-menu .bucket .notification.postapproved > a:before {
        background-position: -1px -483px;
    }
    
    #user-menu .bucket .notification.threadapproved > a:before {
        background-position: -1px -516px;
    }
    
    #user-menu .bucket .notification.badge > a:before {
        background-position: -1px -551px;
    }
    
    #user-menu .bucket .notification.mention > a:before {
        background-position: -1px -583px;
    }
    
    #user-menu .bucket .notification.tagpost > a:before {
        background-position: -1px -620px;
    }
    
    #user-menu .bucket .notification.goldmember_add > a:before,
    #user-menu .bucket .notification.goldmember_remove > a:before {
        background-position: -1px -651px;
    }
    
    #user-menu .bucket .notification.quote_anon > a:before {
        background-position: -1px -681px;
    }
    
    #user-menu .bucket .notification.unanswered > a:before {
        background-position: -1px -714px;
    }
}

/**
offset anchors
**/
:target:before {
    content:"";
    display:block;
}


#main-menu > ul > li:first-child > .submenu > .column.last{
    margin-top: 25px;

}

@media screen and (max-width: 480px),
(-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px),
(min-resolution: 120dpi) and (max-width: 480px) {
    #user-menu {
        padding: 0 49px 0 0;
    }
}

/* Standard mobile styles */
#user-menu li.search > div.glyph {
    display: none;
}
@media screen and (max-width: 480px),
(-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px),
(min-resolution: 120dpi) and (max-width: 480px) {
    #user-menu li.search > div.glyph {
        display: block !important;
    }
    #searchTools {
        display: none;
    }
    #user-menu .bucket > span {
        position: relative;
    }
    #user-menu .unread-count {
        margin: 0 !important;
        position: absolute;
        top: 22%;
        left: 38%;
        font-size: 11px !important;
    }
    #user-menu > ul > li.search {
        height: 54px !important;
        display: inline-block;
        text-align: center;
        margin-right: -2px;
    }
    #page-search #ps-query input {
        color: #444;
    }
    #user-menu > ul > li.search.expanded {
        background-color: #369bd6;
    }
    #user-menu li.search > div.glyph {
        display: inline-block;
        padding: 0;
        color: #fff;
        line-height: normal;
        position: relative;
        top: 17px;
        background: none;
    }
    #user-menu li.search > div.glyph:before {
        font-size: 22px;
    }
    #page-search {
        background-color: #369bd6;
        border-width: 1px 0;
        box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.25);
        cursor: default;
        padding: 9px;
        position: absolute;
        box-sizing: border-box;
        width: 100%;
        height: auto !important;
        top: 54px !important;
        left: 0;
        display: none;
    }
    #user-menu > ul > li.search.expanded #page-search {
        display: block;
    }
    #header #page-search .search-bar {
        border-color: #2d83b6 !important;
        width: 100%;
        height: auto;
        margin: 0;
    }
    #header #page-search #ps-query input {
        height: 36px;
    }
    #header #page-search .search-bar button {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        padding: 0;
        width: 33px;
        outline-offset: -1px;
    }
    #header #page-search .search-bar button > .glyph {
        color: #272727;
    }

    #user-menu .signin {
        margin-right: -5px;
    }
    #user-menu .search .glyph:before {
        margin-right: 0;
    }
    #user-menu .glyph:not(.search):before {
        font-size: 18px;
    }
    #main-menu:before {
        width: 43px;
    }
    #user-menu > ul > li .bubble{
        height:26px;
        min-height: 0;
    }
    #user-menu  .register {
        padding-right: 5px;
    }
    #user-menu .search {
        display: block;
    }
    #user-menu > ul > li.inbox > span,
    #user-menu > ul > li.notifications > span {
        padding: 7px 0 !important;
        box-sizing: border-box;
        height: 54px !important;
    }
    #user-menu > ul > li.notifications:not(.zero) > span,
    #user-menu > ul > li.inbox:not(.zero) > span {
        min-width: 43px !important;
    }
    #user-menu > ul > li.notifications.zero > span,
    #user-menu > ul > li.inbox.zero > span {
        min-width: 43px !important;
        text-align: center;
    }
}

/* Traffic Driver. */



.blue_bg {
    position: absolute;
    top: -9px;
    left: 0px;
    z-index: 1;
    height: 50px!important;
    width: 52px;
    background-color: #7B63DB;
}
.blue_bg_logo {
    z-index: 10!important;
    position: absolute!important;
    width:133px!important;
}

.calendartext {
    height: 44px;
    font-size: 14px;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    -webkit-text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}


@media screen and (min-width: 481px) {
    li.trafficdriver.mobile {
        height: 0px;
        visibility: hidden;
        width:0px;
    }
    li.trafficdriver-noads.mobile {
        height: 0px;
        visibility: hidden;
        width:0px;
    }
    a.trafficdriver
    {
        height:44px;
        line-height:44px;
    }
}
@media screen and (max-width: 480px),
(-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px),
(min-resolution: 120dpi) and (max-width: 480px) {
    .trafficdriver_on
    {
        height:40px;
    }

    .blue_bg {
        position: absolute;
        top: -9px;
        left: 0px;
        z-index: 1;
        height: 0px!important;
        width: 0px!important;
        background-color: #7B63DB;
    }

    li.trafficdriver.desktop {
        height: 0px;
        visibility: hidden;
        width:0px;
    }

    a.trafficdriver
    {
        text-decoration: none ! important;
    }

    li.trafficdriver.mobile {
        height: 50px;
        position: relative;
        margin-top: -5px;
        background-color: #2492c7;
    }
    li.trafficdriver.mobile span
    {
        position: absolute;
        left: 20px;
        color: #fff;
        top: 18px;
    }

    a.bubble.secondary.arrow-right.trafficdriver {
        top: 14px;
        right: 10px;
        position: absolute;
    }

    #header_container.trafficdriver.hidden {
        transform: translate3d(0,-100px, 0) !important;
    }

    div#main-menu.traffic_driver_on {
        top: 158px!important;
    }

    #header #user-menu {
        height: 63px !important;
    }


    /* no ads. */
    a.bubble.secondary.arrow-right.trafficdriver-noads {
        top: 4px;
        right: 10px;
        position: absolute;
    }
    li.trafficdriver-noads.mobile span
    {
        position: absolute;
        left: 20px;
        color: #fff;
        top: 9px;
    }
    li.trafficdriver-noads.mobile {
        height: 50px;
        margin-top: -60px;
        background-color: #2492c7;
    }
    .trafficdriver_on-noads {
        height: 0px;
        top: -15px;
        position: relative;
    }
    div#main-menu.traffic_driver_on-noads {
        top: 104px!important;
    }

    .nofixedad {
        margin-top:0px!important;
    }

    .trafficdriver_tsrl {
        margin-top: -50px;
    }
}

#page_profile #user-menu #logo {
    display: none;
}

.trafficdriver_arrow {
    position: relative;
    top:-1px;
}

.show_it {
    display: block;
}
.hide_it {
    display: none;
}
