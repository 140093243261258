/**
* Relates the to Poll widget in the sidebar and possibly forum polls.
* @author A TSR Developer
* @device desktop
*/

/* ## start-device-block desktop ##*/
.poll-container { margin:0; padding:0; color:#666666; border-top:2px solid #006DA8 }

.vote-button {
    padding:3px 7px 5px 7px; 
    margin:0px 0px 0px 5px; 
    border:none !important; 
    cursor:pointer;
    font-size:9pt;
}

.poll-container .sprite-thread {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/statusicons/sprite-thread.png") no-repeat;
    display: inline-block;
}

.poll-container .sprite-thread-poll {
    background-position: -144px 0;
    width:22px;
    height:25px;
}

.poll-container .title {
    position:relative;
    top:-6px;
    padding-left:3px;
}

.poll-container .edit-poll-link {
     float:right; 
     position:relative; 
     top:6px;
}

.poll-container .poll-header {
     background-color:#E8F2F8; 
     padding:2px 10px 2px 10px; 
     color:#006DA8;
}

.poll-container .poll-close-date
, .poll-container .result-footer
{
     border-bottom:1px dotted #CCCCCC; 
     padding:8px 10px 8px 10px;
     text-align:center;
}

.poll-container .poll-option-holder
, .poll-container .poll-result-option
{
    display:inline-block; 
    width:100%; 
    padding-bottom:3px;
}

.poll-container .poll-body {
    border-bottom:1px dotted #CCCCCC; 
    padding:8px 10px;
}

.poll-container .poll-result-option {
    border-bottom:1px dotted #CCCCCC; 
}

.poll-container .poll-buttons {
     padding:8px 0px 8px 0px; 
     text-align:right;
}

#page-columns .poll-container .poll-result-option .poll_result_container div {
	float: left;	
	width: 20%;
	margin: 5px;
}

#page-columns .poll-container .poll-result-option .poll_result_container .poll_results_percent {
        width: 15%;
}

#page-columns .poll-container .poll-result-option .poll_result_container .poll_results_question {
	width: 30%;
}
#page-columns .poll-container .poll-result-option .poll_result_container .poll_results_bar {
	width: 35%;
}


#page-columns .poll-container .poll-option-holder label img {
	max-width:70%;
}

#page-columns .poll-container .poll-result-option .poll_result_container .poll_result_question img {
	max-width: 100%;
}


/* ## end-device-block ## */