/**
* Inline/block elements for forum post controls.
* @author A TSR Developer
* @device all
*/


/**
 * Controls, used on forum pages
 */

/* Control bar */

.ctl-bar,
tr.ctl-bar > td,
tr.ctl-bar > th {
    background-color: #7B63DB;
}

.ctl-bar {
    margin: 10px 0;
}

.ctl-bar:after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    visibility: hidden;
}

tr.ctl-bar:after {
    content: "";
    content: none;
}

.ctl-bar,
.ctl-bar a {
    color: white !important;
}

tr.ctl-bar {
    background: none;
    filter: none;
}

tr.ctl-bar > td,
tr.ctl-bar > th {
    padding: 0 10px;
}

.ctl-bar .interact {
    cursor: pointer;
    margin-left: 12px;
    vertical-align: middle;
}

.ctl-bar .interact:first-child {
    margin-left: 7px;
}

.ctl-bar .left-ctl {
    float: left;
    margin-left: 10px;
}

.ctl-bar .right-ctl {
    float: right;
    margin-right: 10px;
}

.ctl-bar .left-ctl > div,
.ctl-bar .right-ctl > div {
    display: table-cell;
    height: 42px;
    vertical-align: middle;
}

/* Control bar - tabs */
.ctl-bar .ctl-tab {
    background: #2eb3e2;
    border: 1px solid #78cbf4;
    border-bottom-width: 0;
    display: inline-block;
    line-height: 22px;
    margin: 7px 0 0px 3px;
}

/* Mobile styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    .ctl-bar .ctl-tab {
        line-height: 26px;
        margin: 8px 0 0 5px;
        text-decoration: none;
    }
}

.ctl-bar .active .ctl-tab {
    margin-top: 5px;
}

/* Mobile styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    .ctl-bar .active .ctl-tab {
        margin-top: 8px;
    }
}

/* These rules are to sort out an annoying "helper" class that actually helps fuck all */
.ui-tabs .ctl-bar .ui-tabs-nav li .ctl-tab {
    float: none;
}

.ctl-bar .ctl-tab,
.ui-tabs .ctl-bar .ui-tabs-nav li .ctl-tab {
    padding: 0 12px;
}

.ctl-bar .ctl-tab.selected,
.ctl-bar .ui-tabs-active .ctl-tab {
    background: white;
    color: #4492c6 !important;
    line-height: 26px;
    font-size: 1.333em;
    margin-top: 5px;
    margin-bottom: 0;
}

/* Mod control bar */

.ctl-bar.mod,
tr.ctl-bar.mod > td,
tr.ctl-bar.mod > th {
    background: #BDB1ED;
    border-bottom: 1px dotted #ccc;
}

.ctl-bar.mod {
    color: #272727 !important;
}

.ctl-bar.mod a {
    color: #7B63DB !important;
}

.ctl-bar.mod a:hover {
    color: #6652B8 !important;
}

.ctl-bar ~ .ctl-bar.mod,
.ctl-bar.mod ~ .ctl-bar {
    margin-top: -10px;
}

/* Title bar */

.ctl-bar.title-bar {
    background-color: #3ba7d2;
    font-size: 1.25em;
    line-height: 30px;
    margin: 0;
    padding: 0 6px;
}

/* Pager */

ul.pager,
ul.pager li {
    display: inline-block;
    vertical-align: top;
}

ul.pager {
    font-size: 0.917em; /* 11px */
    line-height: 42px;
    margin: 0 !important;
}

ul.pager li a,
ul.pager li span {
    display: block;
    padding: 0 2px;
    min-width: 13px;
    text-align: center;
}

ul.pager li.current {
    font-weight: bold;
}

ul.pager .pager-first,
ul.pager .pager-last,
ul.pager .pager-rew,
ul.pager .pager-ff {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/control/sprite-pager.png") no-repeat;
    font-size: 1px;
}

.ctl-bar ul.pager .pager-first, .pagination_links ul.pager .pager-first,
.ctl-bar ul.pager .pager-last, .pagination_links ul.pager .pager-last,
.ctl-bar ul.pager .pager-rew, .pagination_links ul.pager .pager-rew,
.ctl-bar ul.pager .pager-ff, .pagination_links ul.pager .pager-ff {
    background-image: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/control/sprite-pager-white.png");
}

ul.pager .pager-first {
    background-position: center 16px;
}

ul.pager .pager-last {
    background-position: center -10px;
}

ul.pager .pager-rew {
    background-position: center -36px;
}

ul.pager .pager-ff {
    background-position: center -62px;
}

ul.pager .pager-ff:hover
, ul.pager .pager-last:hover
, ul.pager .pager-first:hover {
    text-decoration:none;
}

.ctl-bar ul.pager li.current, .pagination_links ul.pager li.current {
    cursor: default;
    position: relative;
}

.ctl-bar ul.pager li.current:after, .pagination_links ul.pager li.current:after {
    content: "\a0";
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/control/pager-current.png") no-repeat 0 -1px;
    height: 8px;
    margin-left: -8.5px;
    position: absolute;
        bottom: -1px;
        left: 50%;
    width: 17px;
}

.ctl-bar.bottom ul.pager li.current:after, .pagination_container.bottom .pagination_links ul.pager li.current:after {
    background-position: 0 -7px;
    bottom: auto;
    top: -1px;
}

/* Skip to page form */

.page-jump {
    display: inline-block;
    margin-left: 10px;
    vertical-align: top;
}

.page-jump form {
    display: table-cell;
    height: 42px;
    vertical-align: middle;
}

.page-jump fieldset {
    border: none;
    padding: 0;
}

.page-jump label {
    color: #7B63DB;
    font-weight: bold;
    line-height: 12px;
}

.ctl-bar .page-jump label, .pagination_links .page-jump label {
    color: white;
}

.page-jump label span {
    display: inline-block;
    text-align: right;
    vertical-align: middle;
    width: 48px;
}

.page-jump.perpage-jump label span {
    width: 36px;
}

.page-jump .pj-page {
    border-color: #7B63DB !important;
    border-radius: 3px 0 0 3px;
    border-right-width: 0 !important;
    margin: 0;
    width: 3em;
}

.page-jump .submit {
    background: white;
    border: solid #7B63DB;
    border-radius: 0 3px 3px 0;
    border-width: 1px 1px 1px 0;
    cursor: pointer;
    margin-top: 1px;
    padding: 1px;
    vertical-align: top;
}

.page-search fieldset {
    white-space: nowrap;
}

.page-search .query {
    border-color: #c3c3c3;
    border-radius: 2px;
    box-shadow: inset 0 0 3px rgba(223, 220, 220, 0.8);
    margin: 0 3px 0 0;
    outline: none;
    padding: 3px;
    width: 200px;
}

.page-search .query:-moz-placeholder {
    color: #999;
}

.page-search .query:-ms-placeholder {
    color: #999;
}

.page-search .query::-webkit-input-placeholder {
    color: #999;
}

.page-search a {
    margin-top: 3px;
}

.go-button {
    height:25px !important;
    width:35px !important;
    vertical-align:middle;
}
