/**
* CSS rules pertaining to the top of the main content area.
* @author A TSR Developer
* @device mixed
*/

/* Section header */

.section-header {
    border-top: 2px solid #7B63DB;
    color: #272727;
    font-size: 1.083em;
    line-height: 1.308em;
    margin: 15px 0 15px;
    position: relative;
    border-bottom: 2px solid #7B63DB;
}

.section-header a {
    color: #7B63DB;
}

.section-header a {
    color: #6652B8;
}

.section-header h1 {
    color: #7B63DB;
    font-size: 1.385em;
    font-weight: bold;
    line-height: 32px;
    margin: 0px;
}

.section-header.simple {
    font-size: 1.083em;
    line-height: 1.308em;
    margin: 15px 0 30px;
    position: relative;
    border-top: none;
}

.section-header.simple {
    border-bottom: 2px solid #99FFA8;
    font-size: 2em;
}

#follow_page .section-header.simple,
#page_private .section-header.simple {
    border-bottom: 2px solid #7B63DB;
    font-size: 2em;
}

.sturdy.section-header h1,
div.cms-content .section-header h1 {
    font-size: 2em;
}

.sturdy.section-header,
div.cms-content .section-header {
    border-bottom: 2px solid #99FFA8;
    border-top:none;
}

.section-header > .social-media {
    font-size: 0.722em;
    height: 20px;
    line-height: 20px;
    margin: 6px 0;
    position: absolute;
        top: 0;
        right: 0;
    width: 210px;
    overflow: hidden;
}

.social-media #mobile_watch_button {
	display: none;
}

.social-media .fb-container {
    float: left;
}

.social-media .tweet {
    width: 79px;
    overflow: hidden;
    float: right;
}

#social-buttons .fb-container {
    padding-right: 20px;
    display: inline-block;
}

#social-buttons .social-container {
    padding-right: 20px;
    display: inline-block;
}

.section-header p {
    margin: 10px 0;
}

.section-header ul {
    clear: left;
    margin: 10px 0 5px;
}

.section-header ul li {
    display: inline-block;
    vertical-align: top;
    width: 33.33%;
}

.lte7 .section-header ul li {
    display: inline;
}

.section-header ul li a {
    display: inline-block;
    line-height: 22px;
}

ul.usp-bar li {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/miscellaneous/tick2.png") no-repeat left center;
    display: inline-block;
    line-height: 17px;
    margin-left: 19px;
    padding-left: 22px;
    vertical-align: top;
    width: auto;
}

.usp-bar li:first-child {
    margin-left: 0;
}

/* University Navigation */

.university-navigation {
	background-color: #7B63DB;
	width: 100%;
	height: 38px;
}

.university-navigation .university-navigation-header {
	display: none;
}

.university-navigation ul li {
	width: auto;
	height: 28px;
	padding: 6px 7px 0px 7px;
	border-right: 1px solid #99FFA8;
	font-family: Arial !important;
	font-size: 13px;
	float: left;
}

.fixed-width .university-navigation ul li {
	padding: 6px 30px 0px 30px;
}

.fixed-width .wide-gutter .university-navigation ul li {
	padding: 6px 11px 0px 11px;
}

.fixed-width .wide-gutter .university-navigation ul li.course_node_id {
	max-width: 54px;
}

.fixed-width .wide-gutter .university-navigation ul li.course_node_id a {
	line-height: 12px;
}

#connect .university-navigation ul li {
	height: 27px;
	padding: 11px 31px 0 31px;

}

.university-navigation ul a:after {
	content: none;
}

.university-navigation ul li a {
	color: #fff !important;
	text-align: center;
}

.university-navigation ul li.active a {
	display: block;
	position: relative;
	padding: 0px 6px;
}

#connect .university-navigation {
	margin-top: -10px;
	margin-bottom: 10px;
}

.university-navigation ul li:hover {
	background-color: #6652B8;
}

.university-navigation ul li a:hover {
	text-decoration: none;
}

.university-navigation ul li.active {
	padding-top: 0px;
	background-color: #6652B8;
	height: 38px;
    font-weight: 700;
}

.university-navigation ul li.active a {
	padding: 0px 6px;
}

#connect .university-navigation ul li:last-child {
	border-right: none;
}


.institution-links {
    margin-bottom: 33px;
}

.institution-links .inst-nav-forum-title {
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.06;
    text-align: left;
    color: #272727;
    padding-bottom: 5px;
}

.institution-links .inst-nav-forum .short,
.institution-links .inst-nav-forum .short.desktop {
    display: none;
}

.institution-links .inst-nav-forum .long {
    color: #7B63DB;
    display: inline-block;
    font-family: Arial;
    font-size: 15px;
    line-height: 1.27;
    vertical-align: top;
}

.institution-links .inst-nav-forum.type-b .long {
    color: white;
    display: inline-block;
    font-family: Arial;
    font-size: 15px;
    font-weight: bold;
    line-height: 1.27;
    padding: 0 5px 10px 5px;
    vertical-align: top;
}

.institution-links .inst-nav-forum,
.institution-links .inst-nav-forum.type-b {
    flex-direction: row;
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.institution-links .inst-nav-forum li,
.institution-links .inst-nav-forum.type-b li {
    flex: 0 1 140px;
    margin: 0 10px;
    padding: 0;
}
.institution-links .inst-nav-forum li a {
    display: block;
    text-align: left;
}
.institution-links .inst-nav-forum.type-b li a {
    display: block;
    text-align: center;
}

.institution-links .inst-nav-forum li img,
.institution-links .inst-nav-forum.type-b li img {
    display: block;
    padding-bottom: 10px;
    width: 100%;
}
.institution-links .inst-nav-forum.type-b li img {
    padding-bottom: 0;
}

.institution-links .inst-nav-forum .guideid,
.institution-links .inst-nav-forum.type-b .guideid {
    order: 1;
}
.institution-links .inst-nav-forum .openday_node_id,
.institution-links .inst-nav-forum.type-b .openday_node_id {
    order: 2;
}
.institution-links .inst-nav-forum .unichoice_forum_link,
.institution-links .inst-nav-forum.type-b .unichoice_forum_link {
    order: 3;
}
.institution-links .inst-nav-forum .aaq,
.institution-links .inst-nav-forum.type-b .aaq {
    order: 4;
}
.institution-links .inst-nav-forum .slug,
.institution-links .inst-nav-forum.type-b .slug {
    order: 5;
}
.institution-links .inst-nav-forum .uniguide_link_website,
.institution-links .inst-nav-forum.type-b .uniguide_link_website {
    order: 6;
}
.institution-links .inst-nav-forum .forumid,
.institution-links .inst-nav-forum.type-b .forumid{
    order: 7;
    display: none;
}

.institution-links .inst-nav-forum.type-b .guideid {
    background-color: #32b3e6;
}
.institution-links .inst-nav-forum.type-b .aaq {
    background-color: #75c583;
}
.institution-links .inst-nav-forum.type-b .openday_node_id {
    background-color: #4bc0b6;
}
.institution-links .inst-nav-forum.type-b .unichoice_forum_link {
    background-color: #369bd6;
}
.institution-links .inst-nav-forum.type-b .slug {
    background-color: #64C9D9;
}
.institution-links .inst-nav-forum.type-b .uniguide_link_website {
    background-color: #aade98;
}
.institution-links .inst-nav-forum.type-b .forumid {
    order: 7;
    display: none;
}


/* ## start-device-block mobile ##*/
@media screen and (max-width: 480px),
       screen and (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px),
       screen and (min-resolution: 120dpi) and (max-width: 480px) {
	.university-navigation .university-navigation-header h2 {
		color: #fff;
		padding-top: 10px;
		font-size: 15px;
		float: left;
	}

	.university-navigation .university-navigation-header span.arrow {
		height: 11px;
		width: 24px;
		position: absolute;
		top: 12px;
		right: 10px;
	}
	.university-navigation .university-navigation-header span.arrow.down {
		width: 0;
		height: 0;
		border-left: 9px solid transparent;
		border-right: 9px solid transparent;
		border-top: 9px solid #fff;
	}
	.university-navigation .university-navigation-header span.arrow.up {
		width: 0;
		height: 0;
		border-left: 9px solid transparent;
		border-right: 9px solid transparent;
		border-bottom: 9px solid #fff;
	}

	#connect .university-navigation {
		background: none;
		height: auto;
		position: relative;
	}
	#connect .university-navigation ul {
		background: none;
		color: white;
		line-height: 20px;
		width: 100%;
		text-align: left;
		display: inline-block;
		height: 100%;
		margin: 0 !important;
		padding: 0 !important;
		position: relative;
	}

	#connect .university-navigation ul li:first-child {
		border: 0;
	}

	#connect .university-navigation ul li {
		display: inline-block;
		margin: 0 !important;
		vertical-align: top;
		width: 48%;
		border-right: none;
		text-align: left;
		padding: 0 !important;
		float: left;
		padding-right: 5px !important;
		padding-bottom: 10px !important;
		position: relative;
		margin-bottom: 5px!important;
	}

	#connect .university-navigation ul li.active {
		background: none !important;
		height: 24px !important;
	}

	#connect .university-navigation ul li:hover {
		background: none;
	}

	#connect .university-navigation ul li a {
		background-color: #7B63DB;
		font-size: 14px;
		padding: 7px 21px 7px 10px;
		margin: 0;
		color: white;
		display: block;
		position: relative;
		text-decoration: none;
		z-index: 1;
		text-align: left;
	}

	#connect .university-navigation ul li.active a {
		background: none;
		background-color: #6652B8;
		max-width: none;
		text-align: left;
		padding: 6px 10px;
		margin-top: 0px;
		height: 22px;
        font-weight: 700;
	}
}
/* ## end-device-block ## */

/* University navigation (forum style) */

.section-header .inst-nav-forum {
    clear: both;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    margin: 15px 0;
}

.section-header .inst-nav-forum li {
    box-sizing: border-box;
    flex: 0 0 33.33%;
    padding: 5px 4px;
}

.section-header .inst-nav-forum li::before {
    background-color: #7B63DB;
    border-radius: 6px;
    content: "\a0";
    float: left;
    height: 6px;
    margin: 8px 8px 0 0;
    vertical-align: top;
    width: 6px;
}

.section-header .inst-nav-forum .forumid {
    display: none;
}

.section-header .inst-nav-forum li a {
    color: #7B63DB;
    display: inline;
}

.section-header .inst-nav-forum li a::after {
    content: none;
}

.section-header .inst-nav-forum li a .short,
.section-header .inst-nav-forum li a img {
    display: none;
}

@media screen and (max-width: 480px),
       screen and (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px),
       screen and (min-resolution: 120dpi) and (max-width: 480px) {
    .section-header .inst-nav-forum {
        flex-direction: column;
        margin: 14px 0 10px;
    }

    .section-header .inst-nav-forum li {
        padding: 8px 0 0;
    }

    .section-header .inst-nav-forum.forum .guideid {
        order: 1;
    }

    .section-header .inst-nav-forum.forum .is_openday {
        order: 4;
    }

    .section-header .inst-nav-forum.forum .unichoice_forum_link {
        order: 2;
    }

    .section-header .inst-nav-forum.forum .aaq {
        order: 3;
    }

    .section-header .inst-nav-forum.forum .slug {
        order: 5;
    }

    .section-header .inst-nav-forum.forum .uniguide_link_website {
        display: none;
    }

    .section-header .inst-nav-forum.thread {
        font-size: 15px;
    }

    .section-header .inst-nav-forum.thread li {
        display: none;
        padding: 0;
    }

    .section-header .inst-nav-forum.thread li::before {
        content: none;
    }

    .section-header .inst-nav-forum.thread .guideid {
        display: block;
    }

    .section-header .inst-nav-forum.thread li a {
        background-color: #44a0d4;
        color: white;
        display: block;
        font-weight: bold;
        line-height: 45px;
        padding-left: 10px;
        text-decoration: none;
    }

    .section-header .inst-nav-forum.thread li a::after {
        background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/tsr-institutions/nav/arrow.svg") no-repeat center center / 11px 22px #2b83c2;
        content: "\a0";
        float: right;
        height: 45px;
        padding: 0;
        width: 45px;
    }

    .section-header .inst-nav-forum.thread li a .short {
        display: inline;
    }

    .section-header .inst-nav-forum.thread li a .short.desktop,
    .section-header .inst-nav-forum.thread li a .long {
        display: none;
    }

    .institution-links {
        border-bottom: solid 2px #e2e3e4;
        margin-bottom: 33px;
    }

    .institution-links .inst-nav-forum-title {
        font-family: Arial, sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.06;
        text-align: left;
        color: #272727;
        border-bottom: solid 2px #e2e3e4;
        padding-bottom: 5px;
    }

    .institution-links .inst-nav-forum .short {
        vertical-align: top;
        display: inline-block;
        font-family: "Gibson", Arial, Geneva, Lucida, sans-serif;
        font-size: 16px;
        line-height: 43px;
        text-align: left;
        color: #4088b8;
        padding-left: 10px;
    }

    .institution-links .inst-nav-forum .long,
    .institution-links .inst-nav-forum .short.desktop{
        display: none;
    }

    .institution-links .inst-nav-forum {
        flex-direction: column;
        margin: 14px 0 0;
        display: flex;
    }

    .institution-links .inst-nav-forum li {
        padding: 8px 0 0 0;
        border-top: 1px dotted #dbdcdd;
        flex: unset;
        margin: 0;
    }

    .institution-links .inst-nav-forum li a {
        display: block;
    }

    .institution-links .inst-nav-forum li img {
        width: 60px;
        height: 47px;
        display: inline-block;
    }
    .institution-links .inst-nav-forum .guideid {
        order: 1;
    }
    .institution-links .inst-nav-forum .openday_node_id {
        order: 2;
    }
    .institution-links .inst-nav-forum .unichoice_forum_link {
        order: 3;
    }
    .institution-links .inst-nav-forum .aaq {
        order: 4;
    }
    .institution-links .inst-nav-forum .slug {
        order: 5;
    }
    .institution-links .inst-nav-forum .uniguide_link_website {
        order: 6;
    }
    .institution-links .inst-nav-forum .forumid {
        order: 7;
        display: none;
    }
    .institution-links .inst-nav-forum li:first-child {
        border-top: none;
        padding-top: 0px;
    }
}

@media screen and (min-width: 481px),
       screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 481px),
       screen and (min-resolution: 120dpi) and (min-width: 481px) {
    .section-header .inst-nav-forum.forum.type-b,
    .section-header .inst-nav-forum.thread.type-b {
        flex-wrap: nowrap;
        font-family: "Gibson", Arial, Geneva, Lucida, sans-serif;
        font-size: 15px;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
    }

    .section-header .inst-nav-forum.forum.type-b li::before,
    .section-header .inst-nav-forum.thread.type-b li::before {
        content: none;
    }

    .section-header .inst-nav-forum.forum.type-b {
        justify-content: center;
        margin: 20px 0;
    }

    .section-header .inst-nav-forum.forum.type-b li {
        flex: 0 1 140px;
        margin: 0 10px;
        padding: 0;
    }

    .section-header .inst-nav-forum.forum.type-b li:first-child {
        margin-left: 0;
    }

    .section-header .inst-nav-forum.forum.type-b li:last-child {
        margin-right: 0;
    }

    .section-header .inst-nav-forum.forum.type-b li a {
        display: block;
        line-height: 19px;
    }

    .section-header .inst-nav-forum.forum.type-b li a img {
        display: block;
        padding-bottom: 10px;
        width: 100%;
    }

    .section-header .inst-nav-forum.thread.type-b {
        background-color: #3bb5e0;
    }

    .section-header .inst-nav-forum.thread.type-b li {
        flex: 1 1 0;
        height: 50px;
        padding: 0;
        text-align: center;
    }

    .section-header .inst-nav-forum.thread.type-b .forumid {
        background-color: #369bd6;
        display: block;
    }

    .section-header .inst-nav-forum.thread.type-b .aaq {
        display: none;
    }

    .section-header .inst-nav-forum.thread.type-b li a {
        align-items: center;
        color: white;
        display: flex;
        font-weight: bold;
        height: 50px;
        justify-content: center;
        line-height: 1;
        padding: 0 5px;
        text-decoration: none;
        transition: all 0.2s;
    }

    .section-header .inst-nav-forum.thread.type-b li a:hover {
        background-color: #3392ca;
    }

    .section-header .inst-nav-forum.thread.type-b li a .short {
        display: inline;
    }

    .section-header .inst-nav-forum.thread.type-b li a .short.mobile,
    .section-header .inst-nav-forum.thread.type-b li a .long {
        display: none;
    }
}

.section-header .inst-nav-forum-button {
    display: none;
    line-height: 32px;
}

.section-header .inst-nav-forum-button .bubble {
    margin: 3px 0 3px 5px;
    vertical-align: top;
}
