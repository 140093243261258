/**
* Mainly block layout rules for main page structure. Some font rules also.
* @author A TSR Developer
* @device all
*/

/* vB Overrides */

html {
    background: #FFFFFF;
    -webkit-text-size-adjust: none;
}

body {
    min-width: 320px;
    transition: margin-top 0.2s;
}

body .page {
    padding: 0 !important;
}

.page #secondary_content,
.page #gutter_content {
    padding-left: 0 !important;
}

/* Main structure */

.page .page-section {
    margin: 0 auto;
    max-width: 1300px;
    min-width: 980px;
    width: auto;
}

#page_showthread.page .page-section {
    max-width: 1400px;
}

#page_home.page .page-section,
#page_connect.page .page-section,
#page_opendaycal.page .page-section,
#page_uniatoz.page .page-section,
#page_unichoice.page .page-section,
#page_login.page .page-section,
#page_register.page .page-section {
    max-width: 980px;
    box-sizing: border-box;
}

#page_vbcms.page .page-section {
    max-width: 980px;
    width: auto;
}

#page_vbcms.page #page-columns #primary_content {
    min-height: 600px;
    max-width: 620px;
}

#page_vbcms.page #page-columns.no-gutter #primary_content {
    min-height: 600px;
    max-width: 980px;
}

.takeover-enabled .page .page-section {
    width: 100%;
}

.takeover-enabled #page_showthread #page-columns > .column.main {
    max-width: 940px;
}

/* Main structure - Fixed width rules */

@media screen and (min-width: 481px),
       screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 801px),
       screen and (min-resolution: 120dpi) and (min-width: 801px) {
    body {
        width: 100% !important;
        min-width: 980px;
    }

    #page_showpost.page.fixed-width .page-section,
    #page_index.page.fixed-width .page-section,
    #page_forumdisplay.page.fixed-width .page-section,
    #page_showthread.page.fixed-width #content_container .page-section,
    #top_leaderboard_container {
        max-width: 1300px;
        min-width: 980px;
        width: auto;
    }

    #page_index.page.fixed-width #header.header-collapsed .menu.page-section {
        max-width: 1300px;
    }

    #page_index.page.fixed-width #footer .page-section,
    #page_showpost.page.fixed-width #footer .page-section,
    #page_forumdisplay.page.fixed-width #footer .page-section,
    #page_showthread.page.fixed-width #footer .page-section {
        margin: 0 auto !important;
        min-width: 0;
        width: 915px;
    }

    .takeover-enabled #page_index.page.fixed-width .page-section,
    .takeover-enabled #page_showpost.page.fixed-width .page-section,
    .takeover-enabled #page_forumdisplay.page.fixed-width .page-section,
    .takeover-enabled #page_showthread.page.fixed-width .page-section {
        max-width: 1300px;
        min-width: 940px;
        width: auto;
    }
}

/* Main structure - Columns */

#page-columns,
#page-section-top {
    background: white;
}

#page-columns {
    position: relative;
    padding-bottom: 44px;
    padding-top: 19px;
}

#page_home #page-columns {
    padding-top: 0;
}

/* Overqualified because of fixed width rules above */
#page-section-top.page-section {
    border-bottom: none;
    margin-bottom: 0;
    padding: 4px 10px 8px 10px;
    width: 960px;
}

#page-section-top + #page-columns {
    z-index: 1;
}

#page-columns {
    display: flex;
}

#page-columns > .column {
    flex-grow: 1;
    margin: 0 20px;
}

#page-columns > .column.side {
    flex: 1 0 300px;
    margin-left: 0;
    max-width: 300px;
    position: relative; /* for sticky sidebar content (spotlight+discussions) */
}

#page-columns #primary_content {
    min-height: 600px;
}

/* Main structure - No gutter */

#page-columns.no-gutter > .column.side,
.cms-edit-page #page_vbcms #page-columns > .column.side {
    display: none;
}

/* Main structure - structureless page */

.page.structureless {
    background: white;
    border: 3px solid #c3c5c6;
    overflow: hidden;
    padding: 0 16px !important;
}

#page_register {
    border: none;
}

/* Font overrides */
/* Stuff that uses the new brand should use Arial as the primary font */

#breadcrumb,
#breadcrumb .navbar,
.article-body,
.ctl-bar,
.ctl-bar input,
.ctl-bar label,
.ctl-bar select,
.ctl-bar td,
.ctl-bar th,
.featured-story,
.forum-category td,
.forum-header .interact,
.guest-reply,
.interact,
.minor-box,
.minor-box td,
.mod-header,
.pod,
.progress-steps,
#quick-reply .saved,
#quick-reply .action-autosave-restore,
.section-header,
.section-header h1 .social-media,
.side-message,
#social-buttons,
.subnav,
.slider,
.thread td,
.thread-filter,
.thread-list th,
.thread-review,
.thread-review h2 span,
.user-badges,
.widget,
.widget button,
.widget .widget-title .count,
.widget .widget-title .interact,
.why-register,
table.widget .widget_edit,
table.widget .widget-content,
.widget td,
#ad_sponsored_placeholder td,
.ctl-bar .ctl-tab,
#page_newreply .cke_editor textarea,
#page_newthread .cke_editor textarea,
#page_private .cke_editor textarea,
#page_profile .cke_editor textarea,
#page_report .cke_editor textarea,
#page_showthread .cke_editor textarea,
#mgc_cb_evo_input,
#register-welcome,
#whisper_message,
#whisper_username,
.hint,
.persistant-message,
#qr_defaultcontainer a,
#qr_defaultcontainer label,
#qr_defaultcontainer li,
#qr_defaultcontainer span,
#page_record,
#page_record form,
#page_notifications,
#page_psbuilder,
#page_psbuilder fieldset,
#page_psbuilder form,
#page_psbuilder label,
#page_psbuilder input,
#page_psbuilder select,
#page_psbuilder textarea,
#announcements .posted
{
    font-family: Arial, Geneva, Lucida, sans-serif !important;
}

.postbit_container a,
.postbit_container label,
.postbit_container div,
.postbit_container li,
.postbit_container span,
.postbit_container blockquote,
.postbit_container legend,
.postbit_container fieldset {
    font-family: Arial, Geneva, Lucida, sans-serif;
}

.article .title,
.article-comments .ctl-bar,
.article-preview h2.ctl-bar .title,
.ctl-bar .ui-tabs-active .ctl-tab,
.ctl-bar.title-bar,
.fieldset-group h2,
.forum-category th.title,
.guest-reply h2,
.interact.gibson-semibold,
.minor-box h2,
.minor-box th:first-child,
.notice h2,
.postbit-preview h2,
.section-header h1,
.slider .instruction,
.slider .success-msg,
.snippet-ul.hub-nav,
.thread-filter h3,
.thread-list th.title,
.thread-review h2,
.widget .widget-title,
.widget-ajaxplaceholder .title,
table.widget .widget_title,
#quickreply_title {
    font-family: Arial !important;
    font-weight: bold;
}

.article .title,
.article-comments .ctl-bar,
.article-preview h2.ctl-bar .title,
.ctl-bar .ui-tabs-active .ctl-tab,
.ctl-bar.title-bar,
.fieldset-group h2,
.forum-category th.title,
.guest-reply h2,
.interact.gibson-semibold,
.minor-box h2,
.minor-box th:first-child,
.notice h2,
.postbit-preview h2,
.section-header h1,
.slider .instruction,
.slider .success-msg,
.snippet-ul.hub-nav,
.thread-filter h3,
.thread-list th.title,
.thread-review h2,
.widget .widget-title div,
.widget-ajaxplaceholder .title,
table.widget .widget_title,
#quickreply_title {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* Mobile styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    .widget {
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

#header,
#header fieldset,
#header form,
#header label,
#header input,
#header select,
#footer {
    font-family: Arial !important;
}

#primary_content .section-header ol li {
    list-style-type: decimal;
    margin-left: 18px;
    padding: 10px 0;
}

/* Styles to support floating search */

.postbit_container a[name]:before {
    display:block;
    content:"";
    height:46px;
    margin:-46px 0 0;
}

@media screen and (min-width: 481px) and (max-width: 980px) {
    /* Variable width pages become fixed width */
    .page .page-section,
    #top_leaderboard_container,
    #page-ad-top-container,
    #page-ad-top-container #ad-underlay,
    #user-menu
    {
        margin: 0 auto;
        max-width: 980px !important;
        width: 980px !important;
    }

    #footer .page-section,
    #page-section-top.page-section {
        box-sizing: border-box;
    }
}

/* Miscellaneous */

.ui-helper-hidden-accessible {
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    position: absolute;
    width: 1px;
}

#cf_alert_div {
    margin-top: 44px;
    margin-bottom: -44px;
}

.content-loading-spinner {
    display:none;
}
