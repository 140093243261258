/**
* Rules relating to the actual posts. Has large block of media query for mobile content towards the later half.
* @author A TSR Developer
* @device all
*/



/* Thread icon sprite */

.thread-icon {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/base/thread-icons-sprite-v3.png") no-repeat;
    display: inline-block;
}

.thread-icon.cancel.active {
    background-position: 0 0;
}

.thread-icon.cancel {
    background-position: -18px 0;
    width: 17px;
    height: 17px;
}

.thread-icon.gender-female {
    background-position: -1116px 0;
    width: 11px;
    height: 17px;
}

.thread-icon.gender-male {
    background-position: -1127px 0;
    width: 13px;
    height: 13px;
}

.thread-icon.legacy.gender-female {
    background-position: -36px 0;
    width: 13px;
    height: 19px;
}

.thread-icon.legacy.gender-male {
    background-position: -50px 0;
    width: 16px;
    height: 18px;
}

.thread-icon.help {
    background-position: -67px 0;
    width: 16px;
    height: 15px;
}

.thread-icon.help.active {
    background-position: -84px 0;
}

.thread-icon.ip-logged {
    background-position: -381px 0;
    width: 19px;
    height: 19px;
}

.thread-icon.warned-post {
    background-position: -401px 0;
    width: 19px;
    height: 14px;
}

.thread-icon.button-mq {
    background-position: -421px 0;
    width: 62px;
    height: 26px;
}

.thread-icon.button-mq.off:hover {
    background-position: -162px 0;
}

.thread-icon.button-mq.on {
    background-position: -484px 0;
}

.thread-icon.button-mq.on:hover {
    background-position: -225px 0;
}

.thread-icon.button-edit {
    background-position: -547px 0;
    width: 61px;
    height: 26px;
}

.thread-icon.button-edit:hover {
    background-position: -101px 0;
}

.thread-icon.moderated-post {
    background-position: -609px 0;
    width: 17px;
    height: 22px;
}

.thread-icon.button-qr {
    background-position: -627px 0;
    width: 27px;
    height: 24px;
}

.thread-icon.button-quote {
    background-position: -655px 0;
    width: 72px;
    height: 26px;
}

.thread-icon.button-quote:hover {
    background-position: -288px 0;
}

.thread-icon.quote-end {
    background-position: -728px 0;
    width: 21px;
    height: 20px;
}

.thread-icon.quote-start {
    background-position: -750px 0;
    width: 20px;
    height: 20px;
}

.thread-icon.repguest {
    background-position: -792px 0;
    width: 20px;
    height: 19px;
}

.thread-icon.repguest.neg {
    background-position: -771px 0;
}

.thread-icon.repthumb-pos {
    background-position: -930px 0;
    width: 38px;
    height: 27px;
}

a.thread-icon.repthumb-pos:hover,
.thread-icon.repthumb-pos.active {
    background-position: -891px 0;
}

.thread-icon.repthumb-neg {
    background-position: -852px 0;
    width: 38px;
    height: 28px;
}

.thread-icon.repthumb-neg.active {
    background-position: -813px 0;
}

.thread-icon.report-post {
    background-position: -969px 0;
    width: 14px;
    height: 16px;
}

.thread-icon.report-post.urgent,
a:hover > .thread-icon.report-post {
    background-position: -984px 0;
}

.thread-icon.rep {
    background-position: -999px 0;
    width: 5px;
    height: 11px;
    background: #FFA600 !important;
    margin-right: 1px;
    border-radius: 2px;
}

.thread-icon.rep.highneg {
    background-position: -1008px 0;
}

.thread-icon.rep.highpos {
    background-position: -1017px 0;
    background-color: #FFCA66 !important;
}

.thread-icon.rep.maxneg {
    background-position: -1026px 0;
}

.thread-icon.rep.maxpos {
    background-position: -1035px 0;
    background-color: #F8CE46 !important;
}

.thread-icon.rep.neg {
    background-position: -1044px 0;
}

.thread-icon.rep.off {
    background-position: -1053px 0;
}

.thread-icon.rep.pos {
    background-position: -1062px 0;
}

.thread-icon.sig-expand {
    background-position: -1085px 0;
    width: 13px;
    height: 12px;
}

.thread-icon.sig-expand.fold {
    background-position: -1071px 0;
}

.thread-icon.status {
    background-position: -1099px 0;
    width: 8px;
    height: 8px;
}

.thread-icon.status.online {
    background-position: -1108px 0;
}

.thread-icon.badge {
    background-position: -1194px 0;
    height: 16px;
    width: 15px;
}

/* Post */

.post {
    color: #505050;
    display: inline-block;
    font-size: 13px;
    position: relative;
    width: 100%;
}

.post.threadpost,
.post.announcement {
    border-top: 5px solid #7B63DB;
}

.post.threadpost.imod_highlight {
    background-color: #ffffe3;
}

.post .post-user a,
.post .post-meta a,
.post .post-edited a {
    color: #7B63DB;
}

.post > .post-title,
.post .compact {
    display: none;
}

.post .post-meta,
.post > .post-content,
.post > .post-tags,
.post .post-edited,
.post .post-signature {
    margin-left: 136px;
}

.post .post-user,
.post > .post-content,
.post > .post-tags {
    padding-bottom: 20px;
}

.post > .post-footer {
    border-top: 1px solid #e0e0e0;
    clear: left;
    position: relative;
}

.post.threadpost:before,
.post.announcement:before,
.post > .post-footer:before {
    background: white;
    content: "\a0";
    height: 1px;
    position: absolute;
        top: -1px;
        left: 120px;
    width: 16px;
}

.post.threadpost:before,
.post.announcement:before {
    height: 5px;
    top: -5px;
}

.post > .post-anchor {
    display: block !important;
    pointer-events: none;
}

.lte10 .post > .post-anchor {
    display: none !important;
}

/* Post - user bit */

.post .post-user {
    color: #666;
    float: left;
    width: 120px;
}

.post .post-user > .avatar {
    float: left;
    margin-top: 5px;
}

.post .post-user > .avatar.default {
    border: 1px solid #dbdcd8;
}

.post .post-user > .username {
    clear: left;
	float: left;
    margin: 10px 0 3px;
    max-width: 120px;
    position: relative;
    word-wrap: break-word;
}

.post .post-user > .username > a {
    font-size: 1.308em;
    text-decoration: none;
}

.post .post-user > .username > .over {
    font-size: 1.231em;
}

.post .post-user > .username > .menu {
    padding: 5px 16px 10px;
    top: 25px;
}

.post .post-user > .username > .menu > ul > li {
    margin: 7px 0 0;
}

.post .post-user > .username > .menu > ul > li > a {
    color: #3bb0d7;
    white-space: nowrap;
}

.post .post-user > .username > .menu > ul > li > a:after {
    display: inline-block;
}

.post .post-user > .info-item {
    display: none;
}

.post .post-user > .info {
    clear: left;
    line-height: 16px;
}

.post .post-user > .info > li {
    padding: 3px 0;
}

.post .post-user .reputation > span,
.post .post-user .reputation > div {
    vertical-align: middle;
}

.page.guest .post .post-user > .info > .thread-starter {
    color: #999;
}

.post .post-user > .info > .sex {
    float: left;
    line-height: 18px;
    margin-right: 4px;
}

.post .post-user > .info > .sex > .thread-icon {
    vertical-align: middle;
}

.post .post-user > .info > .badges a {
    text-decoration: none;
}

.post .post-user > .info > .badges .thread-icon {
    vertical-align: top;
}

.post .post-user > .info > .online-status {
    font-style: italic;
    line-height: 18px;
}

.post .post-user > .info > .followers {
    color: #505050;
    font-size: 0.923em;
}

.post .post-user > .info > .online-status > span:before,
.post .post-user > .online-status > span:before,
.post .post-user > .info > .followers > span:before {
    content: "(";
}

.post .post-user > .info > .online-status > span:after,
.post .post-user > .online-status > span:after,
.post .post-user > .info > .followers > span:after {
    content: ")";
}

.post .post-user > .post-moderation {
    border-top: 1px solid #e0e0e0;
    margin-top: 12px;
    padding-top: 10px;
}

.post .post-user > .post-moderation > li > a,
.post .post-user > .post-moderation > li > span {
    display: block;
    line-height: 22px;
}

.post .post-user > .post-moderation .warning-link {
    color: #e35234;
}

.post .post-user > .post-moderation .reported-post {
    color: #e35234;
    font-weight: bold;
}

.post .post-user > .post-moderation .warning-link > .thread-icon,
.post .post-user > .post-moderation .reported-post .thread-icon {
    margin: -1px 3px 0 0;
    vertical-align: middle;
}

/* Post - meta bit */

.post .post-meta {
    border-bottom: 1px solid #e0e0e0;
    text-align: right;
}

.post .post-meta > ul {
    display: inline-block;
}

.post .post-meta > ul > li {
    float: right;
    line-height: 36px;
    margin-left: 12px;
}

.post .post-meta > .meta-moderation > li {
    margin-left: 8px;
}

.post .post-meta > ul > .post-number {
    font-size: 1.231em;
}

.post .post-meta > ul > .post-number > a {
    display: inline-block;
    height: 20px;
    line-height: 22px;
    margin: 7px -4px 0 -6px;
    vertical-align: text-bottom;
}

.post.new .post-meta > ul > .post-number > a {
    background-color: #d4eff8;
}

.post .post-meta > ul > .post-number > a:before,
.post .post-meta > ul > .post-number > a:after {
    content: "\a0";
    display: inline-block;
    height: 20px;
    position: relative;
    vertical-align: top;
}

.post.new .post-meta > ul > .post-number > a:before,
.post.new .post-meta > ul > .post-number > a:after {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/base/thread-icons-sprite-v3.png") no-repeat -1140px 0 white;
}

.post.new .post-meta > ul > .post-number > a:after {
    background-position: -1152px 0;
}

.post .post-meta > ul > .post-number > a:before {
    left: 6px;
    margin-left: -6px;
    width: 12px;
}

.post .post-meta > ul > .post-number > a:after {
    margin-right: -4px;
    right: 4px;
    width: 10px;
}

.post .post-meta > ul > .post-number > a > span {
    position: relative;
    z-index: 1;
}

.post .post-meta > ul > .date {
    color: #999;
    cursor: default;
    position: relative;
}

.post .post-meta > ul > .date .tooltip {
    line-height: 22px;
    white-space: nowrap;
}

.post .post-meta > ul > .link {
    margin-left: 5px;
}

.post .post-meta > ul > .link:before {
    color: #3aa6d0;
    content: "|";
}

.post .post-meta > ul > .link.last:before {
    content: "";
    content: none;
}

.post .report-post > a {
    text-decoration: none;
}

.post .report-post > a:hover {
    color: #e35234;
}

.post .report-post > a > .thread-icon {
    vertical-align: top;
}

.post .post-meta > ul > .report-post > a > .thread-icon {
    margin-top: 10px;
}

.post .post-meta .postimod,
.post .post-meta .ip-logged {
    margin: 0;
    vertical-align: middle;
}


/* Post - content bit */

.post > .post-content {
    line-height: 1.357;
    padding-top: 10px;
    word-wrap: break-word;
}

.post.deleted > .post-content,
.post.ignored > .post-content {
    color: #666;
    font-style: italic;
}

.post > .post-content fieldset {
    border: 1px dotted #ccc;
}

.post > .post-content .legend {
    background-color: white;
    padding: 0 5px;
}

.post > .post-content img,
.post .post-signature img {
    max-width: 100%;
}

.postcontent,
.postcontent .fieldset,
.postcontent .fieldset td,
.postcontent .fieldset p,
.postcontent .fieldset li,
.postcontent .fieldset legend,
.post-rating-label {
    font-size: 1.083em;
}

/* Post Origin */
.post .post-origin {
    font-size: 12px;
    padding-top: 20px;
}

/* Post - tags bit */
.post > .post-tags {
    border-top: 1px solid #e0e0e0;
    line-height: 18px;
    padding-top: 10px;
}

.post > .post-tags > span,
.post > .post-tags > ul {
    display: inline;
}

.post > .post-tags > ul > li {
    display: inline-block;
    vertical-align: top;
}

.post > .post-tags > span {
    color: #999;
}

.post > .post-tags > ul > li:after {
    content: ",\a0";
}

.post > .post-tags > ul > li:last-child:after {
    content: "";
    content: none;
}

.post > .post-tags > ul > li > a {
    float: left;
}

/* Post - signature bit */

.post .post-signature,
.post .post-signature.expandable.expanded {
    border-bottom: 1px solid #e0e0e0;
    border-top: 1px solid #68b1d9;
    margin-top: -1px;
    padding: 12px 0;
    position: relative;
}

.post .post-signature.expandable {
    border: 0;
    margin-top: 0;
    padding: 0;
}

.post .post-signature > .signature-toggle {
    position: absolute;
        top: -18px;
        right: 0;
}

.post .post-signature > .signature-toggle.loading:before {
    content: "Loading\2026";
    color: #999;
    cursor: default;
    font-size: 0.846em;
    font-style: italic;
    position: absolute;
        top: 0;
        left: -55px;
}

.post .post-signature.expandable > .signature {
    display: none;
}

/* Post - edited bit */

.post .post-edited {
    font-size: 0.846em;
    font-style: italic;
    margin-top: 10px;
}

/* Post - control bit */

.post .post-control {
    overflow: hidden;
}

.post .post-control > .helpful-answers {
    color: #b7b8b8;
    float: left;
    height: 32px;
    margin: 7px 0;
}

.page.member .post .post-control > .helpful-answers .label {
    display: none;
}

.post .post-control > .helpful-answers > .post-reputation,
.post .post-control > .helpful-answers .repguest {
    display: inline-block;
    vertical-align: middle;
}

.post .post-control > .helpful-answers .score {
    color: #679a0d;
    cursor: default;
    display: inline-block;
    font-size: 0.923em;
    margin-left: -6px;
}

.post .post-control > .helpful-answers .score.zero {
    color: #999;
}

.page.guest .post .post-control > .helpful-answers .score {
    margin: 0;
}

.post .post-control > .post-buttons {
    float: right;
    margin: 10px 0;
}

.post .post-control > .post-buttons > li {
    display: inline-block;
    margin-left: 3px;
    vertical-align: top;
}

.post .post-control > .post-buttons > li > a {
    display: block;
}

.threadpost .postanchor {
    display: block;
    pointer-events: none;
}

.threadpost .postanchor:before {
    display: block;
    content: "\a0";
    height: 44px;
    margin: -44px 0 0;
    visibility: hidden;
}

#reply_pm .postcontent td {
    vertical-align: top;
    padding: 10px;
}


@media (-webkit-min-device-pixel-ratio: 1.25),
       (min-resolution: 120dpi) {

.thread-icon {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/base/thread-icons-sprite-v3@2x.png") no-repeat;
    background-size: 1286px 28px;
    }
}


/* ## start-device-block mobile ##*/

@media screen and (max-width: 480px),
       (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 800px),
       (min-resolution: 120dpi) and (max-width: 800px) {


    .threadpost .postanchor:before {
        content: none;
        height: 0;
        margin: 0;
    }

    .post.threadpost,
    .post.announcement {
        border-top-color: #2483be;
    }

    .post.threadpost.hasmodbit {
        padding-top: 30px;
    }

    .post .post-meta a,
    .post .post-edited a {
        color: #21669c;
    }

    .post .standard {
        display: none;
    }

    .post .compact {
        display: inline;
    }

    .report-post div {
        vertical-align: top;
        height: 15px;
    }

    .post.threadpost:before,
    .post.announcement:before,
    .post > .post-footer:before {
        content: "";
        content: none;
    }

    .post .post-meta,
    .post > .post-content,
    .post > .post-tags,
    .post .post-edited,
    .post .post-signature {
        margin-left: 0;
    }

    .post > .post-header {
        background-color: #eeeee4;
        display: flex;
        justify-content: space-between;
    }

    .post.thread-starter > .post-header {
        background-color: #e9e9dd;
    }

    /* Post - user bit */

    .post .post-user {
        color: #505050;
        flex-grow: 1;
        padding: 0;
        vertical-align: top;
        width: auto;
    }

    .post .post-user > .avatar {
        margin: 0 6px 0 0;
    }

    .post .post-user > .avatar > img {
        max-height: 50px;
        max-width: 50px;
    }

    .post .post-user > .username {
        display: inline-block;
        float: none;
        margin: 3px 4px 0 0;
        max-width: none;
        vertical-align: top;
    }

    .post .post-user > .username > a {
        /* Note: if you change this font size you'll need to override .over */
        font-size: 1.231em;
    }

    .post .post-user > .original-user,
    .post .post-user > .sex,
    .post .post-user > .online-status,
    .post .post-user > .badges,
    .post .post-user > .reputation,
    .post .post-user > .rank.allow-compact {
        display: inline-block;
        height: 22px;
        line-height: 22px;
        margin-top: 2px;
        vertical-align: top;
    }

    .post .post-user > .badges.standard,
    .post .post-user > .reputation.standard {
        display: none;
    }

    .post .post-user > .sex > .thread-icon,
    .post .post-user > .sex > .gender-male.legacy {
        margin-top: 2px;
        vertical-align: top;
    }

    .post .post-user > .sex > .gender-male {
        margin-top: 4px;
    }

    .post .post-user > .online-status {
        color: #999;
        font-style: italic;
        margin-left: 4px;
    }

    .post .post-user .online-status > span:before,
    .post .post-user .online-status > span:after {
        content: "";
        content: none;
    }

    .post .post-user > .badges {
        margin-right: 4px;
    }

    .post .post-user > .badges a {
        position: relative;
        text-decoration: none;
        top: 1px;
    }

    .post .post-user > .badges .thread-icon {
        margin-top: 2px;
        vertical-align: top;
    }

    .post .post-user > .post-moderation {
        border: 0;
        line-height: 30px;
        margin: 0;
        padding: 0;
        position: absolute;
            top: 0;
            right: 48px;
        z-index: 1;
    }

    .post .post-user > .post-moderation > li {
        display: inline-block;
        margin-left: 5px;
        vertical-align: top;
    }

    .post .post-user > .post-moderation > li:before {
        color: #3aa6d0;
        content: "|";
    }

    .post .post-user > .post-moderation > li:first-child:before {
        content: "";
        content: none;
    }

    .post .post-user > .post-moderation > li > a,
    .post .post-user > .post-moderation > li > span {
        display: inline;
        line-height: 30px;
    }

    .post .post-user > .post-moderation .reported-post {
        display: none;
    }

    .post .post-user > .post-moderation > li > a.warning-link {
        line-height: 0;
        font-size: 0;
        color: transparent;
    }

    /* Post - meta bit */

    .post .post-meta {
        border: 0;
        padding: 3px 3px 0 0;
        flex-shrink: 0;
    }

    .post.hasmodbit .post-meta {
        top: 33px;
    }

    .post .post-meta > ul > li {
        margin-left: 0;
    }

    .post .post-meta > .meta-info > li {
        line-height: 18px;
        text-align: right;
    }

    .post .post-meta > ul > .follow {
        display: block;
        float: none;
        margin-bottom: 4px;
    }

    .post .post-meta > ul > .post-number {
        font-size: 0.923em;
        margin-left: 3px;
    }

    .post .post-meta > ul > .post-number > a,
    .post .post-meta > ul > .post-number > a:before {
        display: inline;
        margin: 0;
        vertical-align: baseline;
    }

    .post.new .post-meta > ul > .post-number > a,
    .post.new .post-meta > ul > .post-number > a:before {
        background: none;
    }

    .post .post-meta > ul > .post-number > a {
        color: #3aa6d0;
        font-family: Arial, Geneva, Lucida, sans-serif !important;
        font-weight: bold;
        line-height: inherit;
    }

    .post .post-meta > ul > .post-number > a:before {
        content: "#";
        position: static;
    }

    .post .post-meta > ul > .post-number > a:after {
        content: "";
        content: none;
    }

    .post .post-meta > ul > .date {
        color: #505050;
        font-size: 0.846em;
    }

    .post .post-meta > .meta-moderation,
    .post .post-meta > .meta-moderation-links {
        position: absolute;
            top: 0;
        width: 300px; /* Bit of a dirty hack, used to prevent wrapping issues */
    }

    .post .post-meta > .meta-moderation {
        right: 0;
    }

    .post .post-meta > .meta-moderation-links {
        right: 21px;
        width: 300px;
        z-index: 1;
    }

    .post .post-meta > .meta-moderation > li,
    .post .post-meta > .meta-moderation-links > li {
        line-height: 30px;
    }

    /* Post - title bit */

    .post > .post-title {
        border-bottom: 1px dotted #ccc;
        display: block;
        padding: 10px 0;
    }

    .post > .post-title > .report-post {
        float: right;
    }

    .post > .post-title > .report-post a {
        color: #3aa6d0;
    }

    .post > .post-title > .report-post > a:hover {
        color: #e35234;
    }

    /* Post - content bit */

    .post > .post-content {
        padding: 10px 0;
    }

    /* Improve display of tables on mobile */
    .post > .post-content table {
          overflow-x:auto;
          display:block;
    }

    .thread-icon.warned-post {
        margin-top:-8px !important;
        margin-left:5px !important;
    }
}
/* ## end-device-block ## */

#page_usernote .post.threadpost {
    border-color: #7B63DB;
}