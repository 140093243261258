/**
* Notifications related CSS for the notifcations icon and popup.
* @author A TSR Developer
* @device all
*/

/* Notification item */

.notification {
    border-bottom: 1px dotted #dbdcdd;
    min-height: 40px;
}

.notification > a {
    display: block;
    padding: 3px 4px 3px 44px;
    position: relative;
}

.notification > a:hover {
    text-decoration: none;
}

.notification > a:hover > .title {
    text-decoration: underline;
}

.notification > a > span {
    display: block;
    line-height: 1.417;
    overflow: hidden;
    text-overflow: ellipsis;
}

.notification > a > .title {
    color: #FFFFFF;
    font-weight: bold;
}

.notification > a > .info {
    color: #FFFFFF;
}

#page_notifications .notification > a > .title {
    color: #7B63DB;
    font-weight: bold;
}

#page_notifications .notification-list .notification > a:hover > .title {
    color: #6652B8;
}

.notification > a > .info {
    color: #272727;
}

li.inbox li.notification > a > img {
    float:left;
    width:35px;
    height:35px;
    margin-right:5px;
}

li.inbox li.notification > a {
    padding-left: 4px !important;
}

/* Notification item - icons */

.notification > a:before {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/base/sprite-notifications-alternate.png") no-repeat 0 0;
    content: "\a0";
    height: 32px;
    position: absolute;
        top: 4px;
        left: 5px;
    width: 31px;
    background-color: #7B63DB;
}

.notification.pm.new > a:before {
    background-position: -1px 9px;
}

.notification.friend > a:before {
    background-position: -1px -23px;
}

.notification.socgroupreq > a:before {
    background-position: -1px -55px;
}

.notification.pc > a:before {
    background-position: -1px -89px;
}

.notification.quote > a:before,
.notification.quote_legacy > a:before {
    background-position: -1px -119px;
}

.notification.pprm > a:before {
    background-position: -1px -154px;
}

.notification.repreceived > a:before,
.notification.repreceived_legacy > a:before {
    background-position: -1px -185px;
}

.notification.socgroupinv > a:before {
    background-position: -1 -216px;
}

.notification.vm > a:before {
    background-position: -1px -248px;
}

.notification.threadwatched > a:before {
    background-position: -1px -280px;
}

.notification.follow > a:before {
    background-position: -2px -313px;
}

.notification.alert > a:before {
    background-position: -1 -344px;
}

.notification.pm > a:before {
    background-position: -1 -377px;
}

.notification.connect > a:before {
    background-position: -2px -411px;
}

.notification.threaddeleted > a:before,
.notification.postdeleted > a:before,
.notification.threadreview > a:before,
.notification.postreview > a:before {
    background-position: -2px -441px;
}

.notification.postapproved > a:before {
    background-position: -2px -473px;
}

.notification.threadapproved > a:before {
    background-position: -2px -503px;
}

.notification.badge > a:before {
    background-position: -2px -537px;
}

.notification.mention > a:before {
    background-position: -2px -569px;
}

.notification.tagpost > a:before {
    background-position: -2px -604px;
}

.notification.goldmember_add > a:before,
.notification.goldmember_remove > a:before {
    background-position: -2px -635px;
}

.notification.quote_anon > a:before {
    background-position: -1px -664px;
}

.notification.unanswered > a:before {
    background-position: -1px -696px;
}

.notification.dpc_continue > a:before {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/base/notify-dpc.svg") no-repeat 0 0;
    background-position: center;
    background-color: #7B63DB;
}

.notification.threadapproved .dateline,
.notification.postapproved .dateline,
.notification.threaddeleted .dateline,
.notification.postdeleted .dateline,
.notification.threadreview .dateline,
.notification.postreview .dateline {
	display:none;
}

/* Notification item - with image */

.notification.image > a {
    padding-left: 0;
}

.notification.image > a:before {
    content: none;
}

.notification.image > a > img {
    background-color: white;
    border: 1px solid #c3c5c6;
    float: left;
    height: 32px;
    margin: 0 7px 0 5px;
    width: 32px;
}

/* Retina images */

@media (-webkit-min-device-pixel-ratio: 1.25),
       (min-resolution: 120dpi) {
    .notification > a:before {
        background-image: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/base/sprite-notifications-alternate@2x.png");
        background-size: 32px 735px;
    }

    .notification.pm.new > a:before {
        background-position: -1 9px;
    }
    
    .notification.friend > a:before {
        background-position: -1 -24px;
    }
    
    .notification.socgroupreq > a:before {
        background-position: -1px -56px;
    }
    
    .notification.pc > a:before {
        background-position: 0 -91px;
    }
    
    .notification.quote > a:before,
    .notification.quote_legacy > a:before {
        background-position: -1px -122px;
    }
    
    .notification.pprm > a:before {
        background-position: 0 -157px;
    }
    
    .notification.repreceived > a:before,
    .notification.repreceived_legacy > a:before {
        background-position: 0 -190px;
    }
    
    .notification.socgroupinv > a:before {
        background-position: 0 -221px;
    }
    
    .notification.vm > a:before {
        background-position: -1px -253px;
    }
    
    .notification.threadwatched > a:before {
        background-position: -1px -287px;
    }
    
    .notification.follow > a:before {
        background-position: -1px -320px;
    }
    
    .notification.alert > a:before {
        background-position: -1px -352px;
    }
    
    .notification.pm > a:before {
        background-position: 0 -386px;
    }
    
    .notification.connect > a:before {
        background-position: -1px -420px;
    }
    
    .notification.threaddeleted > a:before,
    .notification.postdeleted > a:before,
    .notification.threadreview > a:before,
    .notification.postreview > a:before {
        background-position: -1px -450px;
    }
    
    .notification.postapproved > a:before {
        background-position: -1px -483px;
    }
    
    .notification.threadapproved > a:before {
        background-position: -1px -516px;
    }
    
    .notification.badge > a:before {
        background-position: -1px -549px;
    }
    
    .notification.mention > a:before {
        background-position: -1px -581px;
    }
    
    .notification.tagpost > a:before {
        background-position: -2px -617px;
    }
    
    .notification.goldmember_add > a:before,
    .notification.goldmember_remove > a:before {
        background-position: -2px -649px;
    }
    
    .notification.quote_anon > a:before {
        background-position: -1px -679px;
    }
    
    .notification.unanswered > a:before {
        background-position: -1px -711px;
    }
    
}
