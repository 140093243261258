/**
* Small CSS file containing rules for "latest content" and "featured story". Homepage only (and possibly CMS).
* @author A TSR Developer
* @device all|desktop|mobile|mixed
*/

.featured-story {
    color: #808080;
    line-height: 1.333em;
}

.latest-content {
    border-bottom: 1px dotted #dbdcdd;
    color: #666;
    line-height: 1.231;
    overflow: hidden;
    padding: 8px 0;
    position: relative;
}

/* Mobile styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    .latest-content {
        padding: 9px 0;
    }
}


.latest-content:first-child {
    border-top: 0;
}

.featured-story .cms-button,
.latest-content .cms-button {
    display: none;
    margin: 0;
    position: absolute;
    z-index: 1;
}

.latest-content .cms-button {
    left: 0;
    top: 0;
}

.featured-story .headline-image {
    display: block;
    position: relative;
}

.latest-content .headline-image {
    border: 1px solid #7B63DB;
    float: left;
}

/* Mobile styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    .latest-content .headline-image {
        border: none;
    }
}

.featured-story .headline-image img {
    display: block;
    height: 100px;
    width: 130px;
}

.latest-content .headline-image img {
    display: block;
    height: 70px;
    width: 90px;
}

.latest-content .headline-image + .content {
    margin-left: 98px;
}

.featured-story h3,
.latest-content h3 {
    font-size: 1em;
    font-weight: normal;
}

.featured-story h3 {
    margin: 12px 0 0;
}

.latest-content h3 {
    margin: 0;
}

/* Mobile styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    .latest-content h3 {
        line-height: 1.231;
    }
}

.featured-story h3 a,
.latest-content h3 a {
    color: #7B63DB;
}

.featured-story h3 a {
    text-decoration: underline;
}

.featured-story p,
.latest-content p {
    margin: 4px 0;
}

/* Mobile styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    .latest-content p {
        font-size: 16px;
    }
}
