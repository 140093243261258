/**
* Icons and inline/block elements pertaining to forum items.
* @author A TSR Developer
* @device all
*/

/* Forum header */

.forum-header .interact,
.forum-header .interact .forum-icon {
    vertical-align: top;
}

.forum-header .interact {
    color: white !important;
    font-size: 0.667em;
    margin-left: 12px;
    margin-top: 5px;
}

.forum-header .interact .forum-icon {
    display: inline-block;
    height: 18px;
    padding-right: 2px;
    width: 19px;
}

.forum-header .interact .forum-icon.watch,
.forum-header .interact:hover .forum-icon.watch-light {
    background-position: 0 -22px;
}

.forum-header .interact .forum-icon.watch-light,
.forum-header .interact:hover .forum-icon.watch {
    background-position: 0 -43px;
}

.forum-header-top.has-sponsor {
    min-height: 81px;
    padding-right: 150px;
}

.forum-header .forum-sponsor {
    position: absolute;
        top: 0;
        right: 0;
}

.forum-header .forum-sponsor .advert-block {
    outline: none;
}

@media screen and (max-width: 480px),
       (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px),
       (min-resolution: 120dpi) and (max-width: 480px) {
    .forum-header.has-sponsor {
        min-height: 0;
        padding-right: 0;
    }

    body #page_forumdisplay .forum-header.has-sponsor {
        margin-bottom: 15px;
    }

    .forum-header .forum-sponsor {
        position: static;
    }

    .forum-header .forum-sponsor .advert-block {
        margin: 0;
    }

    #page_forumdisplay .forum-header.has-sponsor ~ .forum-category {
        margin-top: 10px;
    }
}

#page-section-top.page-section.advert-billboard {
    min-width: 980px;
    padding: 0;
    position: relative;
    text-align: center;
    z-index: 2;
    display:block;
}

#page-section-top.page-section.advert-billboard .advert-block {
    display: inline-block;
    margin: 5px 5px 0;
}

#page-section-top.page-section.advert-billboard .advert-block:empty {
    margin: 0;
    outline: none;
}

#page-section-top.advert-billboard + #page-columns .column {
    margin-top: 16px;
}

/* Forum category */

#forum-bits {
    position: relative;
}

#forum-bits:after {
    background-color: white;
    content: "\a0";
    height: 2px;
    position: absolute;
        bottom: 0;
        left: 0;
    width: 100%;
}

.forum-category {
    border-bottom: 2px solid #7B63DB;
    border-spacing: 0;
    margin: 17px 0 0;
    padding-bottom: 7px;
    table-layout: fixed;
    width: 100%;
}

.forum-category tbody {
	display: table-row-group;
}

.forum-category td,
.forum-category th {
    padding: 0 10px;
}

.forum-category th {
    line-height: 32px;
}

.forum-category th.title {
    font-size: 1.25em;
    width: 60%;
}

.forum-category th.count {
    width: 70px;
}

.forum-category .forum td {
    border-bottom: 1px dotted #ccc;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    color: #505050;
    padding: 10px 8px 8px;
    vertical-align: top;
}

.forum-category .forum td a {
    color: #7B63DB !important;
}

.forum-category .subforums a {
    padding-left:10px;
}

.forum-category .forum td a:hover {
    color: #6652B8 !important;
}

.forum-category .forum .info {
    line-height: 1.333em;
    padding-top: 4px;
    padding-left: 4px;
}

.forum-category .arrowscont {
	display: none;
}

.forum .subforums ol li,
.forum-tool,
.status-icons,
.forum-icon {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/statusicons/sprite-forum.png") no-repeat;
}

.status-icons-new,
.status-icons-old {
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 15px;
}

.status-icons-new {
    background-position: 0 -220px;
}

.status-icons-old {
    background-position: 0 -242px;
}

.mention.status-icons-new {
    background-position: 1px -439px;
}

.mention.status-icons-old {
    background-position: 1px -461px;
}

.tagpost.status-icons-new {
    background-position: 1px -484px;
}

.tagpost.status-icons-old {
    background-position: 1px -506px;
}

.forum .title {
    line-height: 1.833em;
}

.forum .status-icons {
    background-position: 0 -154px;
    display: inline-block;
    height: 18px;
    position: relative;
        top: 5px;
    width: 26px;
}

.forum.old .status-icons,
.forum.old_lock .status-icons {
    background-position: 0 -176px;
}

.forum.link .status-icons {
    background-position: 0 -198px;
}

.forum-category .forum .description {
    padding: 0;
}

.forum-category .forum .subforums {
    margin: 2px 0 -3px;
}

.forum-category .forum .subforums h4 {
    color: #333;
    display: inline;
    font-weight: normal;
}

.forum-category .forum .subforums ol {
    display: inline;
    line-height: 1.5em;
}

.forum-category .forum .subforums ol li {
    background-position: 0 -218px;
    display: inline-block;
    margin-left: 2px;
    padding-left: 18px;
    white-space: nowrap;
}

.forum-category .forum .subforums ol li.old {
    background-position: 0 -240px;
}

.forum-category .forum .subforums ol li.link {
    background-position: 0 -262px;
}

.forum-category .forum .last-post .by {
    display: block;
    padding-top: 3px;
}

/* Thread list */

.thread-list {
    border-bottom: 1px dotted #ccc;
    border-spacing: 0;
    margin: 15px 0 20px;
    width: 100%;
}

.thread-list td {
    padding: 8px 10px 4px;
}

.thread-list td.title {
    padding-left: 6px;
}

.thread-list th {
    border: solid #7B63DB;
    border-width: 2px 0 1px;
    color: #272727;
    padding: 4px 5px;
}

.thread-list th.title {
    color: #7B63DB;
    font-size: 1.167em;
}

.thread-list th.last-post {
    padding-left: 10px;
}

.thread.unread {
    font-weight: normal;
}

.thread-row-added {
    background-color: #fff;
    animation: threadhighlight 900ms 1;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
}
@keyframes threadhighlight {
    0% {
        background-color: rgba(255,162,0,0.5);
    }
    100% {
        background-color: rgba(255,162,0,0);
    }
}

.thread .date .date_link {
	display: none;
}

.thread .mobile_date_text {
	display: none;
}

#page_forumdisplay .thread .last-post .mobile-posts,
#page_forumdisplay .thread .icon .last_poster {
	display: none;
}

/* Thread */

body .thread td a {
    color: #7B63DB;
}

body .thread td a:hover {
    color: #6652B8;
}

.thread td {
    border-top: 1px dotted #ccc;
    color: #272727;
    line-height: 1.231em;
}

.thread:first-child td {
    border-top: none;
}

.thread .title {
    font-size: 1.083em;
}

.thread .title .byline {
    font-size: 0.923em;
}

.thread .title .action {
    font-size: 0.923em;
}

.thread .title .titleline,
.thread .last-post .byline,
.thread .replies {
    display: block;
}

.thread .count {
    text-transform: lowercase;
    width: 100px;
}

.thread .title .thread-minis {
    display: inline-block;
    vertical-align: middle;
}

.thread .title .thread-minis .threadbit-icon:first-child {
    margin-left: 0;
}

.thread .avatar {
    float: left;
    margin: 0 8px 8px 0;
}

.thread .avatar ~ span {
    display: block;
    margin-left: 37px;
}

.thread .avatar img {
    border: 1px solid #c3c5c6;
    height: 30px;
    width: 30px;
}

.thread .title .action {
    display: block;
    padding-top: 1px;
}

.thread .title .tooltip .action {
    padding-top: 8px;
}

.thread .title .action img {
    padding-right: 4px;
    vertical-align: middle;
}

.thread .title .label {
    display: inline-block;
    font-size: 0.916em;
    margin: 0 5px;
}

.thread .title .label:first-child {
    margin-left: 0;
}

.thread.read a {
    color: #7B63DB;
}

.thread.sticky {
    background-color: #fffaf5;
}

body .thread.sticky td a,
body .thread.sticky td .prefix {
    color: #FFA600;
    font-weight: 700;
}

.thread.read.sticky a {
    color: #FFA600;
    font-weight: 700;
}

.thread .selectedthread {
    background-color: #ffffe3;
}

/* Thread list - sprites */

.thread .icon {
    background-image: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/statusicons/sprite-thread.png");
    background-position: 5px center;
    background-repeat: no-repeat;
    padding-left: 0;
    padding-right: 0;
    width: 28px;
}

.thread.posted .icon {
    background-position: -23px center;
}

.thread.read .icon {
    background-position: -51px center;
}

.thread.posted.read .icon {
    background-position: -79px center;
}

.thread.redirect .icon {
    background-position: -107px center;
}

.thread.sticky .icon {
    background-position: -135px center !important;
}

.thread.sticky.read .icon {
    background-position: -159px center !important;
}

.thread.closed .icon {
    background-position: -189px center !important;
}

.thread.poll .icon {
    background-position: -221px center;
}

.thread.poll.read .icon {
    background-position: -247px center;
}

.thread.poll.closed .icon {
    background-position: -274px center !important;
}

.thread.poll.posted .icon {
    background-position: -302px center;
}

.thread.poll.posted.read .icon {
    background-position: -329px center;
}

.thread.mention .icon {
    background-position: -392px center;
}

.thread.mention.read .icon {
    background-position: -418px center;
}

.thread.tagpost .icon {
    background-position: -447px center;
}

.thread.tagpost.read .icon {
    background-position: -472px center;
}

.threadbit-icon {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/statusicons/sprite-threadbit.png") no-repeat;
    display: inline-block;
    height: 13px;
}

.threadbit-icon.tag {
    background-position: 0 0;
    width: 13px;
}

.threadbit-icon.expires {
    background-position: -20px 0 !important;
    width: 13px;
}

.threadbit-icon.moderated {
    background-position: -40px 0 !important;
    width: 11px;
}

.threadbit-icon.trash {
    background-position: -58px 0 !important;
    width: 11px;
}

.threadbit-icon.subscribed {
    background-position: 0 -20px !important;
    width: 12px;
}

.threadbit-icon.paperclip {
    background-position: -20px -20px !important;
    width: 7px;
}

.threadbit-icon.firstnew {
    background-position: 0 -40px !important;
    width: 9px;
}

.threadbit-icon.firstnew.sticky {
    background-position: -40px -40px !important;
}

.threadbit-icon.lastpost {
    background-position: -20px -40px !important;
    height: 9px;
    width: 7px;
}

.threadbit-icon.lastpost.sticky {
    background-position: -60px -40px !important;
}

.forum-tool {
    display: inline-block;
    padding-left: 31px;
    height: 22px;
    line-height: 22px;
}

.forum-tool.mark-read {
    background-position: 0 0;
}

.forum-tool.watch {
    background-position: 4px -21px;
}

.forum-tool.watch-light {
    background-position: 4px -43px;
}

.forum-tool.add-to {
    background-position: 3px -65px;
}

.forum-tool.groups {
    background-position: 0 -86px;
}

.forum-tool.poll {
    background-position: 7px -109px;
}

.forum-tool.first-unread {
    background-position: 5px -370px;
}

/* Rep icons */

.rep-thumb {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/reputationicons/sprite-thumb.png") no-repeat;
    display: inline-block;
    min-width: 38px;
    height: 28px;
}

.rep-thumb.pos,
.rep-thumb.personal {
    color: #7ba524;
}

.rep-thumb.neg {
    background-position: 0 -28px;
    color: #d40514;
}

.rep-thumb.personal {
    background-position: 0 -56px;
    height: 32px;
}

.rep-thumb .amount {
    cursor: default;
    display: block;
    margin: 12px 0 0 28px;
}

.rep-thumb.active .amount {
    font-weight: bold;
}

/* Thread icons */

.old-style-button {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/base/old-style-sprite.png") no-repeat;
}

.old-style-button-quote {
    background-position: -58px 0;
    display: inline-block;
    height: 15px;
    margin-right: 5px;
    position: relative;
        top: -1px;
    vertical-align: middle;
    width: 17px;
}

.thread-icons-v2 {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/base/thread-icons-sprite-v3.png") no-repeat;
}

.thread-icons-v2-close-message-cross{ background-position: 0 0; width: 17px; height: 17px; }
.thread-icons-v2-close{ background-position: -18px 0; width: 17px; height: 17px; }
.thread-icons-v2-gender-female{ background-position: -36px 0; width: 13px; height: 19px; }
.thread-icons-v2-gender-male{ background-position: -50px 0; width: 16px; height: 18px; }
.thread-icons-v2-help{ background-position: -67px 0; width: 16px; height: 15px; }
.thread-icons-v2-helped{ background-position: -84px 0; width: 16px; height: 15px; }
.thread-icons-v2-mod-alert{ background-position: -361px 0; width: 19px; height: 14px; }
.thread-icons-v2-mod-iplogged{ background-position: -381px 0; width: 19px; height: 19px; }
.thread-icons-v2-mod-warn{ background-position: -401px 0; width: 19px; height: 14px; }
.thread-icons-v2-multiquote_off{ background-position: -421px 0; width: 62px; height: 26px; }
.thread-icons-v2-multiquote_off:hover{ background-position: -162px 0; width: 62px; height: 26px; }
.thread-icons-v2-multiquote_on{ background-position: -484px 0; width: 62px; height: 26px; }
.thread-icons-v2-multiquote_on:hover{ background-position: -225px 0; width: 62px; height: 26px; }
.thread-icons-v2-old_edit{ background-position: -547px 0; width: 61px; height: 26px; }
.thread-icons-v2-old_edit:hover{ background-position: -101px 0; width: 61px; height: 26px; }
.thread-icons-v2-old_moderated{ background-position: -609px 0; width: 17px; height: 22px; }
.thread-icons-v2-old_post{ background-position: -627px 0; width: 27px; height: 24px; }
.thread-icons-v2-old_quote{ background-position: -655px 0; width: 72px; height: 26px; }
.thread-icons-v2-old_quote:hover{ background-position: -288px 0; width: 72px; height: 26px; }
.thread-icons-v2-quote-end{ background-position: -728px 0; width: 21px; height: 20px; }
.thread-icons-v2-quote-start{ background-position: -750px 0; width: 20px; height: 20px; }
.thread-icons-v2-rep-icon-guest-neg{ background-position: -771px 0; width: 20px; height: 18px; }
.thread-icons-v2-rep-icon-guest-pos{ background-position: -792px 0; width: 20px; height: 19px; }
.thread-icons-v2-rep-icon-neg-active{ background-position: -813px 0; width: 38px; height: 28px; }
.thread-icons-v2-rep-icon-neg{ background-position: -852px 0; width: 38px; height: 28px; }
.thread-icons-v2-rep-icon-pos-active{ background-position: -891px 0; width: 38px; height: 27px; }
.thread-icons-v2-rep-icon-pos{ background-position: -930px 0; width: 38px; height: 27px; }
.thread-icons-v2-report_normal{ background-position: -969px 0; width: 14px; height: 16px; }
.thread-icons-v2-report_over{ background-position: -984px 0; width: 14px; height: 16px; }
.thread-icons-v2-reputation_balance{ background-position: -999px 0; width: 8px; height: 11px; }
.thread-icons-v2-reputation_highneg{ background-position: -1008px 0; width: 8px; height: 11px; }
.thread-icons-v2-reputation_highpos{ background-position: -1017px 0; width: 8px; height: 11px; }
.thread-icons-v2-reputation_maxneg{ background-position: -1026px 0; width: 8px; height: 11px; }
.thread-icons-v2-reputation_maxpos{ background-position: -1035px 0; width: 8px; height: 11px; }
.thread-icons-v2-reputation_neg{ background-position: -1044px 0; width: 8px; height: 11px; }
.thread-icons-v2-reputation_off{ background-position: -1053px 0; width: 8px; height: 11px; }
.thread-icons-v2-reputation_pos{ background-position: -1062px 0; width: 8px; height: 11px; }
.thread-icons-v2-sig-contract{ background-position: -1071px 0; width: 13px; height: 12px; }
.thread-icons-v2-sig-expand{ background-position: -1085px 0; width: 13px; height: 12px; }
.thread-icons-v2-status-offline{ background-position: -1099px 0; width: 8px; height: 8px; }
.thread-icons-v2-status-online{ background-position: -1108px 0; width: 8px; height: 8px; }

.thread-icons-v2-button-edit,
.thread-icons-v2-button-reply {
    display: inline-block;
    margin-right: 5px;
    position: relative;
        top: -1px;
    vertical-align: middle;
}

.thread-icons-v2-quote-end{ position:relative; top:5px; margin-left:-5px; float:right }
.thread-icons-v2-quote-start{ position:relative; top:3px; margin-right:-7px; float:left }

.report_post_compact {
	display: none;
}

@media (-webkit-min-device-pixel-ratio: 1.25),
       (min-resolution: 120dpi) {
    .thread-icons-v2-quote-start,
    .thread-icons-v2-quote-end {
        background-image: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/base/thread-icons-sprite-v2-quote@2x.png");
        background-size: 41px 20px;
    }

    .thread-icons-v2-quote-start {
        background-position: 0 0;
    }

    .thread-icons-v2-quote-end {
        background-position: -21px 0;
    }
}

.quote_block_container {margin:0px 0px 20px 0px; overflow-x: auto}

.thread-icons-v2-reputation_highneg
, .thread-icons-v2-reputation_highpos
, .thread-icons-v2-reputation_neg
, .thread-icons-v2-reputation_off
, .thread-icons-v2-reputation_pos
, .thread-icons-v2-reputation_balance
{
    display:inline-block;
    position:relative;
    top:2px;
}

/* Minor boxes */

.minor-box,
.minor-box h2,
.minor-box th {
    border: solid #7B63DB;
}

.minor-box {
    border-spacing: 0;
    border-width: 2px 0 1px;
    color: #272727;
    display: table;
    padding: 0 0 10px;
    width: 100%;
}

.minor-box .mb-inner {
    display: table-cell;
    vertical-align: top;
}

.minor-box a {
    color: #7B63DB !important;
}

.minor-box a:hover {
    color: #6652B8 !important;
}

.minor-box h2,
.minor-box th {
    border-width: 0 0 1px;
    color: #272727;
    line-height: 25px;
    white-space: nowrap;
}

.minor-box th {
    color: #272727;
    padding: 0 5px;
}

.minor-box th:first-child {
    color: #7B63DB;
    font-size: 1.167em;
}

.minor-box td {
    border-bottom: 1px dotted #ccc;
    padding: 5px;
}

.minor-box a {
    color: #7B63DB;
}

.minor-box a:hover {
    color: #6652B8;
}

.minor-box h2,
.minor-box p,
.minor-box ol,
.minor-box ul {
    padding: 0 5px;
}

.minor-box h2 {
    color: #7B63DB;
    font-size: 1.167em;
    font-weight: normal;
    margin: 0 0 12px;
}

.minor-box h2 img {
    display: inline-block;
    vertical-align: middle;
}

.minor-box p,
.minor-box ul {
    margin: 12px 0 0;
}

/* Minor boxes - active users and birthdays */

#forum-info,
#forum-active-users {
    margin-bottom: 24px;
}

#forum-info {
    margin-top: 30px;
}

#forum-stats {
    width: 30%;
}

#forum-birthdays h2 {
    padding-left: 38px;
    position: relative;
}

#forum-birthdays h2 a {
    position: absolute;
        top: -4px;
        left: 0;
}

#forum-active-users ul li,
#forum-birthdays ul li {
    display: inline;
}

#forum-active-users ul li:before,
#forum-birthdays ul li:before {
    content: ", ";
}

#forum-active-users ul li:first-child:before,
#forum-birthdays ul li:first-child:before {
    content: "";
    content: none;
}

#forum-birthdays ul li .age:before {
    content: "(";
}

#forum-birthdays ul li .age:after {
    content: ")";
}

#forum-active-users {
    padding: 0;
}

#forum-active-users h2,
#forum-active-users ul {
    display: inline;
    margin: 0;
}

#forum-active-users h2 {
    border: none;
}

#forum-active-users h2:after {
    content: ":";
}

#forum-active-users ul {
    padding-left: 3px;
}

#forum-active-users ul li {
    display: inline-block;
    font-size: 1.083em;
    line-height: 1.25em;
    padding: 0 0 4px;
}

/* Minor boxes - announcements */

#announcements_container { min-height: 75px; }

#announcements table {
    width: 100%;
    border-bottom: none;
    border-collapse: collapse;
    padding-bottom: 0;
    border-top: 1px dotted #ccc;
}

table.solitary {
    border-top-style: none ! important;
}

#announcements th {
    border-top-width: 1px;
}

#announcements th.posted {
    width: 90px;
}

.announcement .icon {
    width: 24px;
}

.announcement .title a {
    color: #4088b8 !important;
}

.announcement .posted {
    color: #666;
    width: 75px;
}

.announcement.important {
    background: #fdf9f9;
}

.announcement.important .title a {
    color: #eb333a !important;
}

.announcement.sponsored td {
    border: solid #e9e9e6;
    border-width: 3px 0;
}

.announcement.sponsored .title a {
    color: #333 !important;
    display: table-row;
}

.announcement.sponsored .title a img,
.announcement.sponsored .title a span {
    display: table-cell;
    vertical-align: middle;
}

.announcement.sponsored .title a img {
    max-height: 35px;
    padding-right: 5px;
}

.announcement-important-icon {
    background-position: 0 -304px;
    display: inline-block;
    height: 20px;
    width: 24px;
}

.announcement-icon {
    background-position: 0 -280px;
    display: inline-block;
    height: 20px;
    width: 22px;
}

#social-buttons {
    border: dotted #ccc;
    border-width: 1px 0;
    color: #808080;
    font-size: 1.083em;
    margin: 2px 0 16px;
    padding: 6px 0;
    width: 100%;
    overflow: hidden;
}

#social-buttons .last-updated {

}

/* Thread filter */

.thread-filter {
    background-color: #F8F5FF;
    border: 1px solid #ddd;
    color: #505050;
    margin: -6px 0 10px;
    padding: 10px;
}

.thread-filter .close-button {
    color: #7B63DB;
    float: right;
    text-decoration: none;
}

.thread-filter .close-button:hover {
    color: #6652B8;
    text-decoration: underline;
}

.thread-filter fieldset {
    border: none;
    padding: 0;
}

.thread-filter h3 {
    color: #7B63DB;
    font-size: 1.25em;
    font-weight: 700;
}

.thread-filter label:after {
    content: ":"
}

.thread-filter ol li {
    display: inline-block;
    margin-right: 20px;
    vertical-align: bottom;
}

.thread-filter p {
    margin: 0 0 12px;
}

.thread-filter select {
    display: block;
    margin: 8px 0 0;
}

/* Guest Login/Registration popup */
.popupHolder {
    position:absolute;
    left:0px;
    top:0px;
    width:100%;
    height:100%;
    background: rgb(0, 0, 0) transparent;
    background: rgba(0, 0, 0, 0.6);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#33000000, endColorstr=#33000000);
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#33000000, endColorstr=#33000000)";
    display:none;
    z-index:10000
}

.iframeHolder {
    position:absolute;
    width:610px;
    height:400px;
    background-color:transparent;
}

.popupFrame {
    width:100%;
    height:100%;
    background-color:transparent;
    border:0px;
    margin:0px;
    padding:0px;
    overflow:hidden
}

.signinregisterrollmenu
{
    cursor: pointer;
    position: relative;
    transition: all 0.3s;
}

.signinregisterrollmenu .menu
{
    background: white;
    border: 1px solid #336699;
    border-radius: 5px 5px 5px 5px;
    box-shadow: 0 2px 2px rgba(125, 125, 125, 0.5);
    color: #666;
    cursor: default;
    display: none;
    font-weight: normal;
    padding: 3px 10px;
    position: absolute;
    top: 30px;
    left: 0px;
    text-align: left;
    z-index: 30;
}

.signinregisterrollmenu .menu.menu-up-arrow:before
{
    border: 10px solid;
    border-color: transparent transparent #336699 transparent;
    content: ' ';
    height: 0;
    position: absolute;
    left: 50px;
    top: -20px;
    width: 0;
}

.signinregisterrollmenu .menu.menu-up-arrow:after
{
    border: 9px solid;
    border-color: transparent transparent #fff transparent;
    content: ' ';
    height: 0;
    position: absolute;
    left: 51px;
    top: -18px;
    width: 0;
}

.signinregisterrollmenu .menu.menu-down-arrow:before
{
    border: 10px solid;
    border-color: #336699 transparent transparent transparent;
    content: ' ';
    height: 0;
    position: absolute;
    left: 50px;
    top: 100%;
    width: 0;
}

.signinregisterrollmenu .menu.menu-down-arrow:after
{
    border: 9px solid;
    border-color: #fff transparent transparent transparent;
    content: ' ';
    height: 0;
    position: absolute;
    left: 51px;
    top: 100%;
    width: 0;
}

.signinregisterrollmenu .menu.postbit-menu {
    left:-35px;
}

.signinregisterrollmenu .menu.postbit-menu:before {
    left:100px;
}

.signinregisterrollmenu .menu.postbit-menu:after {
    left:101px;
}

.signinregisterrollmenu .menu.forumdisplay-menu:before {
    left:83px;
}

.signinregisterrollmenu .menu.forumdisplay-menu:after {
    left:84px;
}

.signinregisterrollmenu .menu a
{
    color: #006da8 !important;
    display: inline-block;
    line-height: 20px;
}

.signinregisterrollmenu ul li
{
    margin: 5px 0;
}

.signinregisterrollmenu ul li a
{
    white-space: nowrap;
}

.signinregisterrollmenu button
{
    font-weight:bold;
    font-size:12px !important;
    cursor:default !important;
}

label#fb_pulishlabel {
    margin-right:0px !important;
}

.top-search a {
    display: inline-block;
    text-transform: lowercase;
    vertical-align: top;
    width: 1px;
}

.top-search fieldset {
    background-color: #F8F5FF;
    border: none;
    padding: 10px;
}

.top-search input {
    border-radius: 3px;
    height: 28px;
    margin: 0;
    padding: 0 6px;
    vertical-align: top;
    width: 320px;
}

.top-search button {
    margin:3px 0px 0px 5px;
}

#page_showthread #mobile_reply_box,
#page_newreply #mobile_reply_box {
    border-radius: 0;
    border: 1px solid #ccc;
    box-sizing: border-box!important;
    font-size: 1.167em;
    height: 11.08em;
    margin-top: 6px;
    padding: 8px;
    resize: vertical;
    font-size: 16px;
    width: 100%;
    display: block;
    margin: 0 auto;
}

#page_usercp li.subforum {
    padding-left: 25px;
}
