/**
* A collection of CSS rules relating to the page footer.
* @author A TSR Developer
* @device mixed
*/

/* Footer */

#footer {
    background-color: #7B63DB;
    color: white;
    margin: 0 auto 0 !important;
    min-width: 980px;
    position: relative;
}


#footer .page-section {
    width: 915px;
    padding: 0px 20px 20px 20px;
}

#footer,
#footer a {
    color: white;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-shadow: rgba(0,0,0,.01) 0 0 1px;
    -webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
}

#footer #disclaimers,
#footer .footer-nav {
    display: inline-block;
    vertical-align: top;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-shadow: rgba(0,0,0,.01) 0 0 1px;
    -webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
}

#footer .footer-nav:first-child {
    display: none;

}

.lte7 #footer .footer-nav {
    display: inline;
}

#disclaimers {
    background-color: #7B63DB;
    font-size: 13px;
    width: 100%;
    text-align: center;
    padding: 16px 0px 16px 0px;
    font-family: Gibson, "Myriad Web Pro", "Myriad Web", "Myriad Std", "Frutiger LT Std", "Segoe UI", Arial, sans-serif;
    min-width:980px;
}

#disclaimers p {
    width: 100%;
    text-align: center;
    margin: 0px auto 5px;
    font-family: Gibson, "Myriad Web Pro", "Myriad Web", "Myriad Std", "Frutiger LT Std", "Segoe UI", Arial, sans-serif;
    color: #ffffff;
}

#footer .footer-nav {
    width: calc(25% - 6px);
}

#footer .seo-links {
    padding-right: 5px;
}

#footer .footer-nav h2 {
    margin: 16px 0 5px;
    font-size: 18px;
    font-weight: bold;
}

#footer .footer-nav ul {
    padding-left: 0px;
}

#footer .footer-nav ul li {
    list-style: none;
    font-family: Gibson, "Myriad Web Pro", "Myriad Web", "Myriad Std", "Frutiger LT Std", "Segoe UI", Arial, sans-serif;
    padding: 0px 0px 5px 0px;
    font-weight: normal;
    font-size: 14px;
}

#footer .twitter a,
#footer .facebook a,
#footer .youtube a,
#footer .instagram a,
#footer .tiktok a {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/base/sprite-footer-social.png") no-repeat;
    background-size: 20px 123px;
    display: block;
    font-size: 13px;
    line-height: 20px;
    padding-left: 28px;
    white-space: nowrap;
    font-weight: normal;
    margin:5px 0px 0px 0px;
}

@media (-webkit-min-device-pixel-ratio: 1.25),
(min-resolution: 120dpi) {
    #footer .twitter a,
    #footer .facebook a,
    #footer .youtube a,
    #footer .instagram a,
    #footer .tiktok a {
        background-image: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/base/sprite-footer-social@2x.png");
        background-size: 20px 120px;
    }
}

#footer .facebook a {
    background-position: 0 -25px;
}

#footer .youtube a {
    background-position: 0 -50px;
}

#footer .instagram a {
    background-position: 0 -75px;
}

#footer .tiktok a {
    background-position: 0 -100px;
}

#clearing-modal-container, #exitPopup-container {
    background-color: #666;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000;
    display: none;
}

#clearing-modal-container #clearing-popup {
    background: url('//static.thestudentroom.co.uk/67e2a42d66799/forum/images/clearing/Clearing-pop-up-image.png') no-repeat 313px 183px;
    background-size: 133px 92px;
    width: 475px;
    height: 290px;
    background-color: white;
    border: 5px solid #38B3E1;
    margin: 0 auto;
    position: relative;
}

#clearing-modal-container #clearing-popup .modal-inner-close {
    margin-left: 455px;
    font-size: 22px;
}

#clearing-modal-container #clearing-popup h1 {
    font-family: "Museo Sans Cyrillic", Arial, Geneva, Lucida, sans-serif;
    font-weight: normal;
    color: #44a0d4;
    line-height: 1em;
    margin: 0 30px;
    font-size: 36px;
}

#clearing-modal-container #clearing-popup h2 {
    font-family: "Museo Sans Cyrillic", Arial, Geneva, Lucida, sans-serif;
    font-weight: normal;
    color: #ff7c4d;
    font-size: 18px;
    margin: 10px 30px;
}

#clearing-modal-container #clearing-popup p {
    color: #666;
    font-family: Arial, Geneva, Lucida, sans-serif;
    font-size: 14px;
    margin: 0px 30px 0px 30px;
}

#clearing-modal-container #clearing-popup ul {
    width: 250px;
    margin-left: 30px;
    margin-top: 15px;
}

#clearing-modal-container #clearing-popup li {
    background-color: #FF7C45;
    width: 99%;
    height: 35px;
    margin-bottom: 8px;
    font-family: "Museo Sans Cyrillic", Verdana, Arial, Geneva, Lucida, sans-serif;
}

#clearing-modal-container #clearing-popup li a {
    display: inline-block;
    color: #fff;
    font-size: 18px;
    text-align: left;
    padding-left: 10px;
    padding-top: 8px;
    text-decoration: none !important;
    width: 96%;
}

#clearing-modal-container #clearing-popup li a img {
    margin-top: -3px;
    float: right;
    margin-right: 6px;
}

#clearing-modal-container #clearing-popup li a img.desktop {
    display: block;
}

#clearing-modal-container #clearing-popup li a img.mobile {
    display: none;
}

.exitPopup-action-close {
    color: white !important;
    margin-left: 615px;
}

#exitPopup {
    width: 640px;
    height: 362px;
    background-color: #FFFFFF;
    margin: 0px auto;
    box-shadow: 2px 2px 3px -1px rgba(0, 0, 0, 0.5);
}

#exitPopup .top-bar {
    position: relative;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 76px;
    background-color: #3F9FD6;
}

#exitPopup .top-bar h1 {
    color: white;
    font-family: 'Museo Sans Cyrillic', Verdana, Arial, Geneva, Lucida, sans-serif;
    font-size: 33px;
    margin-left: 124px;
    margin-top: -12px;
}

.exit-popup-left {
    float: left;
    width: 310px;
    height: 280px;
}

.exit-popup-right {
    float: right;
    width: 310px;
    height: 280px;
}

.exit-popup-left h2,
.exit-popup-right h2 {
    width: 220px;
    margin: 0 0 0.67em;
    margin-left: 45px;
    margin-top: 30px;
    font-weight: normal;
    font-family: arial, helvetica, clean, sans-serif;
    font-size: 18px;
}

.exit-popup-right h2 {
    margin-left: 15px;
    margin-bottom: 15px;
}

.exit-popup-left .exit-img {
    margin-left: 45px;
    width: 251px;
    height: 118px;
    background: url('//static.thestudentroom.co.uk/67e2a42d66799/forum/images/miscellaneous/exit-popup.png') no-repeat 0px 0px;
    background-size: 251px 118px;
    padding-bottom: 15px;
}

.exit-popup-left a,
.exit-popup-right a {
    font-family: 'Museo Sans Cyrillic', Verdana, Arial, Geneva, Lucida, sans-serif;
    padding-bottom: 4px;
    padding-left: 12px;
    padding-top: 5px;
    margin-left: 45px;
    font-size: 16px;
    line-height: 26px;
    text-decoration: none !important;
    color: white !important;
    display: block;
    width: 240px;
}

.exit-popup-left a:after,
.exit-popup-right a:after {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/studentauth/arrow-blob.png") no-repeat 0 center;
    content: "\a0";
    width: 29px;
    height: 26px;
    margin-left: 55px;
    display: inline-block;
}

.exit-popup-right a {
    margin-left: 15px;
    margin-bottom: 8px;
}

.exit-popup-right a#gcse:after {
    margin-left: 124px;
}

.exit-popup-right a#alevel:after {
    margin-left: 111px;
}

.exit-popup-right a#uni:after {
    margin-left: 48px;
}

.exit-popup-right a#other:after {
    margin-left: 85px;
}

.exit-popup-left a:hover,
.exit-popup-right a:hover {
    opacity: 0.9;
}

#footer #footer_info_mobile {
    display: none;
}

@media (-webkit-min-device-pixel-ratio: 1.25),
(min-resolution: 120dpi) {
    .simplemodal-close,
    #clearing-modal-container #clearing-popup {
        background-image: url('//static.thestudentroom.co.uk/67e2a42d66799/forum/images/clearing/Clearing-pop-up-image@2x.png');
        background-size: 133px 92px;
    }
}
/* ## start-device-block mobile ##*/
@media screen and (max-width: 480px) {

    #clearing-modal-container #clearing-popup {
        background-position: 155px 223px;
        width: 300px;
        height: 330px;
        top: 70px
    }

    #clearing-modal-container #clearing-popup .modal-inner-close {
        margin-left: 280px;
    }

    #clearing-modal-container #clearing-popup h1 {
        margin: -13px 16px 0px;
        font-size: 28px;
    }

    #clearing-modal-container #clearing-popup h2 {
        margin: 10px 16px;
        font-size: 16px;
    }

    #clearing-modal-container #clearing-popup p {
        margin: 0 15px;
    }

    #clearing-modal-container #clearing-popup ul {
        width: 130px;
        margin-left: 15px;
        margin-top: 14px;
    }

    #clearing-modal-container #clearing-popup li a img {
        margin-right: 10px;
    }

    #clearing-modal-container #clearing-popup li a img.desktop {
        display: none;
    }

    #clearing-modal-container #clearing-popup li a img.mobile {
        display: block;
    }

}
/* ## end-device-block ## */

#footer > .page-section > .footer-nav > h2{
    font-weight: bold !important;
}
footer.page-footer > #footer{
    width:100%;
    line-height:17px
}
footer.page-footer > #footer >.page-section{
    margin:0px auto 0px;
}

/* ## start-device-block mobile ##*/
@media screen and (max-width: 480px) {

    div.footer-nav li.footer-space{
       display: none;
    }
    div.footer-nav li.footer-android{
        display: none;
    }

}

/* cross promote tsrg footer section */

#footer .page-section .tsrg {
    display: flex;
    flex-direction: column;
    padding-bottom: 0px;
}

#footer .footer-cross-promo {
    display: flex;
    align-items: center;
    margin-bottom: -5px;
    border-bottom: 1px solid white;
    padding-top: 10px;
    padding-bottom: 10px;
}

#footer .footer-cross-promo .tsrg-logos{
    align-items: center;
    padding: 10px 0;
}

#footer .footer-cross-promo .tsrg-messaging {
    display: flex;
    padding-left: 20px;
    margin: 0;
    font-size: 14px;
}

#footer .footer-cross-promo .tsrg-messaging p {
    margin: -4px 0 0;
}

@media screen and (max-width: 480px) {

    #footer {
        padding: 0;
    }

    #footer .page-section.tsrg {
        column-count: 1;
    }

    #footer .footer-cross-promo {
        display: flex;
        flex-wrap: wrap;
    }

    #footer .footer-cross-promo .tsrg-logos{
        padding: 10px 0;
    }

    #footer .footer-cross-promo .tsrg-messaging {
        text-align: left;
        padding-left: 0;
        padding-bottom: 15px;
    } 

    #footer .footer-cross-promo .tsrg-messaging p {
        margin: 0;
    }
}
