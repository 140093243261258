/**
* CSS relating to styling BB code content.
* @author A TSR Developer
* @device all
*/

.bb-spoiler .pre-spoiler {
    display: block;
    font-size: 0.923em;
    padding-bottom: 5px;
}

.bb-spoiler .pre-spoiler .interact {
    margin-left: 9px;
}

.bb-spoiler .spoiler-content {
    border: 1px solid #c1c2c2;
    display: none;
    padding: 6px;
}

.bb-expand {
    background-color: #f4f4ee;
    border: 1px solid #c1c1c2;
    margin-top: 18px;
    padding: 6px;
    position: relative;
}

.bb-expand .pre-expand {
    color: #3aa6d0;
    cursor: pointer;
    font-size: 0.923em;
    line-height: 18px;
    position: absolute;
        top: -18px;
        left: 0;
}

.bb-expand .pre-expand:hover {
    text-decoration: underline;
}

.bb-expand .expand-content {
    display: none;
}

.bb-forum:before,
.bb-user:before {
    color: #505050;
    display: inline-block;
}

.bb-user:before {
    content: "@";
}
