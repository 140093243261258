.gizmo-askaquestion {
    font-family: Arial, sans-serif;
    margin: 35px 0;
}

.gizmo-askaquestion.modal {
    background: white;
    margin: 0;
    min-height: 100vh;
}


.gizmo-askaquestion .modal-inner-close {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/register/custom/mobile/close.svg") no-repeat center center / 12px 12px;
    line-height: 27px;
    overflow: hidden;
    position: absolute;
        top: 0;
        right: 0;
    text-indent: 1000px;
    width: 27px;
}

.gizmo-askaquestion .ordered-form,
.gizmo-askaquestion input,
.gizmo-askaquestion label,
.gizmo-askaquestion textarea,
.gizmo-askaquestion select {
    font-family: Arial, sans-serif !important;
}

.gizmo-askaquestion fieldset {
    border: 0;
    padding: 0;
}

.gizmo-askaquestion optgroup {
    font-weight: normal;
}

.gizmo-askaquestion select {
    font-size: 16px;
}

.gizmo-askaquestion label {
    margin: 0;
}

.gizmo-askaquestion .header-bar {
    background-color: #7B63DB;
    height: 60px;
}

.gizmo-askaquestion .header-bar .standard-logo {
    position: absolute;
    top: 10px;
    left: 12px;
}

.gizmo-askaquestion .header-bar .standard-logo > .retina {
    display: none;
}

.gizmo-askaquestion > .legend {
    color: #444;
}

.gizmo-askaquestion > .legend > .title {
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
}

.gizmo-askaquestion.modal > .legend > .title {
    color: #4a4a4a;
    font-size: 27px;
    line-height: 1.231;
    padding: 50px 40px 20px;
    text-align: center;
}

.gizmo-askaquestion > .legend > .subtitle {
    display: none;
    font-size: 16px;
}

.gizmo-askaquestion.modal > .legend > .subtitle {
    color: #4a4a4a;
    display: block;
    padding: 0 40px 20px;
    text-align: center;
}

.gizmo-askaquestion > .legend a {
    color: #7B63DB;
}

.gizmo-askaquestion.modal > .content {
    padding: 0 40px 40px;
}

.gizmo-askaquestion > .content > .success, .gizmo-askaquestion > .content > .modqueue {
    display: none;
    font-size: 18px;
    margin-top: 12px;
}

.gizmo-askaquestion.modal > .content > .success, .gizmo-askaquestion.modal > .content > .modqueue {
    text-align: center;
    margin-top: 100px;
}

.gizmo-askaquestion.modal > .content > .success::before {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/images/gizmo/askaquestion/thanks.svg") no-repeat center center / 110px 107px #8cc7a6;
    border-radius: 100%;
    content: "\a0";
    display: block;
    height: 156px;
    margin: 0 auto 50px;
    width: 156px;
}

.gizmo-askaquestion > .content > .success > .thanks {
    font-size: 27px;
    font-weight: bold;
    margin-bottom: 12px;
}

.gizmo-askaquestion > .content > .success > .action, .gizmo-askaquestion > .content > .modqueue > .action {
    margin-top: 16px;
}

@media (-webkit-min-device-pixel-ratio: 1.25),
       (min-resolution: 120dpi) {
    .gizmo-askaquestion .header-bar .standard-logo > .standard {
        display: none;
    }

    .gizmo-askaquestion .header-bar .standard-logo > .retina {
        display: inline;
    }
}

@media screen and (max-width: 481px),
       screen and (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 801px),
       screen and (min-resolution: 120dpi) and (max-width: 801px) {
    .gizmo-askaquestion.modal > .legend > .title,
    .gizmo-askaquestion.modal > .legend > .subtitle,
    .gizmo-askaquestion.modal > .content {
        padding-left: 20px;
        padding-right: 20px;
    }

    .gizmo-askaquestion.modal > .legend > .title {
        font-size: 22px;
        padding-top: 20px;
    }

    .gizmo-askaquestion.modal > .legend > .subtitle {
        padding-bottom: 10px;
    }

    .gizmo-askaquestion .header-bar {
        height: 50px;
    }

    .gizmo-askaquestion .header-bar .standard-logo {
        left: 10px;
        top: 6px;
    }

    .gizmo-askaquestion .header-bar .standard-logo > img {
        width: 110px !important;
    }
}

.gizmo-askaquestion .ordered-form > ol {
    margin: 10px 0 0;
    padding: 0;
}

.gizmo-askaquestion .ordered-form > ol li {
    list-style: none;
}

/* This rule is to overwrite an annoying style in Drupal's styles.css */
article[role="article"] .gizmo-askaquestion .ordered-form > ol li {
    margin-bottom: 0;
}

.gizmo-askaquestion .ordered-form .forum {
    font-size: 16px;
}

.gizmo-askaquestion .ordered-form .forum .secondary,
.gizmo-askaquestion .ordered-form .forum .prefix {
    display: none;
    margin-top: 16px;
}

.gizmo-askaquestion .ordered-form .forum .secondary.default-visible,
.gizmo-askaquestion .ordered-form .forum .prefix.default-visible {
    display: block;
}

@media screen and (min-width: 481px),
       screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 801px),
       screen and (min-resolution: 120dpi) and (min-width: 801px) {
    .gizmo-askaquestion .ordered-form .forum {
        display: flex;
        flex-direction: row;
    }

    .gizmo-askaquestion .ordered-form .forum > div {
        box-sizing: border-box;
        flex: 1 1;
        max-width: 50%;
    }

    .gizmo-askaquestion .ordered-form .forum > .main {
        padding-right: 12px;
    }

    .gizmo-askaquestion .ordered-form .forum.has-secondary.has-prefix > .main {
        padding-right: 16px;
    }

    .gizmo-askaquestion .ordered-form .forum .secondary,
    .gizmo-askaquestion .ordered-form .forum .prefix {
        margin-top: 0;
        padding-left: 12px;
    }

    .gizmo-askaquestion .ordered-form .forum.has-prefix > .secondary {
        padding-left: 8px;
        padding-right: 8px;
    }

    .gizmo-askaquestion .ordered-form .forum.has-secondary > .prefix {
        padding-left: 16px;
    }

    .gizmo-askaquestion .ordered-form .forum > .prefix:first-child {
        padding-left: 0;
    }
}

.gizmo-askaquestion .ordered-form .input-text {
    border-color: #bbbbb5 !important;
    font-size: 16px;
    line-height: 23px;
    padding: 8px;
    font-weight: normal;
    font-style: italic;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    color: #9b9b9b;
}

.gizmo-askaquestion .ordered-form .input-text.invalid {
    border-color: #db8a8f !important;
}

.gizmo-askaquestion .ordered-form textarea {
    height: 133px; /* 5 lines + padding + border = (23*5) + (8*2) + (1*2) = 133 */
    min-height: 133px;
    resize: vertical;
}

.gizmo-askaquestion .ordered-form .select {
    border-color: #bbbbb5;
    height: 36px;
    line-height: 36px;
}

.gizmo-askaquestion .ordered-form .select:before {
    background-position: 10px -109px;
    width: 35px;
}

.gizmo-askaquestion .ordered-form .message {
    margin: 14px 0 0;
}

.gizmo-askaquestion .ordered-form .anonymous {
    float: left;
    margin: 18px 0 0;
}

.gizmo-askaquestion .ordered-form .anonymous.default-hidden {
    display: none;
}

.gizmo-askaquestion .ordered-form .anonymous label {
    color: #444;
    cursor: default;
    font-size: 16px;
    min-height: 0;
    padding-left: 0;
}

.gizmo-askaquestion.disabled .input-text,
.gizmo-askaquestion.disabled .select {
    background-color: #d8d8d8 !important;
}

.gizmo-askaquestion.disabled .bubble {
    opacity: 0.5;
}

.gizmo-askaquestion .error {
    clear: left;
    color: #eb333a;
    float: left;
    font-size: 16px;
    font-weight: bold;
    line-height: 23px;
    margin-top: 0;
}

.gizmo-askaquestion .error > div {
    display: none;
    padding-top: 16px;
}

.gizmo-askaquestion .error > div::before {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/miscellaneous/exclamation.svg") no-repeat 0 0 / contain;
    content: "\a0";
    display: inline-block;
    height: 13px;
    margin: 5px 5px 0 0;
    vertical-align: top;
    width: 13px;
}

.gizmo-askaquestion .ordered-form .submit {
    float: right;
    margin-top: 16px;
}