/**
* CSS rules mostly relating to the CMS content, though some are used elsewhere.
* @author A TSR Developer
* @device all|desktop|mobile|mixed
*/

/* Snippets */

.snippet-column {
    display: table-cell;
    padding-left: 8px;
    vertical-align: top;
}

.snippet-column:first-child {
    padding-left: 0;
}

.snippet-button, .snippet-h2, .snippet-h3, .snippet-p, .snippet-ul, .snippet-ils, .snippet-ls, .snippet-img.block .snippet-date, .snippet-uniguide, .snippet-youtube {
    margin: 15px 0 0;
}

.snippet-button {
    background-color: #44a0d4;
    color: white !important;
    display: block;
    font-size: 1.083em;
    font-weight: normal;
    height: 40px;
    line-height: 40px;
    text-align: center;
    transition: all 0.2s;
}

.snippet-button.highlight {
    background-color: #f57345;
}

.snippet-button:hover {
    background-color: #93c762;
    text-decoration: none;
}

.snippet-button.secondary,
.snippet-button.add {
    float: left;
    font-family: Arial, Geneva, Lucida, sans-serif !important;
    font-size: 1em;
    font-weight: bold;
    height: 32px;
    line-height: 32px;
    min-width: 150px;
    padding: 0 10px;
}

.snippet-button.secondary {
    background-color: #3bb5e0;
}

.snippet-button.add {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/snippet/button/add.png") no-repeat 10px 8px #e5e5d9;
    color: #226ab8 !important;
    padding-left: 36px;
}

.snippet-results {
    margin-bottom: 20px;
    padding-top: 20px;
}

.snippet-results:first-child {
        padding-top: 0;
}

.snippet-results+.snippet-results {
    border-top: 1px dotted #dbdcdd;
}

.snippet-results:after {
    clear: both;
    content: "";
    display: block;
}

@media (-webkit-min-device-pixel-ratio: 1.25),
       (min-resolution: 120dpi) {
    .snippet-button.add {
        background-image: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/snippet/button/add@2x.png");
        background-size: 16px 16px;
    }
}

.snippet-button.add.right,
.snippet-button.secondary.right {
    float: right;
}

.snippet-button.add:after,
.snippet-button.secondary:after {
    content: "";
    content: none;
}

.snippet-h2 {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
}

.snippet-h3 {
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
}

.snippet-p {
    line-height: 1.5em;
}

.snippet-ul li {
    list-style: disc outside;
    margin-right: 1.167em;
    position: relative;
        left: 1.167em;
}

.ie .snippet-ul li {
    left: auto;
    list-style-position: inside;
    margin-right: 0;
}

.snippet-ul.forum-list li {
    left: 0;
    list-style: none;
    margin: 0 0 5px;
}

.snippet-ul.forum-list li a {
    background-color: #9ddaef;
    display: block;
    font-size: 15px;
    font-weight: bold;
    padding: 7px;
}

.snippet-ul.forum-list li a:hover {
    text-decoration: none;
}

.snippet-ul.hub-nav {
    background-color: #95c676;
    color: white;
    display: table;
    line-height: 20px;
    text-align: center;
    width: 100%;
}

.snippet-ul.hub-nav li {
    border-left: 1px solid white;
    display: table-cell;
    transition: all 0.3s;
    vertical-align: middle;
    left: 0;
}

.snippet-ul.hub-nav li:first-child {
    border: 0;
}

.snippet-ul.hub-nav li:hover {
    background-color: #3bb5e0;
}

.snippet-ul.hub-nav li a {
    color: white;
    display: block;
    font-size: 16px;
    padding: 7px 14px;
    position: relative;
    text-decoration: none;
    z-index: 1;
}

.snippet-ul.hub-nav.double li a span {
    display: table-cell;
    height: 40px;
    vertical-align: middle;
}

.snippet-ablock {
    display: block;
    line-height: 1.5em;
}

.snippet-ils, .snippet-ls {
    line-height: 18px;
}

.snippet-ils {
    padding-left: 82px;
    position: relative;
}

.snippet-ils.half,
.snippet-ls.half {
    display: inline-block;
    box-sizing: border-box;
    vertical-align: top;
    width: 50%;
}

.snippet-ils img {
    box-shadow: 0 0 1px 1px rgba(204, 204, 204, 0.5);
    height: 37px;
    position: absolute;
        top: 0;
        left: 0;
    width: 66px;
}

.snippet-ils .link,
.snippet-ils .subtitle,
.snippet-ls .link,
.snippet-ls .subtitle {
    margin-right: 16px;
}

.snippet-ils .link,
.snippet-ls .link {
    color: #369;
    display: inline-block;
}

.snippet-ils .link:after,
.snippet-ls .link:after {
    content: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/snippet/ils/arrow.png");
    display: inline-block;
    padding-left: 5px;
    vertical-align: middle;
}

.snippet-ils .subtitle,
.snippet-ls .subtitle {
    display: block;
    font-size: 0.917em; /* 11px */
}

.snippet-img.block,
.snippet-img.block img {
    display: block;
}

.snippet-img.compact {
    display: none;
}

.snippet-img.left,
.snippet-img.right {
    position: relative;
    z-index: 1;
}

.snippet-img.left {
    float: left;
    margin-right: 8px;
}

.snippet-img.right {
    float: right;
    margin-left: 8px;
}

.snippet-uniguide {
    line-height: 18px;
}

.snippet-uniguide a {
    display: table-cell;
    vertical-align: middle;
}

.snippet-uniguide img {
    height: 37px;
}

.snippet-uniguide .link {
    font-weight: bold;
    height: 37px;
    padding-left: 8px;
}


.snippet-date .calendar {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/miscellaneous/uniprofiles-calendar.gif") no-repeat -1px 0;
    color: #444;
    cursor: default;
    float: left;
    height: 17px;
    margin-right: 8px;
    padding-top: 12px;
    text-align: center;
    width: 32px;
}

.snippet-date .date {
    display: block;
}

.snippet-quicklinks {
    background: #E6E3E4;
    padding: 10px;
}

.snippet-quicklinks > strong {
    display: block;
}

.snippet-quicklinks li {
    display: inline-block;
    list-style: none;
}
.snippet-quicklinks li a {
    white-space: nowrap;
}

.snippet-contentblock .snippet-blue-block {
    background: #3bb5e0;
    padding: 2px 12px;
    color: #fff;
}
.snippet-contentblock .snippet-blue-block h2 {
    color: #fff;
}

/* Featured Story blocks */
.snippet-featured-story {
    position: relative;
    float: left;
    width: 100px;
    padding-left: 12px;
    color: #808080;
    line-height: 1.333em;
}
.snippet-featured-story .headline-image {
    margin-bottom: 8px;
    display: block;
}
.snippet-featured-story .headline-image img {
    height: 100px;
    width: 100px;
}
.snippet-featured-story h3 {
    margin-top: 0;
    font-size: 1em;
    font-weight: normal;
}
.snippet-featured-story h3 a {
    color: #006DA8;
    text-decoration: underline;
}

/* Student Finance Block */
.snippet-sfnews {
    width: 600px;
}

.snippet-sfnews > div.sfnews-title {
    float:left;
    border: solid #d2d2d4 2px;
}

.sfnews-title {
    float:left;
    width: 136px;
    background-color: #E05037;
    border-right: solid #d2d2d4 2px;
    color: white;
    padding: 5px;
}

.sfnews-text {
    float: left;
    width: 436px;
    background-color: #808185;
    color: white;
    padding: 5px;
}

.snippet-sfnews div:first-child .sfnews-text {
    display: none;
}

/*----- End of Student Finance Block -----*/

.snippet-youtube > div {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
}

.snippet-youtube > div embed,
.snippet-youtube > div iframe,
.snippet-youtube > div object {
    height: 100%;
    position: absolute;
        top: 0;
        left: 0;
    width: 100%;
}

.snippet-bulletlist {
    background-position: -9999px!important;
}

.snippet-bulletlist li {
    display: block;
    padding: 0 0 0 34px;
    background-repeat: no-repeat;
    background-image: inherit;
}

.snippet-bulletlist li a {
    color: #4492c6!important;
    font-size: 1.2em;
    font-weight: normal;
    font-family: 'Museo Sans Cyrillic', Arial, Geneva, Lucida, sans-serif;
}

.snippet-cmsheader {
    padding: 20px 12px 5px 10px;
    color: white!important;
}

.snippet-cmsheader h2 {
    font-size: 34px!important;
    color: white!important;
    font-family: "Museo Sans Cyrillic",Arial,Geneva,Lucida,sans-serif !important;
    display: inline-block;
    margin: 0px!important;
    border-bottom: none!important;
    padding-bottom: 5px!important;
}

.snippet-cmsheader p {
    font-size: 14px;
    font-family: 'helvetica neue', helvetica, Arial;
    margin: 0px!important;
}

.snippet-cmsheader hr {
    border: 1px dashed #fff;
    color: #fff;
    margin-bottom: 4px;
}
