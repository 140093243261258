/**
* CSS rules relating to the popup menu/tooltip on buttons like the "follow" button within forum display pages.
* @author A TSR Developer
* @device all
*/

/* Tooltip - base */

.tooltip {
    background: white;
    border: 1px solid #c4c6c6;
    box-shadow: 0 3px 3px rgba(125, 125, 125, 0.5);
    color: #666;
    cursor: default;
    display: none;
    font-size: 12px;
    line-height: 1.333;
    padding: 10px;
    position: absolute;
        top: 33px;
        right: 0;
    z-index: 30;
}

.tooltip:before,
.tooltip:after {
    border: 7px solid;
    content: "\a0";
    height: 0;
    position: absolute;
        right: 10px;
    width: 0;
}

.tooltip:before {
    border-color: transparent transparent #c4c6c6;
    top: -15px;
}

.tooltip:after {
    border-color: transparent transparent white;
    top: -14px;
}

/* Tooltip - above element */

.tooltip.above {
    top: auto;
    bottom: 33px;
}

.tooltip.above:before {
    border-color: #c4c6c6 transparent transparent;
    bottom: -15px;
    top: auto;
}

.tooltip.above:after {
    border-color: white transparent transparent;
    bottom: -14px;
    top: auto;
}

/* Tooltip - left anchor */

.tooltip.left {
    left: 0;
    right: auto;
}

.tooltip.left:before,
.tooltip.left:after {
    left: 10px;
    right: auto;
}

.tooltip.middle:before,
.tooltip.middle:after {
    left: calc(50% - 7px);
    right: auto;
}

/* Tooltip - floating */

.tooltip.floating:before,
.tooltip.floating:after {
    content: "";
    content: none;
}

/* Tooltip - extra stuff */

.tooltip.oneline {
    white-space: nowrap;
}

.tooltip.persist.oneline {
    padding-right: 32px;
}

/* Tooltip - close button */

.tooltip > .tooltip-close {
    cursor: pointer;
    float: right;
    margin: -5px -5px 5px 5px;
}

.tooltip.persist.oneline > .tooltip-close {
    position: absolute;
        top: 10px;
        right: 10px;
}

/* Tooltip - unfollow style */

.tooltip-unfollow {
    background-color: #6652B8;
    color: white;
    padding: 0;
    text-align: left;
    top: 25px;
    width: 175px;
}

.tooltip-unfollow:after {
    border-color: transparent transparent #6652B8;
}

.tooltip-unfollow > .tooltip-close {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/button/close2.png") no-repeat 0 0;
    height: 12px;
    margin: 5px;
    width: 12px;
}

.tooltip-unfollow > .confirmation {
    font-size: 1.083em;
    padding: 6px 10px;
}

.tooltip-unfollow > .action {
    background-color: white;
    padding: 8px 10px;
    display: flex;
    justify-content: space-between;
}

.tooltip-unfollow > .action > .interact {
    font-size: 12px;
}

.tooltip-unfollow > .action > .interact:first-child {
    margin-right: 17px;
}

/* Toast window */

.toast {
    background-color: white;
    border: 1px solid #c4c6c6;
    border-bottom: 0;
    box-shadow: 0 1px 3px 3px rgba(125, 125, 125, 0.5);
    border-radius: 5px 5px 0 0;
    color: #666;
    cursor: default;
    font-size: 12px;
    line-height: 1.333;
    padding: 8px 16px;
    position: fixed;
        bottom: 0;
        left: 50%;
    text-align: center;
    white-space: nowrap;
    z-index: 9999;
}

.toast .failure,
.toast .success {
    display: inline-block;
    vertical-align: top;
}

.toast .failure:before,
.toast .success:before {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/miscellaneous/sprite-ordered-form@2x.png") no-repeat 0 -59px / 29px 190px;
    content: "\a0";
    display: inline-block;
    height: 14px;
    margin: 1px 8px 0 0;
    vertical-align: top;
    width: 15px;
}

.toast .failure:before {
    background-position: 0 -73px;
    width: 18px;
}

/* Hint */

.hint-node.transition {
    position: relative;
    z-index: 101;
}

.hint {
    background-color: white;
    border: 1px solid #7B63DB;
    box-shadow: 0 3px 3px rgba(125, 125, 125, 0.5);
    color: #666;
    cursor: default;
    display: none;
    font-size: 12px;
    font-weight: normal;
    line-height: 17px;
    max-width: 250px;
    padding: 12px 16px 12px 26px;
    position: absolute;
    text-align: left;
    z-index: 100;
}

.hint > .thread-icon {
    position: absolute;
        top: 12px;
        left: 5px;
}

.hint.transition > .thread-icon {
    display: none;
}

.hint > .hint-title {
    color: #7B63DB;
    display: block;
}

/* Back to top */

.back-to-top {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    color: white;
    cursor: pointer;
    display: none;
    font-size: 15px;
    height: 42px;
    position: fixed;
        right: 10%;
        bottom: -10px;
    width: 67px;
    z-index: 1000;
}

.back-to-top:before {
    content: "top";
    position: relative;
        top: 7px;
        left: 12px;
}

.back-to-top:after {
    border-color: transparent transparent white;
    border-style: solid;
    border-width: 0 6px 9px;
    content: "\a0";
    height: 0;
    position: absolute;
        top: 12px;
        left: 45px;
    width: 0;
}
