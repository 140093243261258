/**
* CSS relating to interactive button elements. Contains a few Retina related rules.
* @author A TSR Developer
* @device all
*/

button.plain {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1em;
}

/* Bubbles */

.bubble {
    background-color: #99FFA8;
    border: 1px solid #99FFA8;
    border-radius: 8px;
    box-sizing: content-box;
    color: #272727;
    cursor: pointer;
    display: inline-block;
    font-family: Arial, sans-serif !important;
    font-size: 14px;
    font-weight: bold;
    height: 20px;
    line-height: 20px;
    padding: 2px 12px;
    transition: all 0.1s;
    -webkit-font-smoothing: antialiased;
}

.bubble.no-lowercase {
    text-transform: inherit;
}

.bubble:hover,
button:hover > .bubble {
    background-color: #63CD73;
    border-color: #63CD73;
}

.bubble.secondary {
    background-color: #32b3e6;
    border-color: #32b3e6;
}

.bubble.secondary:hover,
button:hover > .interact.secondary {
    background-color: #3392ca;
    border-color: #2f88bc;
}

.bubble.secondary.light {
    background-color: #4ecbfc;
    border-color: #47b8e4;
}

.bubble.secondary.light:hover,
button:hover > .interact.secondary.light {
    background-color: #30aedf;
    border-color: #2ca1ce;
}

.bubble.chunky {
    background-color: #82c11f;
    border: none;
    border-radius: 4px;
    font-size: 15px;
    height: 36px;
    line-height: 36px;
    text-transform: none;
}

.bubble.chunky:hover,
button:hover > .bubble.chunky {
    background-color: #71a61c;
}

.bubble.chunky.secondary {
    background-color: #f9fcf4;
    border: 2px solid #82c11f;
    color: #6aa310;
    height: 32px;
    line-height: 32px;
}

.bubble.chunky.secondary:hover,
button:hover > .bubble.chunky.secondary {
    background-color: #eefad9;
}

a.bubble,
a.bubble:link,
a.bubble:hover,
a.bubble:visited {
    color: #272727;
    text-decoration: none !important;
}

.bubble.disabled {
    cursor: default;
}

button.disabled > .bubble,
.bubble.disabled {
    opacity: 0.5;
}

.bubble.large {
    font-size: 18px;
    height: 22px;
    line-height: 22px;
    padding: 4px 12px;
}

.interact-purple {
    cursor: pointer;
    display: inline-block;
    margin: 0 7px;
    padding: 1px 0;
    position: relative;
    color: #7B63DB;
}

.bubble.arrow-up:after,
.bubble.arrow-right:after,
.bubble.arrow-left:after {
    font-size: 9px;
    padding-left: 4px;
    vertical-align: baseline;
}

.bubble.arrow-down:after {
    font-size: 9px;
    padding-left: 4px;
    vertical-align: bottom;
    margin-bottom: -3px;
}

.bubble.large.arrow-up:after,
.bubble.large.arrow-right:after,
.bubble.large.arrow-left:after,
.bubble.large.arrow-down:after {
    font-size: 11px;
}

.bubble.submit-icon {
    font-size:15px;
}

.bubble.submit-icon:after {
    width:13px;
    height:11px;
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/interact/submit_icon.png") no-repeat;
    content:" ";
    display:inline-block;
    margin-left:3px;
}

.bubble.watched-icon:hover:before,
.bubble.watch-icon:before,
.bubble.watch-icon:hover:before,
.bubble.watched-icon:before {
    width:15px;
    font-family: tsrglyph;
    color: #fff;
    display:inline-block;
    margin-right:3px;
    vertical-align: top;
}

.bubble.watched-icon:hover:before,
.bubble.watch-icon:before {
    content: "\e910";
}

.bubble.watch-icon:hover:before,
.bubble.watched-icon:before {
    content: "\e911";
}

/* Interacts */

.interact {
    color: white;
    cursor: pointer;
    display: inline-block;
    margin: 0 7px;
    padding: 1px 0;
    position: relative;
}

.lte8 .interact,
.lte8 .interact.rollmenu.hover > span,
.nojs.lte8 .interact.rollmenu:hover > span {
    border-width: 1px;
    padding-left: 7px;
    padding-right: 7px;
}

.interact:hover,
button:hover > .interact {
    text-decoration: underline;
}

.interact:hover:before,
button:hover > .interact:before {
    background-position: left -96px, right -120px;
}

.interact.secondary,
.interact.secondary.light:hover,
button:hover > .interact.secondary.light {
    background-color: #3bb5e0;
    border-color: #449fd3;
}

.interact.secondary:before,
.interact.secondary.light:hover:before,
button:hover > .interact.secondary.light:before {
    background-position: left -144px, right -168px;
}

.interact.secondary:hover,
button:hover > .interact.secondary,
.interact.secondary.light {
    background-color: #8bd9ec;
    border-color: #7fcaf5;
}

.interact.secondary:hover:before,
button:hover > .interact.secondary:before,
.interact.secondary.light:before {
    background-position: left -192px, right -216px;
}

.interact.light {
    background-color: #ffa400;
    border-color: #e87e23;
}

.interact.light:before {
    background-position: left -48px, right -72px;
}

.interact.light:hover,
button:hover > .interact.light {
    background-color: #ee9900;
    border-color: #e87e23;
}

.interact.light:hover:before,
button:hover > .interact.light:before {
    background-position: left 0, right -24px;
}

.interact.tertiary,
.interact.tertiary:hover,
button:hover > .interact.tertiary {
    background-color: transparent;
    border-color: transparent;
}

.interact.tertiary {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #4088b8;
    font-weight: normal;
    margin: 0;
    padding: 2px 7px;
}

.interact.tertiary:before {
    content: "";
    content: none;
}

.interact.tall {
    height: 26px;
    line-height: 26px;
    padding-left: 4px;
    padding-right: 4px;
}

.interact.tall:before {
    background-position: left -624px, right -656px;
}

.interact.tall:hover:before,
button:hover > .interact.tall:before {
    background-position: left -688px, right -720px;
}

.interact.large {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/svg/blobs/interact-large.f57345.c34b20.svgz") no-repeat;
    background-size: 100% 100%;
    border: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 1.333em;
    font-weight: normal;
    height: 37px;
    line-height: 32px;
    margin: 0;
    padding: 0 10px;
    text-transform: none;
}

.lte8 .interact.large {
    background: none;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src="//static.thestudentroom.co.uk/67e2a42d66799/forum/images/svg/blobs/interact-large.f57345.de511f.280x37.png", sizingMethod="scale");
}

.interact.large.light {
    background-image: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/svg/blobs/interact-large.ee9900.e87c23.svgz");
}

.lte8 .interact.large.light {
    background: none;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src="//static.thestudentroom.co.uk/67e2a42d66799/forum/images/svg/blobs/interact-large.ee9900.e87c23.280x37.png", sizingMethod="scale");
}

.interact.small {
    font-size: 11px;
    height: 13px;
    line-height: 13px;
    margin: 0 4px;
    padding: 0 3px;
}

.interact.small.secondary:before,
.interact.small.secondary.light:hover:before,
button:hover > .interact.small.secondary.light:before {
    background-position: left -804px, right -821px;
}

.interact.small.secondary:hover:before,
button:hover > .interact.small.secondary:before,
.interact.small.secondary.light:before {
    background-position: left -838px, right -855px;
}

.interact.go:before,
.interact.large:before {
    content: "";
    content: none;
}

.interact.large:hover,
button:hover .interact.large,
.interact.go,
.interact.go:hover,
button:hover > .interact.go {
    background-color: transparent;
}

.interact.go {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/interact/go.png") no-repeat;
    border: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    height: 29px;
    line-height: 26px;
    font-weight: normal;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 0;
    text-align: center;
    text-transform: none;
    width: 31px;
}

.interact.go:hover,
button:hover > .interact.go {
    background-position: 0 -29px;
}

.interact.museo {
    font-weight: normal;
}

a.interact, a.interact:link, a.interact:hover, a.interact:visited {
    color: #7B63DB;
}

a.interact.tertiary, a.interact.tertiary:link {
    color: #7B63DB;
}
a.interact.tertiary:hover, a.interact.tertiary:visited {
    color: #6652B8;
    text-decoration: underline !important;
}

button.disabled,
.interact.disabled {
    cursor: default;
}

button.disabled .interact,
.interact.disabled {
    filter: alpha(opacity = 50);
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
    opacity: 0.5;
}

.arrow-down:after,
.arrow-up:after,
.arrow-right:after,
.arrow-left:after,
.interact.rollmenu.hover > span:after {
    display: inline-block;
    font-family: "tsrglyph";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 7px;
    font-weight: normal;
    speak: none;
    vertical-align: middle;
    width: 8px;
}

.arrow-down:after,
.interact.rollmenu.hover > span:after {
    content: "\e600";
}

.arrow-down.arrow-green:after {
    color: #599f26;
}

.arrow-down.arrow-grey:after {
    color: #505050;
}

.arrow-up:after {
    content: "\e603";
    width: 7px;
}

.arrow-right:after {
    content: "\e602";
    width: 5px;
}

.arrow-left:after {
    content: "\e601";
    float: left;
    margin-right: 6px;
    width: 5px;
}

.interact.tertiary.arrow-right:after {
    color: #7B63DB;
}

.interact.tertiary.arrow-right:hover:after {
    color: #6652B8;
}

a.interact.post-new-thread { font-size:1.4em; }

div.interact.forum-tools,
div.interact.search-forum{
        border:1px solid #7fcaf5; background-color:#8bd9ec;
}

div.interact.forum-tools:before,
div.interact.search-forum:before {
        background: url('//static.thestudentroom.co.uk/67e2a42d66799/forum/images/interact/blob-ends.png') no-repeat left -192px,
            url('//static.thestudentroom.co.uk/67e2a42d66799/forum/images/interact/blob-ends.png') no-repeat right -216px;
}

div.interact.search-forum:hover,
div.interact.forum-tools:hover {
    background-color: #3bb5e0;
    border-color: #449fd3;
}
div.interact.search-forum:hover:before,
div.interact.forum-tools:hover:before {
    background-position: left -144px, right -168px;
}

.interact.follow > .forum-tool,
.interact.unfollow > .forum-tool {
    height: 18px;
    padding: 0 2px 0 0;
    vertical-align: top;
    width: 12px;
}

.interact.follow > .forum-tool,
.interact.unfollow:hover > .forum-tool {
    background-position: 0px -415px;
}

.interact.follow:hover > .forum-tool,
.interact.unfollow > .forum-tool {
    background-position: 0px -393px;
}

.interact.small.follow > .forum-tool,
.interact.small.unfollow > .forum-tool {
    background-size: 20.01px auto;
    height: 13px;
    padding: 0;
    width: 8px;
}

.interact.small.follow > .forum-tool,
.interact.small.unfollow:hover > .forum-tool {
    background-position: 0 -297px;
}

.interact.small.follow:hover > .forum-tool,
.interact.small.unfollow > .forum-tool {
    background-position: 0 -281px;
}

.qr-preview > a {
    color: #4088b8;
    font-size:14px;
    margin-right:10px;
}

@media (-webkit-min-device-pixel-ratio: 1.25),
       (min-resolution: 120dpi) {
    .interact.go {
        background-image: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/interact/go@2x.png");
        background-size: 31px 58px;
    }

    .bubble.submit-icon:after {
        background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/interact/submit_icon@2x.png") no-repeat;
        background-size: 13px 11px;
    }
}

.interact.large.arrow-right:after {
    font-size: 9px;
    margin-left: 5px;
}

.interact > .compact {
    display: none!important;
}

.interact.strong {
    font-size: 1.4em;
    padding-left: 6px;
    padding-right: 6px;
}

.interact.strong.arrow-right:after {
    font-size: 8px;
}
