/**
* General CSS rules for student rating
* @author A TSR Developer
* @device all
*/

/* Star rating */

.student-stars {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/miscellaneous/sprite-unichoice-stars.png") 0 0 no-repeat ,
    url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/miscellaneous/sprite-unichoice-stars.png") -90px -17px no-repeat  ,
    url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/miscellaneous/sprite-unichoice-stars.png") 90px -34px no-repeat  ;
    display: inline-block;
    height: 17px;
    width: 90px; /* 18*5 */
    vertical-align: top;
}

.student-stars.zero.and-a-half,
.student-stars.one-half {
    background-position: 18px 0, -90px -17px, 0 -34px;
}

.student-stars.one {
    background-position: 18px 0, -72px -17px, 90px -34px;
}

.student-stars.one.and-a-half {
    background-position: 36px 0, -72px -17px, 18px -34px;
}

.student-stars.two {
    background-position: 36px 0, -54px -17px, 90px -34px;
}

.student-stars.two.and-a-half {
    background-position: 54px 0, -54px -17px, 36px -34px;
}

.student-stars.three {
    background-position: 54px 0, -36px -17px, 90px -34px;
}

.student-stars.three.and-a-half {
    background-position: 72px 0, -36px -17px, 54px -34px;
}

.student-stars.four {
    background-position: 72px 0, -18px -17px, 90px -34px;
}

.student-stars.four.and-a-half {
    background-position: 90px 0, -18px -17px, 72px -34px;
}

.student-stars.five {
    background-position: 90px 0, 0 -17px, 90px -34px;
}

button.studentRatings {
    width:230px;
    height:31px;
    padding: 0;
    border: none;
    outline: none ;
    background: none;
    color : #4088b8 ;
}
button.studentRatings:hover{
    cursor:pointer  ;
    text-decoration: none ;
}
button.studentRatings >img{
    vertical-align: middle ;
}
