/**
* Some general rules used for PM page and follow/follower pages amoungst others.
* @author A TSR Developer
* @device all
*/

.major-box {
	border: solid #cecfd0;
	border-width: 0 1px 1px;
}

.major-box h2 {
	background-color: #7B63DB;
	color: white;
	padding: 10px;
}

.blue-bar {
    background-color: #7B63DB;
    color: white;
    padding: 5px;
    font-size: 14px;
    font-family: Arial !important;
}

.block-arrow-link {
    background-color: #7B63DB;
    color: white !important;
    display: block;
    font-size: 15px;
    line-height: 35px;
    padding: 0 10px;
    position: relative;
}

a.block-arrow-link:hover {
    text-decoration: none;
}

.block-arrow-link:before {
    border: 8px solid;
    border-color: transparent transparent transparent white;
    content: "\a0";
    height: 0;
    position: absolute;
        top: 10px;
        right: 2px;
    width: 0;
}
