/**
* In page popup menu's triggered by button press. Used by moderation controls. 
* @author A TSR Developer
* @device all
*/

/* Rollover menu - structure */

.interact.rollmenu.hover,
.nojs .interact.rollmenu:hover {
    background: none;
    border: none;
    margin: -2px 0 0 5px;
}

.interact.rollmenu.hover:before,
.interact.rollmenu.hover:after,
.nojs .interact.rollmenu:hover:before,
.nojs .interact.rollmenu:hover:after {
    content: "";
    content: none;
}

.interact.rollmenu.hover > span,
.nojs .interact.rollmenu:hover > span {
    border-color: transparent;
    border-width: 1px 0;
    display: inline-block;
    height: 18px;
    line-height: 18px;
    margin: 0 3px;
    padding: 1px 0;
    color: #272727;
}

.mod .interact.rollmenu.hover > span:before,
.nojs .mod .interact.rollmenu:hover > span:before {
    background-position: left -288px, right -312px;
}

.interact.rollmenu.hover:after,
.interact.rollmenu.hover > span,
.nojs .interact.rollmenu:hover:after,
.nojs .interact.rollmenu:hover > span {
    position: relative;
    z-index: 3;
}

.interact.rollmenu .background {
    border: 1px solid #ccc;
    border-bottom: none;
    background: white;
    display: none;
    height: 100%;
    padding: 5px 3px 6px;
    position: absolute;
        top: -5px;
        left: -4px;
    width: 100%;
    z-index: 2;
}

.interact.rollmenu .menu {
    background: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 2px rgba(125, 125, 125, 0.5);
    color: #272727;
    cursor: default;
    display: none;
    font-weight: normal;
    margin-top: 6px;
    padding: 3px 8px;
    position: absolute;
        top: 100%;
        left: -4px;
    text-align: left;
    text-transform: none;
    z-index: 1;
}

.nojs .interact.rollmenu:hover .menu {
    display: block;
}

.rollmenu .menu.menuleft {
    left: auto;
    right: -4px;
}

/* Rollover menu - content */

.rollmenu .menu a,
.rollmenu .menu label {
    line-height: 22px;
}

.rollmenu .menu a {
    color: #7B63DB !important;
    display: inline-block;
}

.rollmenu .menu a:hover {
    color: #6652B8 !important;
}

.rollmenu .menu input[type="checkbox"] {
    margin: 0 6px;
    vertical-align: text-bottom;
}

.rollmenu fieldset {
    border: none;
    padding: 5px 0 0;
}

.rollmenu .menu > ul {
    border-top: 1px dotted #ccc;
}

.rollmenu .menu > ul:first-child {
    border-top: none;
}

.rollmenu ul li {
    margin: 5px 0;
}

.rollmenu ul li.submit {
    margin-bottom: 13px;
    margin-top: 9px;
}

.rollmenu ul li a,
.rollmenu .oneline {
    white-space: nowrap;
}

.rollmenu ul li input[type="radio"] {
    margin-right: 3px;
}
