.headthread {
    background-color: #7B63DB;
    color: white;
    display: none;
}

.headthread .headthread-content {
    max-width: 530px;
    margin: 0 10px;
    padding: 20px 0;
}

.headthread .headthread-title {
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 16px;
    text-align: left;
}

.headthread .ordered-form .input-text {
    font-family: Gibson, "Myriad Web Pro", "Myriad Web", "Myriad Std", "Frutiger LT Std", "Segoe UI", Arial, sans-serif;
    font-size: 16px;
    line-height: 1.375;
}

.headthread .ordered-form textarea.input-text {
    height: 132px;
    min-height: 132px;
    resize: none;
}

.headthread .ordered-form .threadmessage {
    margin-top: 10px;
}

.headthread .ordered-form .error {
    margin: 0px;
    display: none;
}

.headthread .ordered-form .validation {
    font-family: Gibson, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    margin-top: 10px;
    text-align: left;
    color: white;
}

.headthread .ordered-form .validation:before {
    background: url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/miscellaneous/sprite-ordered-form.png) no-repeat 0 -73px;
    content: "\a0";
    display: inline-block;
    height: 14px;
    margin: 2px 6px 0 0;
    vertical-align: top;
    width: 18px;
}

.headthread .ordered-form .validation.forum-error {
    margin-top: 0px;
    margin-bottom: 10px;
}

.headthread .ordered-form ol.aaq-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.headthread .ordered-form ol.aaq-actions.multi {
    justify-content: space-between;
}

.headthread .ordered-form ol.aaq-actions.multi li.anonymous {
    display: block;
}

.headthread .ordered-form ol.aaq-actions li.submit span.new-primary {
    padding: 0 20px;
    color: #FFFFFF;
}

.headthread .ordered-form ol.aaq-actions li.anonymous {
    display: none;
}

.headthread .ordered-form ol.aaq-actions li.anonymous label {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.headthread .ordered-form ol.aaq-actions li.anonymous label span {
    margin-left: 10px;
    margin-bottom: 2px;
    color: white;
    white-space: nowrap;
}

.headthread .ordered-form ol.aaq-actions li.anonymous label span:before {
    background-color: #fff;
    border: 1px solid #b4b4b4;
    box-sizing: content-box;
    content: "\a0";
    display: inline-block;
    height: 20px;
    position: absolute;
    top: -2px;
    left: 0px;
    width: 20px;
}

.headthread .ordered-form ol.aaq-actions li.anonymous label input:checked+span:after {
    background: url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/miscellaneous/sprite-ordered-form.png) no-repeat;
    background-position: 0 -44px;
    content: "\a0";
    height: 15px;
    pointer-events: none;
    position: absolute;
    top: 3px;
    left: 0px;
    width: 22px;
}

@media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
    
    .headthread .ordered-form ol.aaq-actions li.anonymous label input:checked+span:after {
        background-image: url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/miscellaneous/sprite-ordered-form@2x.png);
        background-size: 29px 190px;
    }

}

.headthread .ordered-form li.forum {
    text-align: left;
    margin-top: 10px;
}

.headthread .ordered-form li.forum > div.chosen-forum {
    border-bottom: 1px dotted white;
}

.headthread .ordered-form li.forum > div.chosen-forum div.relevant-forums {
    display: none;
    margin-bottom: 10px;
}

.headthread .ordered-form li.forum > div.chosen-forum div.relevant-forums > div.forums {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
}

.headthread .ordered-form li.forum > div.chosen-forum div.relevant-forums > div.forums > div.action-forum-block.glyph {
    font-weight: bold;
    border: 1px solid white;
    padding: 5px 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 15px;
    display: inline-flex;
}

.headthread .ordered-form li.forum > div.chosen-forum div.relevant-forums > div.forums > div.action-forum-block.glyph:before {
    color: white;
    margin-right: 5px;
    align-self: center;
}

.headthread .ordered-form li.forum > div.chosen-forum div.alt-forum-list,
.headthread .ordered-form li.forum > div.chosen-forum div.relevant-forums div.action-less-forums {
    display: none;
}

.headthread .ordered-form li.forum > div.chosen-forum div.relevant-forums div.action-more-forums, 
.headthread .ordered-form li.forum > div.chosen-forum div.relevant-forums div.action-less-forums {
    text-align: center;
    cursor: pointer;
}

.headthread .ordered-form li.forum > div.chosen-forum > div.selected {
    display: flex;
    flex-direction: column;
}

.headthread .ordered-form li.forum > div.chosen-forum > div.selected span.glyph {
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 10px;
}

.headthread .ordered-form li.forum > div.chosen-forum > div.selected span.glyph:before {
    color: white;
    margin-right: 5px;
    font-size: 20px;
}

.headthread .ordered-form li.forum > div.chosen-forum > div.selected span.glyph.suggested {
    font-size: 16px;
    font-weight: bold;
}

.headthread .ordered-form li.forum > div.chosen-forum div.alt-forum-list {
    border-top: 1px dotted white;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 5px;
    flex-direction: column;
}

.headthread .ordered-form li.forum > div.chosen-forum div.alt-forum-list .glyph {
    margin-top: 5px;
    cursor: pointer;
    width: 100%;
    display: inline-flex;
}

.headthread .ordered-form li.forum > div.chosen-forum div.alt-forum-list .glyph.selected {
    font-weight: bold;
}

.headthread .ordered-form li.forum > div.chosen-forum div.alt-forum-list .glyph:before {
    color: white;
    width: 23px;
    align-self: center;
}

.headthread .ordered-form li.forum div.action-changeforum {
    border-top: 1px dotted white;
    padding: 5px 0px;
    display: none;
    cursor: pointer;
}

.headthread .ordered-form li.forum div.action-changeforum:after {
    margin-left: 5px;
}


@media (min-width: 481px) {
    .headthread .headthread-title {
        text-align: center;
    }

    .headthread .headthread-content {
        margin: 0 auto;
    }
    
    .headthread .ordered-form li.forum > div.chosen-forum div.alt-forum-list {
        flex-direction: row;
    }

    .headthread .ordered-form li.forum > div.chosen-forum div.alt-forum-list > div {
        width: 50%;
    }
}

/* Showthread 2 specific */
#aaqHolder > .headthread {
    display: block;
    background-color: transparent;
}

#aaqHolder > .headthread fieldset {
    padding: 0px;
    border: none;
}

#aaqHolder > .headthread .ordered-form > ol {
    list-style: none;
    margin-left: 0px;
}

#aaqHolder > .headthread .ordered-form {
    font-size: 14px;
}

#aaqHolder > .headthread .ordered-form input,
#aaqHolder > .headthread .ordered-form textarea {
    padding: 10px;
    width: 100%;
    border: 1px solid #b2b2b2;
    color: #444444;
}

#aaqHolder > .headthread .ordered-form ol.aaq-actions {
    margin-top: 10px;
}

#aaqHolder > .headthread .ordered-form .arrow-down:after,
#aaqHolder > .headthread .ordered-form .arrow-up:after {
    padding: 0px;
}

#aaqHolder > .headthread .ordered-form ol.aaq-actions li.anonymous label span {
    margin-left: 30px;
}

#aaqHolder > .headthread .ordered-form .disabled {
    opacity: 0.5;
}
