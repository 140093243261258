/**
* File containing learning search related CSS.
* @author A TSR Developer
* @device all
*/


.lsearch,
.lsearch fieldset,
.lsearch input,
.lsearch label,
.lsearch select {
    font-family: Arial, Geneva, Lucida, sans-serif !important;
}

.lsearch {
    background-color: #93c762;
    font-size: 13px;
    padding: 10px;
}

.lsearch label {
    color: white;
    float: left;
    font-size: 1.231em;
    font-weight: bold;
    line-height: 34px;
    margin: 0 20px 0 10px;
}

.lsearch fieldset {
    border: 0;
    float: none;
    margin: 0;
    padding: 0;
    width: auto;
}

.lsearch .fieldset {
    border: 0;
    font-size: 1em;
    float: left;
    margin: 0;
    padding: 0;
    position: relative;
}

.lsearch .fieldset input {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/icons/search-icon.png") no-repeat 239px center white !important;
    border: 1px solid #abadb3 !important;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    color: #1a1a1a;
    font-size: 0.923em;
    height: 34px;
    margin: 0;
    padding: 0px 42px 0px 6px;
    position: relative;
    width: 227px;
    z-index: 95;
}


.lsearch .fieldset input.show-background {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/icons/search-icon.png") no-repeat 239px center white !important;
}

.lsearch .fieldset .subfields {
    background-color: #7bb543;
    display: none;
    position: absolute;
        top: -10px;
        left: -10px;
    padding: 54px 10px 10px;
    width: 100%;
    z-index: 94;
}

.lsearch .fieldset .subfields button,
.lsearch .fieldset .subfields select {
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}

.lsearch .fieldset .subfields select {
    border: 1px solid #abadb3 !important;
    color: #444;
    font-size: 1em;
    height: 22px;
    margin: 0 0 4px;
    width: 100%;
}

.lsearch .fieldset .subfields button {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/learning/gr-next.png") no-repeat right center #f37953;
    border: 1px solid #d13d0f;
    -moz-box-shadow: 1px 1px 2px #f69e83;
    -webkit-box-shadow: 1px 1px 2px #f69e83;
    box-shadow: 1px 1px 2px #f69e83;
    color: white;
    float: right;
    font-size: 1.077em;
    font-weight: bold;
    line-height: 18px;
    margin: 10px 0 0;
    padding: 4px 25px 4px 9px;
    text-shadow: 1px 1px 0 #d13d0f;
    cursor: pointer;
}
