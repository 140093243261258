/**
* CSS rules mostly for the old the search dropdown. Seems to be mostly irrelevant now, though a few rules look to still be in
* use.
* @author A TSR Developer
* @device all
*/

.dropdown,
.dropdown .value {
    cursor: default;
}

.dropdown {
    padding: 0 5px;
    position: relative;
}

.dropdown select {
    background: none;
    border: none !important;
    color: #666;
    filter: alpha(opacity = 0);
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    margin: 0;
    opacity: 0;
    position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
}

.lte7 .dropdown select,
.lte8 .dropdown select:focus {
    width: auto;
}

.nojs .dropdown select {
    background-color: #599f26;
    color: white;
    filter: alpha(opacity = 100);
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    font-weight: bold;
    opacity: 1;
}

.nojs .dropdown select option {
    color: #666;
    font-weight: normal;
}

.dropdown .value {
    color: white;
    font-weight: bold;
    padding: 0;
}

.nojs .dropdown .value {
    visibility: hidden;
}

.dropdown-js {
    display: none;
    pointer-events: none;
    position: absolute;
        top: -3px;
        left: -3px;
    z-index: 1000;
}

.dropdown-js .value,
.dropdown-js ul {
    background: white;
    border: 1px solid #d7d7d7;
    pointer-events: auto;
}

.dropdown-js .value {
    border-bottom-width: 0;
    color: #599f26;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: -1px;
    padding: 2px 7px 5px;
    position: relative;
}

.dropdown-js .value:before {
    -moz-box-shadow: 0 1px 9px -1px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 1px 9px -1px rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 9px -1px rgba(0, 0, 0, 0.3);
    content: "\a0";
    height: 100%;
    position: absolute;
        top: 0;
        left: 0;
    width: 100%;
    z-index: -1;
}

.dropdown-js ul {
    -moz-border-radius: 0 5px 5px 5px;
    -webkit-border-radius: 0 5px 5px 5px;
    border-radius: 0 5px 5px 5px;
    padding: 5px;
}

.dropdown-js ul li {
    border-top: 1px dotted #dedede;
    cursor: pointer;
}

.dropdown-js ul li:first-child {
    border-top: none;
}

.dropdown-js ul li.highlight {
    border-top: none;
}

.dropdown-js ul li a,
.dropdown-js ul li span {
    border: none;
    color: #369;
    display: block;
    line-height: 26px;
    margin: 0;
    padding: 0 10px;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    white-space: nowrap;
}

.dropdown-js ul li:hover a,
.dropdown-js ul li:hover span {
    background-color: #edede6;
    color: #666;
}

.dropdown-js ul li.highlight > a {
    background: #ffa400;
    color: white;
}

.dropdown-js ul li.highlight > a:hover {
    background: #ff7c4d;
}
