/**
* CSS relating to the "ask a question" popup, amougnst possibly othrts
* @author A TSR Developer
* @device all
*/

/* Ordered form - sprites */

.progress-steps li,
.ordered-form .icon,
.ordered-form li.errors:before,
.ordered-form .select:before,
.ordered-form .checkbox input:checked + span:after {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/miscellaneous/sprite-ordered-form.png") no-repeat;
}

@media (-webkit-min-device-pixel-ratio: 1.25),
       (min-resolution: 120dpi) {
    .progress-steps li,
    .ordered-form .icon,
    .ordered-form li.errors:before,
    .ordered-form .select:before,
    .ordered-form .checkbox input:checked + span:after {
        background-image: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/miscellaneous/sprite-ordered-form@2x.png");
        background-size: 29px 190px;
    }
}

/* Ordered form - font sizes */

/* Progress steps */

.progress-steps {
    color: #808080;
    font-size: 1.167em;
    line-height: 22px;
    margin: 16px 0;
}

.progress-steps li {
    background-position: 0 -22px;
    display: inline-block;
    margin-left: 20px;
    padding-left: 30px;
}

.progress-steps li:first-child {
    margin-left: 0;
}

.progress-steps li.complete {
    background-position: 0 0;
    color: #599f26;
}

/* Ordered form */

.ordered-form {
    border: none;
    font-size: 1.167em;
    padding: 0;
}

.ordered-form ol > li,
.ordered-form ol + ol > li:first-child {
    margin-top: 16px;
}

.ordered-form ol > li:first-child {
    margin-top: 0;
}

.ordered-form fieldset,
fieldset.ordered-form {
    border: none;
    border-radius: 0;
    padding: 0;
    position: relative;
}

.ordered-form label {
    display: block;
    position: relative;
}

.ordered-form label span {
    display: block;
    color: #333;
    margin-bottom: 8px;
}

.ordered-form legend {
    color: #505050;
    font-weight: bold;
    padding: 0;
    margin-bottom: 8px;
}

.ordered-form .form-mini {
    width: 100px;
}

.ordered-form .form-short {
    width: 150px;
}

.ordered-form .form-medium-short {
    width: 262px;
}

.ordered-form .form-medium {
    width: 300px;
}

.ordered-form .form-auto li {
    float: left;
    clear: left;
}

.ordered-form .input-text {
    background: white;
    border: 1px solid #b2b2b2 !important;
    border-radius: 0;
    box-sizing: border-box !important;
    color: #444;
    display: block;
    font-size: 1em;
    height: auto;
    margin: 0;
    padding: 10px;
    transition: all 0.2s;
    width: 100%;
}

.ordered-form .input-text:focus,
.ordered-form .select.focus,
.ordered-form .checkbox input:focus + span:before {
    background-color: #f5f5f5 !important;
    border-color: #999 !important;
    outline: none;
}

.ordered-form .input-text.redbox,
.ordered-form .input-text.invalid,
.ordered-form .select.invalid {
    border-color: #db8a8f !important;
}

.ordered-form .input-text:invalid {
    box-shadow: none;
}

.ordered-form .input-text.plain {
    background: none !important;
    border-color: #b2b2b2 !important;
    padding: 4px;
}

.ordered-form .icon {
    display: none;
    height: 14px;
    margin: 0 -25px 0 0;
    position: absolute;
        bottom: 12px;
        right: 0;
}

.ordered-form .input-text.greenbox + .icon,
.ordered-form .input-text.valid + .icon,
.ordered-form .select.valid ~ .icon {
    background-position: 0 -59px;
    display: block;
    width: 18px;
}

.ordered-form .select.unverified ~ .icon {
    display: none;
}

.ordered-form .input-text.redbox + .icon,
.ordered-form .input-text.invalid + .icon,
.ordered-form .select.invalid ~ .icon {
    background-position: 0 -73px;
    display: block;
    width: 20px;
}

#page_home .register-home-form .icon,
#page_home .register-home-form .ordered-form .input-text.redbox + .icon,
#page_home .register-home-form .ordered-form .input-text.invalid + .icon,
#page_home .register-home-form .ordered-form .select.invalid ~ .icon,
.register-other-form .icon,
.register-other-form .ordered-form .input-text.redbox + .icon,
.register-other-form .ordered-form .input-text.invalid + .icon,
.register-other-form .ordered-form .select.invalid ~ .icon
{
	left: -21px;
	position: absolute;
}

.register-home-form .ordered-form .radiofields .error-message {
    clear: both;
}

#page_home .register-home-form a.forgotten_password,
.register-other-form a.forgotten_password {
	display: none;
}

#forgotten-password_link {
    display: none;
}

#page_home .register-home-form li .error-message,
.register-other-form li .error-message
{
    margin-bottom: -5px;
}

#page_home .register-home-form li .error-message.age-not-met,
.register-other-form li .error-message.age-not-met
{
    margin-bottom: 0px;
}

.ordered-form .submit {
    font-size: 0.929em;
}

/* Ordered form - fieldset group */

.fieldset-group {
    border-bottom: 2px solid #7B63DB;
    margin-top: 16px;
}

.fieldset-group > .ctl-bar {
    font-weight: normal;
    font-size: 1.25em;
    line-height: 30px;
    margin-bottom: 0;
    padding: 0 8px;
}

.fieldset-group > .fieldset-group-item {
    border-top: 1px dotted #ccc;
    margin: 0 8px;
    padding: 16px 0;
    position: relative;
}

.fieldset-group > .fieldset-group-item:first-child,
.fieldset-group > .ctl-bar:first-child + .fieldset-group-item {
    border-top: 0;
}

.fieldset-group > fieldset {
    margin: 16px 0;
}

#page_editpost .fieldset-group > .ordered-form,
#page_newreply .fieldset-group > .ordered-form,
#page_newthread .fieldset-group > .ordered-form {
	margin: 10px auto;
}

/* Ordered form - inline group */

.ordered-form .inline-group > li,
.ordered-form .inline-group > li > label {
    display: inline-block;
}

.ordered-form .inline-group > li {
    margin: 0 0 0 10px;
}

.ordered-form .inline-group > li:first-child {
    margin-left: 0;
}

/* Ordered form - textarea */

.ordered-form .input-textarea {
    border-radius: 0;
    border: 1px solid #ccc;
    box-sizing: border-box !important;
    font-size: 1em;
    height: 11em; /* 8 lines */
    line-height: 1.214;
    min-height: 4.929em;
    padding: 8px;
    resize: vertical;
    width: 100%;
}

/* Ordered form - drop-downs */

.ordered-form .select {
    background-color: #fff;
    border: 1px solid #b2b2b2;
    color: #444;
    display: block;
    height: 33px;
    line-height: 33px;
    overflow: hidden;
    position: relative;
}

.ordered-form .select:before {
    background-color: #d4d4cd;
    background-position: 8px -110px;
    border-left: 1px solid #acaca7;
    content: "\a0";
    float: right;
    height: 100%;
    width: 30px;
}

.ordered-form .input-text.disabled,
.ordered-form .select.disabled {
    filter: alpha(opacity = 50);
    opacity: 0.5;
}

.ordered-form .select select {
    border: none !important;
    filter: alpha(opacity = 0);
    height: 100%;
    margin: 0;
    opacity: 0;
    position: absolute;
        top: 0;
        left: 0;
    width: 100%;
}

.nojs .ordered-form .select select {
    filter: alpha(opacity = 100);
    opacity: 1;
}

.ordered-form .select .value {
    display: inline;
    margin: 0;
    padding: 0 8px;
}

.ordered-form .select.plain {
    line-height: 25px;
    height: 25px;
}

.ordered-form .select.plain.focus {
    background-color: #fff;
}

.ordered-form .select.plain:before {
    background-position: 6px -113px;
    width: 25px;
}

.ordered-form .datefields .select {
    display: inline-block;
    margin-left: 3.3333337%;
    vertical-align: top;
    width: 30%;
}

.ordered-form .datefields .select.first {
    margin-left: 0;
}

.ordered-form .input-select {
    margin: -5px 0 0;
}

/* Ordered form - highlight box */

.ordered-form .highlight-box {
    border: 1px dotted #b2b2b2;
    background-color: #efefe8;
    padding: 10px;
}

.ordered-form .highlight-box legend {
    color: #333;
    padding: 0;
    position: absolute;
        top: 16px;
}

.ordered-form .highlight-box legend + ol {
    margin-top: 40px;
}

/* Ordered form - checkboxes */

.ordered-form .checkbox label {
    margin-left: 30px;
}

.ordered-form .checkbox input {
    filter: alpha(opacity = 0);
    opacity: 0;
    position: absolute;
        top: 0;
        left: 0;
}

.ordered-form .checkbox input + span {
    line-height: 22px;
    margin-bottom: 0;
}

.ordered-form .checkbox input + span:before {
    background-color: white;
    border: 1px solid #b4b4b4;
    box-sizing: content-box;
    content: "\a0";
    display: inline-block;
    height: 20px;
    position: absolute;
        top: 0;
        left: -30px;
    width: 20px;
}

.ordered-form .checkbox input:checked + span:after {
    background-position: 0 -44px;
    content: "\a0";
    height: 15px;
    pointer-events: none;
    position: absolute;
        top: 3px;
        left: -28px;
    width: 22px;
}

.ordered-form .checkbox.small,
.ordered-form .radio {
    margin-top: 8px !important;
}

.ordered-form ol .checkbox.small:first-child {
    margin-top: 0;
}

.ordered-form .checkbox.small label {
    margin-left: 20px;
}

.ordered-form .checkbox.small input + span {
    line-height: 14px;
}

.ordered-form .checkbox.small input + span:before {
    height: 12px;
    left: -20px;
    width: 12px;
}

.ordered-form .checkbox.small input:checked + span:after {
    background-position: 0 -144px;
    height: 11px;
    left: -19px;
    top: 1px;
    width: 15px;
}

/* Ordered form - radio buttons */

.ordered-form .radio {
    position: relative;
}

.ordered-form .radio input,
.ordered-form .radio span {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
}

/* Ordered form - descriptions and inline errors */

.ordered-form .info,
.ordered-form .error-message,
.ordered-form #dialog_box {
    font-size: 0.857em;
}

.ordered-form .info {
    color: #808080;
    margin-top: 3px;
}

.ordered-form .error-message,
.ordered-form #dialog_box {
    color: #c80d18;
    margin-top: 3px;
}

#page_home .register-home-form .ordered-form .error-message,
.register-other-form .ordered-form .error-message {
	color: #612316;
	font-weight: bold;
    margin-top: 5px;
}

.ordered-form .error-message {
    display: none;
}

.ordered-form .error-message a,
.ordered-form #dialog_box a {
    color: #c80d18;
    text-decoration: underline;
}

.ordered-form .error-message::first-letter,
.ordered-form #dialog_box div::first-letter {
    text-transform: lowercase;
}

.ordered-form #dialog_box .greenbox {
    display: none !important;
}

.ordered-form .description {
    font-size: 0.929em;
    padding: 0;
}

.ordered-form .description + li {
    margin-top: 30px;
}

/* Ordered form - page-level errors */

.ordered-form li.errors,
.ordered-form li.errors a,
.ordered-form li.errors .title {
    color: #c80d18;
}

.ordered-form li.errors {
    background-color: #eeeee6;
    border: 2px solid #e35234;
    border-radius: 1px;
    padding: 16px 20px 16px 50px;
    position: relative;
}

.ordered-form li.errors:before {
    background-position: 0 -87px;
    content: "\a0";
    position: absolute;
        top: 12px;
        left: 12px;
    height: 22px;
    width: 29px;
}

.ordered-form li.errors a {
    text-decoration: underline;
}

.ordered-form li.errors .title {
    font-size: 1.143em;
    font-weight: bold;
}

.ordered-form li.errors .title,
.ordered-form li.errors .subtitle {
    display: block;
    margin-bottom: 10px;
}

.ordered-form li.errors .error-list li {
    list-style: disc outside;
    margin-top: 6px;
}

/* Ordered form - compact style */

.ordered-form .form-medium.compact {
    width: 520px;
}

.ordered-form .compact li {
    position: relative;
}

.ordered-form .compact label,
.ordered-form .compact .info,
.ordered-form .compact .error-message,
.ordered-form .compact #dialog_box,
.ordered-form .compact > li > fieldset {
    margin-left: 150px;
}

.ordered-form .compact label > span {
    line-height: 39px;
    position: absolute;
        top: 0;
        left: -166px;
    text-align: right;
    width: 150px;
}

.ordered-form .compact label > .fixed-value {
    line-height: 39px;
}

.ordered-form .compact .checkbox label {
    margin-left: 180px;
}

.ordered-form .compact .checkbox label > span {
    font-size: 0.939em;
    line-height: 22px;
    position: static;
    text-align: left;
    width: auto;
}

.ordered-form .compact label .icon {
    left: auto;
    top: auto;
}

.ordered-form .compact .error-message,
.ordered-form .compact #dialog_box {
    background-color: white;
    position: absolute;
        right: 0;
        left: 0;
}

/* Ordered form - newpost style */

.ordered-form.newpost-form .input-text,
.ordered-form.newpost-form .input-textarea,
.ordered-form.newpost-form .select,
.ordered-form.newpost-form .checkbox input + span:before {
    border-color: #7B63DB !important;
}

.ordered-form.newpost-form .select:before {
    background-color: #dbecf7;
    background-position: 8px -156px;
    border-left-color: #7B63DB;
}



.ordered-form.newpost-form .select.plain:before {
    background-position: 6px -159px;
    background-color: #F8F5FF;
}

.ordered-form.newpost-form .input-text:focus,
.ordered-form.newpost-form .input-text.plain:focus,
.ordered-form.newpost-form .select.focus,
.ordered-form.newpost-form .select.plain.focus,
.ordered-form.newpost-form .checkbox input:focus + span:before {
    background-color: #f7fcff !important;
}

/* Responsive tweaks */
@media screen and (max-width: 440px) {
    .ordered-form .icon {
        display: none;
    }
}
