/**
* AD block wrapper CSS. Contains some desktop related queries.
* @author A TSR Developer
* @device all
*/

.advert-block {
    background-color: white;
    margin-left: auto;
    margin-right: auto;
}

.advert-block.spot, .advert-block.acmt {
    outline: none;
}

.advert-block.acmt { margin-left: 0; margin-right: 0; }

.advert-block.advert-mobile {
    display: none;
}

.advert-block.advert-standard {
    display: block;
}

#page_home #secondary_content #ad_sponsored_placeholder,
#page_home #widgets-secondary .advert-block.advert-mobile,
#page_home #widgets-secondary .advert-block.advert-standard,
#page_home #primary_content .advert-block.advert-standard {
    display: block !important;
}

/* Mobile styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    .advert-block.advert-standard {
        display: block;
    }
}

.column.side .advert-block {
    margin-bottom: 16px;
}

.column.side .advert-block.spot {
    margin-bottom: 0px;
    margin-left: 0px;
    border-top: 1px dotted #e4e4e0;
}

.column.side .advert-block.spot:first-child {
    border-top: none;
}

.advert-block .advert-iframe {
    border: 0;
    height: 0;
    width: 100%;
}

.advert-block h2 {
    color: #808080;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 6px;
}

#ad_sponsored_placeholder {
    padding: 0;
    outline: none;
}

#ad_sponsored_placeholder.fixed,
#ad_sponsored_placeholder.fixed-narrow {
    position: fixed;
    top: 52px;
}

#ad_sponsored_placeholder.fixed {
    width: 300px;
}

#ad_sponsored_placeholder.fixed-narrow {
    width:232px
}
#ad_sponsored_placeholder.fixed-bottom {
    position: absolute;
        top: auto !important;
        bottom: 0;
}

.ad-sponsored-container {
    border-top: 2px solid #E2E3E4;
    border-bottom: 2px solid #E2E3E4;
}

#widget-scrolling-discussions.fixed,
#widget-scrolling-discussions.fixed-narrow {
    position: fixed;
    top: 61px;
}

#widget-scrolling-discussions.fixed-bottom {
    position: absolute;
        top: auto;
        bottom: 0;
}

#widget-scrolling-discussions.fixed-with-sl,
#widget-scrolling-discussions.fixed-narrow-with-sl {
    position: fixed;
}

#widget-scrolling-discussions.fixed,
#widget-scrolling-discussions.fixed-with-sl {
    width:300px;
}

#widget-scrolling-discussions.fixed-narrow,
#widget-scrolling-discussions.fixed-narrow-with-sl {
	width:244px;
}

#widget-scrolling-discussions.fixed-bottom-with-sl {
    margin-bottom: 0;
    position: absolute;
        top: auto;
        bottom: 0;
}

@media screen and (max-width: 1536px) {
    .takeover-enabled .column.main .advert-block.lb {
        height: 77px !important;
        overflow: hidden;
        width: 620px !important;
    }
    .takeover-enabled .column.main .advert-block.lb iframe {
        transform: scale(0.85165);
        transform-origin: left top;
    }

    .takeover-enabled .android .column.main .advert-block.lb {
        display: none;
    }
}


@media screen and (max-width: 960px) {
    #ad_sponsored_placeholder.fixed,
    #ad_sponsored_placeholder.fixed-narrow {
        position: static;
    }

    #widget-scrolling-discussions.fixed-with-sl,
    #widget-scrolling-discussions.fixed-narrow-with-sl,
    #widget-scrolling-discussions.fixed,
    #widget-scrolling-discussions.fixed-narrow {
        position: static;
    }
}

/* Site takeover */

.takeover-enabled {
    overflow-x: hidden;
}

.takeover-enabled .takeover-wrapper {
    display: table;
    margin: -5px auto 0;
}

.takeover.panel-left,
.takeover.panel-right {
    display: none;
}

.takeover-enabled .takeover.panel-left,
.takeover-enabled .takeover.panel-right,
.takeover-enabled .takeover.panel-main {
    display: table-cell;
    padding-top: 5px;
    vertical-align: top;
    position: relative;
}

.takeover.panel-left,
.takeover.panel-right,
.takeover > .panel,
.takeover > .panel > .inner-panel {
    height: 902px;
    width: 220px;
}

.takeover.panel-main {
    background-color: #f3f3f3;
}

.takeover > .panel {
    padding: 0;
    position: absolute;
    top: 0;
}

.takeover.panel-left > .panel {
    right: 0;
}

.takeover.panel-left .panel {
    background: no-repeat right top #edede6;
}

.takeover.panel-right .panel {
    background: no-repeat left top #edede6;
}

.takeover > .panel > .inner-panel {
    position: fixed;
    display: block;
}

#page-ad-top-container {
    margin: 0;
    max-width: 100% !important;
    width: 100% !important;
    padding: 15px 0 50px 0;
}

#page-ad-top-container .advert-block {
    position: absolute;
    z-index: 99;
    left: 50%;
    ms-transform: translateX(-50%);
    webkit-transform: translateX(-50%);
    moz-transform: translateX(-50%);
    transform: translateX(-50%);
}

#page-ad-top-container #page-ad-top {
    padding-top: 4px;
    height: 0px;
    margin: -15px auto 0 auto;
    position: relative;
    padding-top: 19px;
    background-color: #ffffff;
    max-width: 1300px;
    min-width: 980px;
    width: auto;
}

#page_showthread #page-ad-top-container #page-ad-top {
    max-width: 1400px;
}

#page-ad-top-container #page-ad-top .advert-block {
    height: 250px;
    width: 970px;
    visibility: hidden;
}

#page_home.page #page-ad-top-container #page-ad-top,
#page_gsa-search.page #page-ad-top-container #page-ad-top,
#page_vbcms.page #page-ad-top-container #page-ad-top,
#page_connect.page #page-ad-top-container #page-ad-top,
#page_opendaycal.page #page-ad-top-container #page-ad-top,
#page_uniatoz.page #page-ad-top-container #page-ad-top,
#page_unichoice.page #page-ad-top-container #page-ad-top,
#page_login.page #page-ad-top-container #page-ad-top,
#page_register.page #page-ad-top-container #page-ad-top {
    max-width: 980px;
}


@media screen and (max-width: 980px) {
    /* Variable width pages become fixed width */

    #page-ad-top-container #page-ad-top {
        width: 980px !important;
        max-width: 980px !important;
    }
}

#page-ad-top-container #ad-underlay {
    position: absolute;
    z-index: 99;
    width: 100%;
    left: 50%;
    ms-transform: translateX(-50%);
    webkit-transform: translateX(-50%);
    moz-transform: translateX(-50%);
    transform: translateX(-50%);
    top: 0;
    height: 285px;
    background-color: #f3f3f3;
    display: none;
}

.takeover-enabled #page-ad-top-container #ad-underlay {
    width: 980px;
}

/* Announcements */

#announcements {
    border-bottom: 1px solid #006da8;
}

#announcements td {
    border-top: none;
    border-bottom: 1px dotted #ccc;
    padding: 5px 0;
}

#announcements tr:last-child td {
    border-bottom: none;
}

#announcements td.posted {
    display: none;
}

.announcement:first-child td {
    border-top: none;
}

/* Sticky bottom */

.sticky-bottom {
    background: none;
    outline: none;
    position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
    z-index: 8997;
}

/* TSR Alert Unit - Sticky bottom and mobile */
.sticky-bottom-showthread-mobile {
    background-color: #E4E4E4;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 480;
    text-align: center;
    padding: 6px 0px;
    width: 100% !important;
    height: auto !important;
}
