/**
* CSS rules relating to the CMS editing controls.
* @author A TSR Developer
* @device all
*/

/* TSR CMS controls */

.cms-button {
    background-color: #e54d45;
    background-image: linear-gradient(to bottom, #e54d45, #e58e8a);
    border: 1px solid #ad5505;
    border-radius: 2px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    color: white;
    cursor: pointer;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e54d45', endColorstr='#e58e8a', GradientType=0);
    float: left;
    font-size: 15px;
    font-weight: bold;
    margin: 5px;
    padding: 4px;
    transition: all 0.3s;
}

.cms-button:after {
    vertical-align: top;
}

.cms-button:empty {
    margin: 0 0 5px 5px;
    padding: 2px;
    opacity: 0.5;
}

.cms-button:hover {
    color: white;
    opacity: 1;
    text-decoration: none;
}

.cms-button.small {
    transform: scale(0.75);
}

.cms-button.add:after {
    content: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/tsrcms/add.png");
}

.cms-button.contract:after {
    content: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/tsrcms/contract.png");
}

.cms-button.copy:after {
    content: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/tsrcms/copy.png");
}

.cms-button.edit:after {
    content: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/tsrcms/edit.png");
}

.cms-button.expand:after {
    content: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/tsrcms/expand.png");
}

.cms-button.move:after {
    content: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/tsrcms/move.png");
}

.cms-button.remove:after {
    content: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/tsrcms/remove.png");
}

button.cms-button {
    display: block;
    float: none;
}

.cms-button.move {
    cursor: move;
}

/* this is the new brand buttons */

.new-primary,
.interact.arrow-right.tall,
#filter-open-days-submit > button {
    display: inline-flex !important;
    align-items: center !important;
    background-color: #99FFA8 !important;
    height: 37px;
    color: #272727 !important;
    font-family: Arial !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    letter-spacing: 0.84px !important;
    border-radius: 8px !important;
    justify-content: center !important;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    background: none;
    border: none !important;
}

.new-primary:hover,
.interact.arrow-right.tall:hover,
#filter-open-days-submit > button:hover {
    text-decoration: none !important;
    background-color: #63CD73 !important;
}

.interact.arrow-right.tall:after,
#filter-open-days-submit > button:after {
    content: '' !important;
}

.new-secondary {
    display: inline-flex !important;
    align-items: center !important;
    background-color: transparent;
    height: 35px;
    color: #FFFFFF !important;
    font-family: Arial !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    letter-spacing: 0.84px !important;
    border-radius: 8px !important;
    border: 1px solid #99FFA8 !important;
    justify-content: center !important;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

.new-secondary:hover {
    text-decoration: none !important;
    background-color: #63CD73 !important;
    color: #272727 !important;
    border: 1px solid #63CD73 !important;
}

.new-purple-primary {
    display: inline-flex !important;
    align-items: center !important;
    background-color: #7B63DB !important;
    height: 37px;
    color: #FFFFFF !important;
    font-family: Arial !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    letter-spacing: 0.84px !important;
    border-radius: 8px !important;
    justify-content: center !important;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

.new-purple-primary:hover {
    text-decoration: none !important;
    background-color: #6652B8 !important;
}

.new-purple-secondary {
    display: inline-flex !important;
    align-items: center !important;
    background-color: transparent;
    height: 35px;
    color: #272727 !important;
    font-family: Arial !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    letter-spacing: 0.84px !important;
    border-radius: 8px !important;
    border: 1px solid #7B63DB !important;
    justify-content: center !important;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

.new-purple-secondary:hover {
    text-decoration: none !important;
    background-color: #6652B8;
    color: #272727 !important;
    border: 1px solid #6652B8 !important;
}

.new-button-link {
    border: none !important;
    background: none;
    padding: 0;
    cursor: pointer;
    text-decoration: none !important;
    color:#7B63DB !important;
    font-family: Arial !important;
    font-size: 14px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.8399999737739563px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

.new-link {
    border: none !important;
    background: none;
    padding: 0;
    cursor: pointer;
    text-decoration: none !important;
    color:#7B63DB !important;
    font-family: Arial !important;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.8399999737739563px;
    cursor: pointer;
}

.new-button-link:hover,
.new-link:hover {
    color: #6652B8 !important;
    text-decoration: underline !important;
}

.new-small-button-link {
    border: none !important;
    background: none;
    padding: 0;
    cursor: pointer;
    text-decoration: none !important;
    color:#7B63DB !important;
    font-family: Arial !important;
    font-size: 14px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.8399999737739563px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

.new-small-button-link:hover {
    color: #6652B8 !important;
    text-decoration: underline !important;
    background-color: transparent !important;
}

.new-small-primary, 
.button.input-button.rounded5,
.button.input-submit.rounded5,
.button.input-reset.rounded5,
.widget-action > a,
#widget-poll .result-footer .link a,
.vote-footer button.votebtn > span,
#page_psbuilder button.plain > span.interact.arrow-left,
#page_psbuilder button.plain > span.interact.arrow-right,
#page_psbuilder .finished-actions a,
#page_vbcms .btn-warning,
#openday_hub,
.openday-list .events > .cta > ul li > a.bubble,
#page_vbcms a.bubble,
#page_connect form#connect button.plain > span,
#page_reports-admin button.plain > span.interact,
#page_group #search_groups input.input-submit,
#page_group a.link-button,
form.penalty-add div.submit button > span,
#page_usernote a#newreplylink_bottom,
#page_usernote a#newreplylink_top,
.ed-modal li.submit > button > span,
.openday-list div.view-more > span.action-viewmore,
#page_forumdisplay .thread-filter form button.plain > span.interact.arrow-right,
#page_vbcms button.plain.threadsubmit > span.bubble.arrow-right,
#page_private ul.post-buttons a,
#page_vbcms form#content-moderation-entity-moderation-form input#edit-submit,
#page_forms form input.smallfont.input-submit.rounded5,
#page_search form.top-search button.plain > span.interact.arrow-right,
#page_search td#filter_by_header a.interact.arrow-right.apply-button {
    display: inline-flex !important;
    align-items: center !important;
    background-color: #99FFA8 !important;
    height: 26px;
    color: #272727 !important;
    font-family: Arial !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    letter-spacing: 0.84px !important;
    border-radius: 8px !important;
    justify-content: center !important;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    border: none !important;
    background: #99FFA8 !important;
    text-transform: unset;
}

.new-small-primary:hover, 
.button.input-button.rounded5:hover,
.button.input-submit.rounded5:hover,
.button.input-reset.rounded5:hover,
.widget-action > a:hover,
#widget-poll .result-footer .link a:hover,
.vote-footer button.votebtn > span:hover,
#page_psbuilder button.plain > span.interact.arrow-left:hover,
#page_psbuilder button.plain > span.interact.arrow-right:hover,
#page_psbuilder .finished-actions a:hover,
#page_vbcms .btn-warning:hover,
#openday_hub:hover,
.openday-list .events > .cta > ul li > a.bubble:hover,
#page_vbcms a.bubble:hover,
#page_connect form#connect button.plain > span:hover,
#page_reports-admin button.plain > span.interact:hover,
#page_group #search_groups input.input-submit:hover,
#page_group a.link-button:hover,
form.penalty-add div.submit button > span:hover,
#page_usernote a#newreplylink_bottom:hover,
#page_usernote a#newreplylink_top:hover,
.ed-modal li.submit > button > span:hover,
.openday-list div.view-more > span.action-viewmore:hover,
#page_forumdisplay .thread-filter form button.plain > span.interact.arrow-right:hover,
#page_vbcms button.plain.threadsubmit > span.bubble.arrow-right:hover,
#page_private ul.post-buttons a:hover,
#page_vbcms form#content-moderation-entity-moderation-form input#edit-submit:hover,
#page_forms form input.smallfont.input-submit.rounded5:hover,
#page_search form.top-search button.plain > span.interact.arrow-right:hover,
#page_search td#filter_by_header a.interact.arrow-right.apply-button:hover {
    text-decoration: none !important;
    background-color: #63CD73 !important;
}

.widget-action > a:after,
.widget-action > a > span:before,
.vote-footer button.votebtn > span:after,
#page_psbuilder button.plain > span.interact.arrow-left:after,
#page_psbuilder button.plain > span.interact.arrow-right:after,
#page_psbuilder .finished-actions a:after,
#page_vbcms .btn-warning:after,
.glyph.arrow-right:before,
#openday_hub:after,
.openday-list .events > .cta > ul li > a.bubble:after,
#page_vbcms a.bubble:after,
#page_connect form#connect button.plain > span:after,
#page_reports-admin button.plain > span.interact:after,
form.penalty-add div.submit button > span:after,
.ed-modal li.submit > button > span:after,
.openday-list div.view-more > span.action-viewmore:after,
#page_forumdisplay .thread-filter form button.plain > span.interact.arrow-right:after,
#mod-menu ul.control-panel a.bubble.secondary.light.arrow-right.no-lowercase:after,
#page_vbcms button.plain.threadsubmit > span.bubble.arrow-right:after,
#page_search form.top-search button.plain > span.interact.arrow-right:after,
#page_search form.top-search button.plain > span.interact.arrow-right:before,
#page_search td#filter_by_header a.interact.arrow-right.apply-button:after,
#page_search td#filter_by_header a.interact.arrow-right.apply-button:before {
    content: none !important;
}

#page_connect form#connect button.plain > span {
    margin-top: 10px;
}

.new-small-secondary {
    display: inline-flex !important;
    align-items: center !important;
    background-color: transparent;
    height: 26px;
    color: #FFFFFF !important;
    font-family: Arial !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    letter-spacing: 0.84px !important;
    border-radius: 8px !important;
    border: 1px solid #99FFA8 !important;
    justify-content: center !important;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

.new-small-secondary:hover {
    text-decoration: none !important;
    background-color: #63CD73 !important;
    color: #272727 !important;
    border: 1px solid #63CD73 !important;
}

.new-extra-small-secondary {
    display: inline-flex !important;
    align-items: center !important;
    background-color: transparent;
    height: 20px;
    color: #FFFFFF !important;
    font-family: Arial !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 20px !important;
    letter-spacing: 0.84px !important;
    border-radius: 8px !important;
    border: 1px solid #99FFA8 !important;
    justify-content: center !important;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
}

.new-extra-small-secondary:hover {
    text-decoration: none !important;
    background-color: #63CD73 !important;
    color: #272727 !important;
    border: 1px solid #63CD73 !important;
}

.new-extra-small-primary {
    display: inline-flex !important;
    align-items: center !important;
    background-color: #99FFA8 !important;
    height: 20px;
    color: #272727 !important;
    font-family: Arial !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 20px !important;
    letter-spacing: 0.84px !important;
    border-radius: 8px !important;
    border: none !important;
    justify-content: center !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    cursor: pointer;
}

.new-extra-small-primary:hover {
    text-decoration: none !important;
    background-color: #63CD73 !important;
}

.new-small-secondary-grey {
    display: inline-flex !important;
    align-items: center !important;
    background-color: transparent;
    height: 26px;
    color: #272727 !important;
    font-family: Arial !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    letter-spacing: 0.84px !important;
    border-radius: 8px !important;
    border: 1px solid #99FFA8 !important;
    justify-content: center !important;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

.new-small-secondary-grey:hover {
    text-decoration: none !important;
    background-color: #63CD73 !important;
    color: #272727 !important;
    border: 1px solid #63CD73 !important;
}


.new-extra-small-purple-primary,
#page_vbcms a.rep-follow {
    display: inline-flex !important;
    align-items: center !important;
    background-color: #7B63DB !important;
    height: 20px;
    color: #FFFFFF !important;
    font-family: Arial !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 20px !important;
    letter-spacing: 0.84px !important;
    border-radius: 8px !important;
    border: none !important;
    justify-content: center !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    cursor: pointer;
}

.new-extra-small-purple-primary:hover,
#page_vbcms a.rep-follow:hover {
    text-decoration: none !important;
    background-color: #6652B8 !important;
}

#page_private span.action-follow {
    padding: 5px;
    margin-left: 0px;
}

.new-small-purple-primary,
#mod-menu ul.control-panel a.bubble.secondary.light.arrow-right.no-lowercase,
span.action-follow {
    display: inline-flex !important;
    align-items: center !important;
    background-color: #7B63DB !important;
    height: 26px !important;
    color: #FFFFFF !important;
    font-family: Arial !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 26px !important;
    letter-spacing: 0.0084em !important;
    border-radius: 8px !important;
    justify-content: center !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    cursor: pointer;
    border: none !important;
}

.new-small-purple-primary:hover,
#mod-menu ul.control-panel a.bubble.secondary.light.arrow-right.no-lowercase:hover,
span.action-follow:hover {
    text-decoration: none !important;
    background-color: #6652B8 !important;
}

.new-small-purple-secondary {
    display: inline-flex !important;
    align-items: center !important;
    height: 26px;
    color: #272727 !important;
    font-family: Arial !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 26px !important;
    letter-spacing: 0.0084em !important;
    border-radius: 8px !important;
    justify-content: center !important;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    border: 1px solid #7B63DB;
}

.new-small-purple-secondary:hover {
    text-decoration: none !important;
    color: #FFFFFF !important;
    background-color: #6652B8 !important;
    border: 1px solid #6652B8;
}

input#vmessage_inlinego, button#inlinego {
    display: inline-flex !important;
    align-items: center !important;
    background-color: #99FFA8 !important;
    background: #99FFA8 !important;
    height: 24px;
    color: #272727 !important;
    font-family: Arial !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 21px !important;
    letter-spacing: 0.84px !important;
    border-radius: 8px !important;
    justify-content: center !important;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

input#vmessage_inlinego:hover, button#inlinego:hover {
    text-decoration: none !important;
    background-color: #63CD73 !important;
}

span#watch_button > a.watch:before,
span#watch_button > a:hover.watched:before {
    content: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/base/watch-star.svg");
}

span#watch_button > a.watch > span.text,
span#watch_button > a:hover.watched > span.text {
    color: #FFFFFF;
    margin-left: 5px;
    margin-top: 2px;
}

span#watch_button > a.watched:before {
    content: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/base/watched-star.svg");
}

span#watch_button > a.watched > span.text {
    color: #7B63DB;
    margin-left: 5px;
    margin-top: 2px;
}

#page_vbcms .bg--section-heading--dark,
#page_vbcms .nova-widget .heading {
    background-color: #7B63DB !important;
}

.connect-top-uni a,
.connect-top-course a,
.connect-recent a,
.connect-table-data a,
#page_vbcms .centered-list ul li a,
#page_vbcms .gizmo-openday,
#page_vbcms .block.block-multinav-links.block-multinav-links-as-list .navlink-col .block-navlink {
    color: #7B63DB !important;
}

.connect-top-unis a:hover,
.connect-top-course a:hover,
.connect-recent a:hover,
.connect-table-data a:hover,
#page_vbcms .centered-list ul li a:hover {
    color: #6652B8 !important;
}

#page_vbcms a.rep-follow > span:first-child,
#page_private span.action-follow > span:first-child,
#page_usernote a#newreplylink_top > span,
#page_usernote li.follow > span > span.forum-tool {
    display: none;
}

.ed-toolbar span.interact.secondary.light {
    align-items: center !important;
    background-color: #7B63DB !important;
    height: 26px;
    color: #FFFFFF !important;
    font-family: Arial !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    letter-spacing: 0.84px !important;
    border-radius: 8px !important;
    justify-content: center !important;
    padding-left: 10px;
    padding-right: 10px;
    padding-top:5px;
    top: -5px;
    cursor: pointer;
}

.ed-toolbar span.interact.secondary.light:hover {
    text-decoration: none !important;
    background-color: #6652B8 !important;
}

#page_usernote a#newreplylink_top,
#page_usernote a#newreplylink_bottom {
    margin: 10px 0;
}

#page_vbcms .block.block-heading-color-purple .block-coloured-heading,
#page_vbcms .block.block-jump-list.block-heading-color-purple .block-jump-list-background .select2 .select2-selection__arrow,
#page_vbcms .block.block-image .block-image-image .block-image-heading,
#page_vbcms .entity-moderation-form li:first-child,
#page_vbcms .block.block-multinav-links.block-multinav-links-as-list .navlink-col .block-navlink .arrow:before, 
#page_vbcms .block.block-multinav-links.block-multinav-links-as-list .navlink-col .block-navlink .arrow:after,
#page_vbcms .block.block-heading-color-default .block-coloured-heading,
#page_vbcms .block.block-jump-list.block-heading-color-default .block-jump-list-background .select2 .select2-selection__arrow {
    background-color: #7B63DB !important;
}

#page_vbcms .block.block-image .block-image-image .block-image-heading,
#page_vbcms blockquote:before, 
#page_vbcms blockquote:after {
    background: none;
}

#page_vbcms .block.block-heading-color-purple .block-coloured-heading:before,
#page_vbcms .block.block-heading-color-purple .block-coloured-heading:after,
#page_vbcms .block.block-heading-color-default .block-coloured-heading:after, 
#page_vbcms .block.block-heading-color-default .block-coloured-heading:before {
    background-color: transparent !important;
}

.header-plain-bar .plain-logo img {
    width:133px !important;
}

#page_vbcms #notificationList li.badge {
    display: inherit !important;
    background-color: transparent !important;
    border-radius: 0px;
    text-align: left;
}
