/**
* Contains rules for modal popup content inc. registration.
* @author A TSR Developer
* @device mixed
*/

#modal-container {
    background-color: #666;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-sizing: initial;
    z-index: 10000;
}

#modal-data {
    background-color: white;
    border: 1px solid #8a8a8a;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    height: 480px;
    margin: -240px 0 0 -320px;
    position: absolute;
    border-radius: 7px;
    top: 50%;
    left: 50%;
    width: 640px;
    overflow: hidden !important;
    box-sizing: initial;
}

#modal-container-ipad {
    background-color: #666;
    background-color: rgba(102, 102, 102, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0px;
    paddinh: 0px;
    z-index: 10000;
}

#modal-data-ipad {
    background-color: white;
    border: 1px solid #8a8a8a;
    box-shadow: 2px 2px 3px -1px rgba(0, 0, 0, 0.5);
    height: 480px;
    position: absolute;
    width: 640px;
}

#modal-data-ipad > .modal-iframe,
#modal-data > .modal-iframe {
    border: 0;
    height: 100%;
    width: 100%;
}

.simplemodal-close,
#modal-close {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/button/close2.png") no-repeat;
    cursor: pointer;
    height: 15px;
    position: absolute;
    right: 20px;
    top: 13px;
    width: 15px;
}

.modal-inner-close {
    color: #3bb5e0;
    cursor: pointer;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    width: 30px;
}

#modal-data.modal-login-popup {
    background: none;
    border: 0;
    box-shadow: none;
}

#modal-data.modal-login-popup iframe {
    overflow: hidden;
}

.modal {
    background: white;
}

.modal .mobileModalTitle {
    background-color: #4492c6;
    color: white;
    font-size: 24px;
    line-height: 1.25;
    padding: 16px 20px;
}

.modal .mobileModalTitle > h1 {
    font-size: 1em;
    font-weight: normal;
    margin: 0;
}

.modal .modal-subtitle {
    color: #5d88b9;
    font-weight: normal;
    font-size: 19px;
    line-height: 1;
    margin: 0 0 16px;
}

.modal .mobileModalTitle .modal-inner-close {
    color: white;
    float: right;
    margin: -16px -20px 0 0;
}

.modal .modal-subtitle {
}

.modal > .modal-content {
    padding: 16px 20px;
}

@media (-webkit-min-device-pixel-ratio: 1.25),
(min-resolution: 120dpi) {
    .simplemodal-close,
    #modal-close {
        background-image: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/button/close2@2x.png");
        background-size: 15px 15px;
    }
}

#modal-background-mobile {
    position: absolute;
    background-color: #666;
    background-color: rgba(102, 102, 102, 0.6);
    border: 1px solid #8a8a8a;
    box-shadow: 2px 2px 3px -1px rgba(0, 0, 0, 0.5);
    position: absolute;
    width: 100%;
    height: 100%;
}

/* ## start-device-block mobile ##*/
/**mobile modal **/
/* Portrait */
@media screen and (orientation: portrait) and (min-height: 447px) {

    #register-prompt {
        background-color: #ffffff;
        height: 447px;
        width: 290px;
    }

    #modal-container-mobile {
        background-color: #666;
        background-color: rgba(102, 102, 102, 0.6);
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10000;
    }

    #modal-data-mobile {
        background-color: white;
        border: 1px solid #8a8a8a;
        box-shadow: 2px 2px 3px -1px rgba(0, 0, 0, 0.5);
        position: fixed;
        height: 447px;
        width: 290px;
    }

    #register-prompt > .modal-header {
        background-color: #93c762;
        box-shadow: 0 3px 3px rgba(184, 184, 182, 0.35);
        top: 0;
        width: 290px;
        z-index: 9999;
        height: 39px;
    }

    .logo-mobilePrompt {
        background-image: url('//static.thestudentroom.co.uk/67e2a42d66799/forum/images/register/mobilePrompt/TSRLogo.png');
        margin: 12px 0px 0px 13px;
        float: left;
        background-repeat: no-repeat;
        background-position: center;
        height: 52px;
        width: 58px;
    }

    .mobileModalClose {
        color: #FFFFFF;
        cursor: pointer;
        font-size: 24px;
        float: right;
    }

    .mobileModalTitle {
        margin: 28px 0px 0px 0px;
        padding: 0px 13px;
        text-align: center;
        width: 265px;
        color: #FF7C4D;
        font-size: 20px;
        font-weight: 500;
    }

    .modal-image {
        text-align: center;
        width: 290px;
        margin: 10px 0px;
        background-image: url('//static.thestudentroom.co.uk/67e2a42d66799/forum/images/register/mobilePrompt/bagIllustration.png');
        background-repeat: no-repeat;
        background-position: center;
        height: 184px;
    }

    .modal-join {
        margin: 20px 0px 0px 0px;
        background-image: url('//static.thestudentroom.co.uk/67e2a42d66799/forum/images/register/mobilePrompt/mobileRegButton.png');
        background-repeat: no-repeat;
        background-position: center;
        text-align: center;
        width: 290px;
        height: 34px;
    }

    .modal-join h1 {
        color: #ffffff;
        font-size: 19px;
        line-height: 32px;
        font-family: "Museo Sans Cyrillic", Arial, Geneva, Lucida, sans-serif;
        font-size: 20px;

    }

    .modal-signin-link {
        width: 100%;
        text-align: center;
        margin: 50px 0px 0px 0px;
        line-height: 40px;
        position: fixed;
        bottom: 0px;
        height: 39px;
        background-color: #EEEEEC;
        font-family: "Arial";
        font-size: 13px;
    }

    .modal-signin-link a {
        font-weight: bold;
    }

    #modal-data-mobile > .modal-iframe {
        border: 0;
        height: 447px;
        width: 290px;
    }

    .modal-iframe {
        overflow-y: hidden;
    }


    @media
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi){

        .logo-mobilePrompt {

            background-image: url('//static.thestudentroom.co.uk/67e2a42d66799/forum/images/register/mobilePrompt/retina/TSRLogo.png');
            background-size: 58px 52px;
        }
        .modal-image {
            background-image: url('//static.thestudentroom.co.uk/67e2a42d66799/forum/images/register/mobilePrompt/retina/bagIllustration.png');
            background-size: 219px 178px;
        }
        .modal-join {
            background-image: url('//static.thestudentroom.co.uk/67e2a42d66799/forum/images/register/mobilePrompt/retina/mobileRegButton.png');
            background-size: 151px 34px;
        }
    }

}

/**mobile modal **/
/* Landscape */
@media screen and (orientation: landscape) and  (min-width: 447px) {

    /* Portrait styles */
    #modal-container-mobile {
        background-color: #666;
        background-color: rgba(102, 102, 102, 0.6);
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10000;
    }

    #modal-data-mobile {
        background-color: white;
        border: 1px solid #8a8a8a;
        box-shadow: 2px 2px 3px -1px rgba(0, 0, 0, 0.5);
        position: fixed;
        width: 447px;
        height: 290px;
    }

    #register-prompt {
        background-color: #ffffff;
        width: 447px;
        height: 290px;
    }

    #register-prompt > .modal-header {
        box-shadow: 0 3px 3px rgba(184, 184, 182, 0.35);
        top: 0;
        background-color: #93c762;
        z-index: 9999;
        height: 39px;
        width: 447px;
    }

    .logo-mobilePrompt {
        background-image: url('//static.thestudentroom.co.uk/67e2a42d66799/forum/images/register/mobilePrompt/TSRLogo.png');
        margin: 12px 0px 0px 13px;
        float: left;
        background-repeat: no-repeat;
        background-position: center;
        height: 52px;
        width: 58px;
    }

    .mobileModalClose {
        color: #FFFFFF;
        cursor: pointer;
        font-size: 24px;
        float: right;
    }

    .mobileModalTitle {
        font-size: 18px;
        text-align: center;
        width: 127px;
        height: 111px;
        color: #FF7C4D;
        position: fixed;
        top: 80px;
        left: 46px;
        font-size: 18px;
    }

    .mobileModalTitle h1 {
        letter-spacing: 0.5px;
        font-weight: 500;
    }

    .modal-image {
        text-align: center;
        width: 290px;
        background-image: url('//static.thestudentroom.co.uk/67e2a42d66799/forum/images/register/mobilePrompt/bagIllustration.png');
        background-repeat: no-repeat;
        background-position: center;
        height: 184px;
        position: fixed;
        right: -10px;
        top: 55px;
    }

    .modal-join {
        background-image: url('//static.thestudentroom.co.uk/67e2a42d66799/forum/images/register/mobilePrompt/mobileRegButton.png');
        background-repeat: no-repeat;
        background-position: center;
        text-align: center;
        width: 154px;
        height: 34px;
        position: fixed;
        bottom: 55px;
        left: 25px;
    }

    .modal-join h1 {
        color: #ffffff;
        font-size: 19px;
        line-height: 32px;
        font-weight: bold;
        font-family: "Museo Sans Cyrillic", Arial, Geneva, Lucida, sans-serif;
        font-size: 20px;

    }

    .modal-signin-link {
        width: 100%;
        text-align: center;
        margin: 50px 0px 0px 0px;
        line-height: 40px;
        position: fixed;
        bottom: 0px;
        height: 39px;
        background-color: #EEEEEC;
        font-family: Arial;
        font-size: 13px;
    }

    .modal-signin-link a {
        font-weight: bold;
    }

    #modal-data > .modal-iframe {
        border: 0;
        width: 100%;
        height: 100%;
    }

    #modal-data-mobile > .modal-iframe {
        border: 0;
        width: 447px;
        height: 290px;

    }

    .modal-iframe {
        overflow-y: hidden;
    }


    @media
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi){

        .logo-mobilePrompt {

            background-image: url('//static.thestudentroom.co.uk/67e2a42d66799/forum/images/register/mobilePrompt/retina/TSRLogo.png');
            background-size: 58px 52px;
        }
        .modal-image {
            background-image: url('//static.thestudentroom.co.uk/67e2a42d66799/forum/images/register/mobilePrompt/retina/bagIllustration.png');
            background-size: 219px 178px;
        }
        .modal-join {
            background-image: url('//static.thestudentroom.co.uk/67e2a42d66799/forum/images/register/mobilePrompt/retina/mobileRegButton.png');
            background-size: 151px 34px;
        }
    }

}

@media (orientation: portrait) and (max-height: 446px) {
    #register-prompt {
        background-color: #ffffff;
        height: 355px;
        width: 230px;
    }

    #modal-container-mobile {
        background-color: #666;
        background-color: rgba(102, 102, 102, 0.6);
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10000;
    }

    #modal-data-mobile {
        background-color: white;
        border: 1px solid #8a8a8a;
        box-shadow: 2px 2px 3px -1px rgba(0, 0, 0, 0.5);
        position: fixed;
        height: 355px;
        width: 230px;
    }

    #register-prompt > .modal-header {
        background-color: #93c762;
        box-shadow: 0 3px 3px rgba(184, 184, 182, 0.35);
        top: 0;
        width: 230px;
        z-index: 9999;
        height: 30px;
    }

    .logo-mobilePrompt {
        background-image: url('//static.thestudentroom.co.uk/67e2a42d66799/forum/images/register/mobilePrompt/small/TSRLogo.png');
        margin: 12px 0px 0px 13px;
        float: left;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 50px 50px;
        width:50px;
        height:50px;
    }

    .mobileModalClose {
        color: #FFFFFF;
        cursor: pointer;
        font-size: 24px;
        float: right;
    }

    .mobileModalTitle {
        margin: 34px 0px 0px 0px;
        padding: 0px 0px;
        text-align: center;
        width: 230px;
        color: #FF7C4D;
        font-size: 16px;
        font-weight: 500;
    }

    .modal-image {
        text-align: center;
        margin: 10px 0px;
        background-image: url('//static.thestudentroom.co.uk/67e2a42d66799/forum/images/register/mobilePrompt/small/bagIllustration.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 127px 108px;
        width: 230px;
        height: 127px;
    }

    .modal-join {
        margin: 20px 0px 0px 0px;
        background-image: url('//static.thestudentroom.co.uk/67e2a42d66799/forum/images/register/mobilePrompt/small/mobileRegButton.png');
        background-repeat: no-repeat;
        background-position: center;
        text-align: center;
        width: 230px;
        height: 30px;
    }

    .modal-join h1 {
        color: #ffffff;
        font-size: 19px;
        line-height: 32px;
        font-weight: bold;
        font-family: "Museo Sans Cyrillic", Arial, Geneva, Lucida, sans-serif;
        font-size: 16px;

    }
    .modal-signin-link {
        width: 100%;
        text-align: center;
        margin: 50px 0px 0px 0px;
        line-height: 40px;
        position: fixed;
        bottom: 0px;
        height: 39px;
        background-color: #EEEEEC;
        font-family: Arial;
        font-size: 13px;
    }

    .modal-signin-link a {
        font-weight: bold;
    }

    #modal-data-mobile > .modal-iframe,
    #modal-data > .modal-iframe {
        border: 0;
        height: 355px;
        width: 230px;
    }

    .modal-iframe {
        overflow-y: hidden;
    }

    @media (-webkit-min-device-pixel-ratio: 2){
        .logo-mobilePrompt {

            background-image: url('//static.thestudentroom.co.uk/67e2a42d66799/forum/images/register/mobilePrompt/small/retina/TSRLogo.png');
            background-size: 50px 45px;
        }

        .modal-image {
            background-image: url('//static.thestudentroom.co.uk/67e2a42d66799/forum/images/register/mobilePrompt/small/retina/bagIllustration.png');
            background-size: 127px 108px;
        }

        .modal-join {
            background-image: url('//static.thestudentroom.co.uk/67e2a42d66799/forum/images/register/mobilePrompt/small/retina/mobileRegButton.png');
            background-size: 123px 30px;
        }
    }
}

@media (orientation: landscape)  and (max-width: 446px) {
    /* Portrait styles */
    #modal-container-mobile {
        background-color: #666;
        background-color: rgba(102, 102, 102, 0.6);
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10000;
    }

    #modal-data-mobile {
        background-color: white;
        border: 1px solid #8a8a8a;
        box-shadow: 2px 2px 3px -1px rgba(0, 0, 0, 0.5);
        position: fixed;
        width: 355px;
        height: 230px;
    }

    #register-prompt {
        background-color: #ffffff;
        width: 355px;
        height: 230px;
    }

    #register-prompt > .modal-header {
        box-shadow: 0 3px 3px rgba(184, 184, 182, 0.35);
        top: 0;
        background-color: #93c762;
        z-index: 9999;
        height: 30px;
        width: 355px;
    }

    .logo-mobilePrompt {
        background-image: url('//static.thestudentroom.co.uk/67e2a42d66799/forum/images/register/mobilePrompt/TSRLogo.png');
        margin: 12px 0px 0px 13px;
        float: left;
        background-repeat: no-repeat;
        background-position: center;
        height: 52px;
        width: 58px;
    }

    .mobileModalClose {
        color: #FFFFFF;
        cursor: pointer;
        font-size: 24px;
        float: right;
    }

    .mobileModalTitle {
        font-size: 18px;
        text-align: center;
        width: 127px;
        height: 111px;
        color: #FF7C4D;
        position: fixed;
        top: 50px;
        left: 46px;
        font-size: 16px;
    }

    .mobileModalTitle h1 {
        letter-spacing: 0.5px;
        font-weight: 500;
    }

    .modal-image {
        text-align: center;
        width: 230px;
        background-image: url('//static.thestudentroom.co.uk/67e2a42d66799/forum/images/register/mobilePrompt/small/bagIllustration.png');
        background-repeat: no-repeat;
        background-position: center;
        height: 114px;
        position: fixed;
        right: -10px;
        top: 55px;
    }

    .modal-join {
        background-image: url('//static.thestudentroom.co.uk/67e2a42d66799/forum/images/register/mobilePrompt/small/mobileRegButton.png');
        background-repeat: no-repeat;
        background-position: center;
        text-align: center;
        width: 156px;
        height: 30px;
        position: fixed;
        bottom: 40px;
        left: 25px;
    }

    .modal-join h1 {
        color: #ffffff;
        font-size: 19px;
        line-height: 32px;
        font-weight: bold;
        font-family: "Museo Sans Cyrillic", Arial, Geneva, Lucida, sans-serif;
        font-size: 16px;

    }

    .modal-signin-link {
        width: 100%;
        text-align: center;
        margin: 50px 0px 0px 0px;
        line-height: 40px;
        position: fixed;
        bottom: 0px;
        height: 30px;
        background-color: #EEEEEC;
        font-family: Arial;
        font-size: 13px;
    }

    .modal-signin-link a {
        font-weight: bold;
    }

    #modal-data > .modal-iframe {
        border: 0;
        width: 100%;
        height: 100%;
    }

    #modal-data-mobile > .modal-iframe {
        border: 0;
        width: 355px;
        height: 230px;

    }

    .modal-iframe {
        overflow-y: hidden;
    }
    @media (-webkit-min-device-pixel-ratio: 2){
        .logo-mobilePrompt {

            background-image: url('//static.thestudentroom.co.uk/67e2a42d66799/forum/images/register/mobilePrompt/small/retina/TSRLogo.png');
            background-size: 50px 45px;
        }

        .modal-image {
            background-image: url('//static.thestudentroom.co.uk/67e2a42d66799/forum/images/register/mobilePrompt/small/retina/bagIllustration.png');
            background-size: 127px 108px;
        }

        .modal-join {
            background-image: url('//static.thestudentroom.co.uk/67e2a42d66799/forum/images/register/mobilePrompt/small/retina/mobileRegButton.png');
            background-size: 123px 30px;
        }
    }
}

/* ## end-device-block ## */

#modal-data.drawer {
    border-width: 1px 0 0;
    top: auto;
    bottom: 0;
}

#modal-data.drawer > .modal-iframe {
    height: 100%;
    width: 100%;
}

@media (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi), screen and (max-width: 480px) {
    #modal-data.modal-regprompt.drawer {
        overflow-y: scroll !important;
        -webkit-overflow-scrolling: touch;
    }

    #modal-data.modal-askaquestion {
        border: 0;
    }
}

#popover-mobile-cta-bg {
    z-index: 99999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

#popover-mobile-cta {
    bottom: 0;
    transition: top 0.5s;
    text-align: center;
    box-sizing: border-box;
    background: #fff;
    z-index: 100000;
    position: fixed;
    width: 100%;
    padding: 125px 16px 22px;
    font-family: Gibson, Arial, sans-serif;
    color: #4a4a4a;
}

#popover-mobile-cta-banner {
    background: url('https://static.thestudentroom.co.uk/67e2a42d66799/dpc/image/dpc_refresh_bg_2019.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 110px;
    position: absolute;
    top: 0;
    left: 0;
}

#popover-mobile-cta h2 {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: bold;
}

#popover-mobile-cta p {
    font-size: 16px;
    margin-bottom: 25px;
}

#popover-mobile-do-survey {
    color: #fff;
    font-size: 17px;
    font-weight: bold;
    background: #4cb58f;
    border-radius: 15px;
    padding: 7px 15px;
}

#popover-mobile-cta-close {
    position: absolute;
    top: 3px;
    right: 6px;
    font-size: 22px;
    color: #fff;
}

#popover-mobile-do-survey-not-interested {
    font-family: Gibson, sans-serif;
    font-size: 14px;
    clear: both;
    display: block;
    margin-top: 22px;
}

div.ed-modal.modal div.simplemodal-close {
    background:none;
    right:0px;
    top:0px;
    color: #FFFFFF !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.ed-modal.modal div.simplemodal-close:hover {
    font-weight: 700;
    text-decoration: underline;
}

