/**
* A few breadcrumb related rules.
* @author A TSR Developer
* @device all
*/

/* Breadcrumbs */

#breadcrumb,
#breadcrumb .navbar {
    font-size: 1em;
}

#breadcrumb {
    color: #272727;
    float: left;
    padding: 10px 10px 10px 0px;
}

#breadcrumb a {
    color: #7B63DB !important;
}

#breadcrumb a:hover {
    color: #6652B8 !important;
}

#breadcrumb .navbar {
    background: none;
}

#breadcrumb .navbar .mobile {
    display:none;
}
