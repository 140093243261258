/* NEW ANNOUNCEMENTS */
#primary_content #announcements {
    margin: 10px 0;
    border-bottom: 2px solid #E2E3E4;
    font-family: Arial !important;
}

#primary_content #announcements .announcements_header {
    border-bottom: 2px solid #E2E3E4;
    padding: 5px;
    color: #444;
    font-size: 16px;
    font-family: Arial !important;
    font-weight: bold;
}

#primary_content #announcements a.announcement_img {
    height: 20px;
    width: 24px;
    display: block;
    float: left;
    margin-left: 5px;
    margin-right: 10px;
    margin-top: 7px;
}

#primary_content #announcements .announcement_text {
    display: table-row;
    height: 35px;
}

#primary_content #announcements .announcement_text span {
    display: table-cell;
    vertical-align: middle;
    color: #7B63DB;
}

#primary_content #announcements .advert-row {
    border-bottom: 1px dotted #ccc;
}

#primary_content #announcements .advert-row:last-child {
    border-top: none;
}

#primary_content #announcements .advert-row.important {
    background-color: #fdf9f9;
}

#primary_content #announcements .advert-block {
    outline: none;
    width: 100%;
    height: 35px;
    margin: 0px;
    line-height: 17px;
    padding: 5px 0;
    background: transparent;
}

#primary_content #announcements .advert-row.hidden,
#primary_content #announcements .advert-row.hidden .advert-block {
    display: none;
}

#primary_content #announcements .advert-row.hidden,
#primary_content #announcements .advert-row.last {
    border-bottom: none;
}

@media ( max-width: 480px ) {
    #primary_content #announcements .advert-block {
        font-size: 12px;
    }
}
/* NEW ANNOUNCEMENTS */
