/**
* A variety of CSS rules pertaining to core vBulletin level elements.
* @author A TSR Developer
* @device all
*/

body { font-size: 12px; margin: 0; padding: 0; color: #444; }

pre {
    margin: 0;
    white-space: pre-wrap;       /* css-3 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
    }

#navbar_notice { border-radius: 5px 5px 0 0 }
#navbar_notice.theme_background { color: #fff; height:17px; padding:5px 10px; }
.navbar_notice_bg { padding: 0 0 4px !important; margin-bottom: 20px; }
.navbar_notice_dismiss { cursor: pointer; float: right; font-weight: bold }
strong { font-weight:bolder }
.navbar { height: auto; color: inherit; margin: 0 }
a, a:link { text-decoration: none }
.highlight { color: #F33; font-weight: bold; background-color: transparent }
input[type=text],input[type=password], textarea, select {
    font-size:12px;
    padding:1px;
    vertical-align:middle;
    height:18px;
    border: 1px solid #abadb3!important;
    border-radius: 3px;
    box-sizing: content-box !important;
}

input[type=text]:not(.input-text),
input[type=password]:not(.input-text),
textarea:not(.input-textarea) {
    background:#fff !important;
}

.lte8 input[type=text],
.lte8 input[type=password],
.lte8 textarea,
select {
    background:#fff !important;
}

select { padding:0 }
textarea { vertical-align: top; margin: 2px 2px 2px 0; height: 100% }
.quote_block { background-color: #EEFAFC; border: 1px dotted #DFDFDF !important; padding: 8px 16px 6px !important; margin: 0px }
.quote_block .origin { color: #333 }
.quote_block .origin a { color: #3BB0D7; font-weight: bold }
.postbit_editnote { margin-top: 10px }
/* BOOKMARK */
.smallfont, fieldset .smallfont { font-size: 11px; font-weight: normal }
#page_showthread .postbit_editnote em { font-style: italic }
.signature_button { background-color: white; padding-left: 3px; position: absolute; top: -17px; right: 0 }
.signature_button a { display: block }
.post_thumb_guest { display: inline; margin: 0 2px; width: 30px }
.post_thumb { display: inline; margin: 0 5px; width: 30px }
.post_score { font-weight: normal; margin-left: -7px; color: #999; z-index: 100 }
h2 { font-size: 16px; margin: 0 0 0.67em }

.input-reset,
.input-button {
    font-weight: 900;
    font-family: arial !important;
    color: #fff !important;
    cursor: pointer;
    text-decoration: none !important;
    white-space: nowrap;
    border: 0 !important;
    display: inline-block;
}

.rounded5,
.input-submit,
.input-reset,
.input-button { border-radius: 5px }
input[type=checkbox] { margin-right: 3px }
.input-submit, .input-reset { font-size:12px; padding: 1px 6px 2px }

/***** quick reply *****/
#qr_defaultcontainer.floatcontainer:after { padding-bottom: 0 }
.vbform { max-width: 100% }
.vbform .blockrow:after { padding-bottom: 5px!important }
#quickreply_title:first-child { margin-top: 0 }
#quickreply_title {
    background-color: #3ba7d2;
    font-size: 1.154em;
    font-weight: normal;
    padding: 6px;
    color: #FFFFFF;
    width: inherit;
    margin-bottom: 0;
    border: 0;
}
#quick_reply .wysiwyg_block .blockbody { padding: 6px 0px 6px 0px; border: none !important; background-color: white !important }
.formcontrols { border-top: 0 }
.blockbody, .blockrow { background: #F8F5FF !important; color: #272727; font-size: 11px }
#quick_reply .wysiwyg_block .blockrow {  padding: 0; background-color: white !important }
#quick_reply .wysiwyg_block .blockrow .blockrow.texteditor { margin-right: 0 }
#quick_reply .wysiwyg_block .blockrow .editor_textbox_container { margin: 0px }
#quick_reply .wysiwyg_block .blockrow span.cke_skin_kama { padding: 0px; border: none !important }
#quick_reply .wysiwyg_block .formcontrols .postcontrols { margin-bottom: 0px }
.wysiwyg_block .formcontrols .postcontrols { padding: 0 !important }
.quickreply_buttons { float: right }
#quick_reply #qr_posting_msg { float: right; margin-right: 10px }
#quick_reply .wysiwyg_block ul.checkradio li { margin-right: 15px }
.wysiwyg_block .formcontrols .postcontrols ul li label { display: inline }

/***** Widgets *****/
.ui-helper-reset { margin: 0; padding: 0; border: 0; outline: 0; line-height: 1.3; text-decoration: none; font-size: 100%; list-style: none }
.ui-helper-clearfix:after { content: "."; display: block; height: 0; clear: both; visibility: hidden }
.ui-tabs .ui-tabs-nav li { position: relative; float: left; border-bottom-width: 0 !important; margin: 0; padding: 0 }
.ui-tabs .ui-tabs-nav li a { float: left; text-decoration: none; padding: 0 }

/* 8 */
body .alt1, .alt1Active, body .alt2, .alt2Active {
    color: #444444;
}

/* 13 */
table { border-spacing: 2px; }

/* 25 */
table.tborder, table.no-spacing {
    border-spacing: 0;
}

/* 120 */
.widget-content-column .tborder > :first-child > tr:first-child > td, .widget-content-column .tborder > :first-child > tr:first-child > th {
    border-radius: 0;
}

/* 154 */
a:hover { text-decoration: underline; }
a img { border: 0; }

/* 158 */
.fieldset { font-size: 12px; margin-bottom: 0.5em; padding: 7px; }

/* 165 */
legend { color: #444; padding-left: 2px; }

/* 221 */
.table-columns { border-spacing: 0; width: 100%; margin-bottom: 1em; }
.table-columns > tr > td,
.table-columns > tbody > tr > td { vertical-align: top; padding: 0 0 0 12px; }
.table-columns > tr > td:first-child,
.table-columns > tbody > tr > td:first-child { vertical-align: top; padding: 0; }

table.tborder>thead>tr>:last-child, table.tborder>tbody>tr>:last-child, table.tborder>tfoot>tr>:last-child {
    border-right: 1px solid #DDD;
}

table.tborder>tbody>tr>td { background: #FFFFFF; }

/* 226 */
.tcat {
    white-space: nowrap;
}

/* 372 */
table.tborder > thead > tr > td, table.tborder > thead > tr > th, table.tborder > tbody > tr > td, table.tborder > tbody > tr > th, table.tborder > tfoot > tr > td, table.tborder > tfoot > tr > th, div.tborder > *, .alt1, .alt2, .vbmenu_option {
padding: 6px !important;
}

/* 378 */
/*******************************************************************************
 ** Signup page */
#signuptoday { border: 1px solid #DDDDDD; padding: 1px 7px; margin-bottom: 20px; }
#signuptoday p { padding: 10px; margin: 0; }
#signuptoday p a { font-weight: bold; }
#signuptoday .signup-bg { background-repeat: repeat-x; height: 176px; }
#signuptoday .signup-bg-left { background-repeat: no-repeat; float: left; height: 176px; width: 12px; }
#signuptoday .signup-bg-right { background-repeat:no-repeat; float: right; height: 176px; width: 191px; }
#signuptoday .signup-bg div a { color: #ED7E13; font-weight: bold; }
#signuptoday .signup-bg img { margin-top: 20px; }
#signuptoday .signup-bg p { padding: 10px; color: #000; font-size: 12px; line-height: 20px; }
#signuptoday .join { float: right; margin-top: -5px; padding:10px; }
#signup_table td { padding: 5px; }

/* New Signup Design */
#sign-up_today {
    border: 4px solid #3bb5e0;
    position: relative;
    margin-bottom: 10px;
    /*background-image: url('//static.statler.tsrdev.co.uk/7.9/images/signup/earth.png');
    background-repeat: no-repeat;
    background-position-x: 101%;
    background-position-y: 100%;*/
}


#sign-up_today > img {
    bottom: 0px;
    right: -2px;
    position: absolute;
    max-width: 359px;
    max-height: 204px;
    width: 40%;
}

#sign-up_today > div {
    margin: 10px 14px;
}

#sign-up_today h2 {
    color: #439FD3;
    font-size: 2.5em;
    margin-bottom: 4px;
}

#sign-up_today h2 img {
    display: none;
}

#sign-up_today p {
    color: #3BB5DF;
    font-size: 1.2em;
}

#sign-up_today ul li {
    color: #3BB5DF;
    margin: 2px 0px;
    margin: 6px 0px;
    list-style-type: disc;
}

#sign-up_today ul {
    margin: 10px 18px;
}

#sign-up_today  a img {
    margin: 5px 0px;
}


#sign-up_today a img.mobile {
    display: none;
}

@media (-webkit-min-device-pixel-ratio: 1.25),
       (min-resolution: 120dpi) {
    #sign-up_today a img.mobile {
        display: block;
        width: 153px;
        height: 36px;
    }

    #sign-up_today a img.desktop {
        display: none;
    }


}

/* 388 */
table.tborder, div.tborder {
    border: 0;
    background: transparent;
}

/* 389 */
.tborder > thead > tr > td, .tborder > thead > tr > th, .tborder > tbody > tr > td, .tborder > tbody > tr > th, .tborder > tfoot > tr > td, .tborder > tfoot > tr > th {
    border-bottom: 1px solid #DDD;
}

/* 398 */
table.tborder > thead > tr > :first-child, table.tborder > tbody > tr > :first-child, table.tborder > tfoot > tr > :first-child {
    border-left: 1px solid #DDD;
}

/* 417 */
table.tborder > :first-child > tr:first-child > td, table.tborder > :first-child > tr:first-child > th {
    border-top: 1px solid #DDD;
}

/* 427 */
.tborder .tcat {
    line-height: 17px;
}

/*525 */
#primary_content .widget-content-column-home-primary,
#secondary_content .widget-content-column-home-secondary,
#gutter_content .widget .tcat, table.lightwidget .tcat { color: #000; }

/*531*/
.widget-content { padding: 6px; }

/* 545 */
.widget-content { padding: 0; }
.widget-content .tborder tr.last-child td { border-bottom: 0 !important; }
.widget-content .tborder tr:last-child td { border-bottom: 0 !important; } /* had to separate these two lines (above and this) to make it work on some pages in IE8 */

/* 564 */
/*clearing widget*/
#widget_clearing .widget-content .clearing-header,
#widget_clearing .widget-content .clearing-body { padding: 5px; }
#widget_clearing .widget-content #clearing-image { width:100%; height: 101px; }
#widget_clearing .widget-content h1 { color: #44A0D4; margin-bottom: 0.4em; }
#widget_clearing .widget-content p { padding: 0.5em 0 0.3em 0; margin: 0; font-size: 12px; }

.clearing-header h1 {
    color: #44A0D4;
    margin-bottom: .4em;
    font-weight: bold;
    font-size: 18px;
    margin: 0 0 .67em;
}

/* 608 */
.widget_title {
    height: 20px;
    width: 100%;
}

/* 609 */
.widget_edit {
    height: 20px;
    text-align: right;
    width: 1000px;
}

/* 610 */
table.tborder tr td.widget_edit {
    padding: 4px 1px !important;
}

/* 611 */
.widget_buttons {
    height: 20px;
    float: right;
}

/* 613 */
#gutter_content .widget-content-column-default-gutter {
    display: block;
}

/* 621 */
table.widget > tbody > tr > td {
    padding: 0 !important;
}

/* 623 */
.widget thead td, .widget thead tr, .widget thead td.thead {
    padding: 6px 6px 4px;
}

/* 626 */
.widget-content td {
    vertical-align: top;
}

/* 627 */
.widget-subcontent {
    display: none;
}

/* 631 */
.widget_ask_mod {
    font-size: 11px;
    display: block;
}

/* 641 */
.widget-content .tborder td {
    border-left: 0 !important;
    border-right: 0 !important;
}

/* 800 */
.sprite_widget_buttons {
    display: inline-block;
}

/* 803 */
.sprite_widget_buttons_plus {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/widget/expander.png") no-repeat 0 0 !important;
    width: 15px;
    height: 15px;
}

/* 804 */
.sprite_widget_buttons_minus {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/widget/expander.png") no-repeat 0 -20px !important;
    width: 15px;
    height: 15px;
}

/* 909 */
#widget_ps-helper .widget-content, #widget_moderator-tools .widget-content {
    padding: 0px !important;
}

/* 978 */
table {
    border-collapse: separate;
}

/*******************************************************************************
 ** Bookmarks (alternative for Explore TSR button) */
#page-columns .bookmarks { background: #F8F5FF; margin: 0 0 16px; padding: 8px 10px; border-radius: 5px; }
#page-columns .bookmarks p { font-size: 12px; margin: 0 0 4px; }
#page-columns .bookmarks p a { font-size: 10px; }
#page-columns .bookmarks .bookmark_links a { font-size: 12px; display: inline-block; }
#page-columns .bookmarks .bookmark_links span { margin: 0 5px; }

#signup_table td {
    padding: 5px;
}

/* Promotion Bar */
#promo-bar {
    display: none;
    margin-left: 20px;
    margin-right: 20px;
    position: relative;
    height: 56px;
    margin-bottom: 15px;
    background-color: #94c566;
    z-index: 2;
}
#promo-bar:before {
    content: "\a0";
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/clearing/results-logo.png") no-repeat;
    height: 70px;
    width: 142px;
    position: absolute;
    bottom: -4px;
    left: 5px;
}
#promo-bar .promo-wrapper {
    color: white !important;
    background-color: #94c566;
    padding: 0;
    height: inherit;
}
#promo-bar .promo-wrapper:after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    visibility: hidden;
}
#promo-bar .left-promo {
    margin-left: 10px;
    padding-right: 3px;
    height: inherit;
    width: 60%;
    float: left;
}
#promo-bar .right-promo {
    margin-left: 10px;
    padding-right: 5px;
    float: left;
    height: inherit;
}
#promo-bar .promo-msg {
    font-size: 16px;
    padding-left: 151px;
    font-family: Arial;

    height: inherit;
    display: table-cell;
    vertical-align: middle;
}
#promo-bar .promo-buttons {
    height: inherit;
    display: table-cell;
    vertical-align: middle;
    font-size: 10px;
}
#promo-bar .promo-buttons.large {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/svg/blobs/interact-large.f57345.c34b20.svgz") no-repeat;
    background-size: 100% 100%;
    border: none;
    box-shadow: none;
    font-size: 1.333em;
    font-weight: bold;
    height: 28px;
    line-height: 27px;
    margin: 0;
    padding: 0 10px;
    text-transform: none;
    color: white;
    text-decoration: none !important;
}

/* Action Confirmation Loading Pages */

#action_load {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background-color: #F8F5FF;
    color: #272727;
    font-family: Arial !important;
}

#action_load .error_msg > span.thanks {
    font-size: 50px;
    margin: 15px 0px;
    display: block;
}

#action_load .error_msg {
    margin: 60px auto;
    text-align: center;
    max-width: 530px;
    font-size: 20px;
    font-family: Arial;
    padding-top: 100px;
    line-height: 1.5;
}

#action_load a {
    color: #181C1E;
    text-decoration: none;
    font-size: 12px;
    display: block;
    margin: 5px 0px;
    font-family: "Arial";
}

#action_load span.username,
#action_load span.hey {
    font-size: 60px;
    margin-left: 5px;
}

@media screen and (max-width: 480px) {

    #action_load {
        background-color: #DAE4EA;
    }

    #action_load .error_msg > span {
        display: block;
        margin: 0px;
    }

    #action_load .error_msg span.hey {
        font-size: 110px;
        text-transform: uppercase;
        line-height: 1;
    }

    .modal #action_load .error_msg {
        color: #444;
        padding-top: 20px;
    }
    .modal #action_load .error_msg a {
        color: #444;
    }

    #action_load .error_msg {
        width: 250px;
    }

    #action_load .error_msg a {
        margin: 10px 0px;
    }

    #action_load span.username {
        font-size: 30px;
    }

    #action_load span.post_vis,
    #action_load span.thanks {
        font-size: 20px!important;
    }
}
