/**
* Lots of rules about sidebar widgets, both in terms of generic wrapppers and widget specfic rules.
* @author A TSR Developer
* @device mixed
*/

/* Widget template */

.widget {
    font-size: 1.083em;
    margin-bottom: 16px;
}

/* Mobile styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    .widget {
        margin-bottom: 30px;
    }

    .widget,
    .widget input,
    .widget label,
    .widget select,
    .widget textarea,
    .widget .ctl-bar,
    .widget .ctl-bar .ctl-tab,
    .widget .interact.tertiary {
        font-family: Arial !important;
        font-size: 15px;
        -webkit-font-smoothing: subpixel-antialiased;
    }

    .widget .interact {
        font-family: Arial, Geneva, Lucida, sans-serif !important;
        font-size: 13px;
    }

    .widget .interact.tertiary {
        font-size: 14px;
    }
}


.widget:empty {
    margin-bottom: 0;
}

.widget a,
.widget a:link,
.widget a:hover,
.widget a:visited {
    color: #272727;
}

.widget a.bubble,
.widget a.bubble:link,
.widget a.bubble:hover,
.widget a.bubble:visited {
    color: white;
}

/* Mobile Styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    .widget a,
    .widget a:link,
    .widget a:hover,
    .widget a:visited {
        font-size: 14px;
    }

    .widget .widget-action .interact {
        font-size: 13px;
    }
}

#primary_content .sidebar .widget .ctl-bar,
.widget .ctl-bar {
    background-color: #7B63DB;
}

#primary_content .widget .ctl-bar {
    background-color: #7B63DB;
}

/* Mobile Styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    .widget .ctl-bar,
    #widgets-primary .widget .ctl-bar,
    #primary_content .sidebar .widget .ctl-bar {
        background-color: #7B63DB;
    }
}

.widget .ctl-bar a,
.widget a.interact {
    color: white;
}

.widget .ctl-bar .left-ctl > div,
.widget .ctl-bar .right-ctl > div {
    height: 32px;
}

.widget .widget-header {
    margin: 0;
    max-height: 75px;
    border: solid #cecfd0;
    border-width: 1px 1px 0px;
    background-color: transparent !important;
}

.widget .widget-title {
    font-size: 1.154em;
    line-height: 32px;
    background-color: #7B63DB;
}

/* Mobile Styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    .widget .widget-title {
        font-weight: bold;
        line-height: 35px;
        font-size: 1em;
        -webkit-font-smoothing: antialiased;
    }
}

.widget .widget-title ul {
    vertical-align: top;
}

.widget .widget-title li,
#page_wiki .widget .widget-title li {
    float: left;
    list-style-type: none;
    position: relative;
}
.widget .widget-title li .widget-badge {
    background-color: #ffa200;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    position: absolute;
    top: -5px;
    right: -5px;
    color: #fff;
    box-shadow: 0 0 0 rgba(255,160,0, 0.4);
    animation: pulse 2s 2;
}
.widget .widget-title li .widget-badge span {
    position: absolute;
    top: 50%;
    left: 50%;
    font-weight: bold;
    font-size: 12px;
    transform: translate(-50%, -50%);
}
@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(255,160,0, 0.4);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(255,160,0, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(255,160,0, 0);
    }
}
.widget .widget-title li[data-show-empty],
.widget .widget-title li[data-show-for] {
    display: none;
}

.widget .widget-title li a {
    display: block;
}

.widget .widget-title .count {
    background-color: #ffa400;
    border-radius: 9px;
    display: inline-block;
    font-size: 0.8em;
    font-weight: bold;
    line-height: 18px;
    margin-left: 3px;
    min-width: 7px;
    padding: 0 6px 0 5px;
    text-align: center;
}

/* Mobile Styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    .widget .widget-title .count {
        font-size: 14px;
    }
}

.widget .widget-title .ctl-tab {
    background-color: transparent !important;
    color: #7B63DB !important;
    font-size: 13px;
}

#primary_content .sidebar .widget .widget-title .ctl-tab {
    background-color: #83b552;
    border-color: #5c9d2e;
}

#primary_content .widget .widget-title .ctl-tab,
#widgets-primary .widget .widget-title .ctl-tab {
    background-color: #2eb3e2;
    border-color: #78cbf4;
}

#primary_content .widget .widget-title .active .ctl-tab,
#widgets-primary .widget .widget-title .active .ctl-tab {
    color: #6652B8!important;
    background-color: #ffffff;
}

/* Mobile Styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    .widgets-primary .widget .widget-title .ctl-tab,
    #primary_content .sidebar .widget .widget-title .ctl-tab,
    .widget .widget-title .ctl-tab {
        background-color: #31b2e5;
        border-color: #96daf2;
        font-size: 13px;
    }
}

.widget .widget-title .ctl-tab:focus,
.widget .widget-subtitle a:focus {
    outline: 0;
}

.widget .widget-title .active .ctl-tab {
    color: #5b9c2d !important;
    background-color: white;
    font-size: 1em;
    line-height: 26px;
    font-family: Arial !important;
    font-weight: 700;
}

#widgets-primary .widget .widget-title .active .ctl-tab {
    color: #6652B8 !important;
    font-weight: 700;
}

#widgets-gutter .widget .widget-title .active .ctl-tab {
    color: #6652B8 !important;
    font-weight: 700;
}

/* Mobile Styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    #widgets-primary .widget .widget-title .active .ctl-tab,
    #widgets-gutter .widget .widget-title .active .ctl-tab,
    .widget .widget-title .active .ctl-tab {
        color: #6652B8 !important;
        font-weight: 700;
        font-size: 15px;
    }
}

#widget-discussions .widget .widget-title .active .ctl-tab,
#widgets-primary .widget .widget-title .active .ctl-tab,
#widgets-gutter .widget .widget-title .active .ctl-tab {
    background-color: white;
    font-size: 1em;
    line-height: 26px;
    font-family: Arial !important;
    font-weight: 700;
}

/* Mobile Styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    #widgets-primary .widget .widget-title .active .ctl-tab,
    #widgets-gutter .widget .widget-title .active .ctl-tab {
        border-color: white;
        font-size: 1em;
        font-weight: bold;
        line-height: 27px;
        margin-top: 7px;
    }
}

.widget .widget-title > div {
    padding: 8px 10px;
    line-height: 16px;
}

/* Mobile Styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    .widget .widget-title > div {
        padding: 0 7px;
        line-height: 35px;
        font-size: 15px;
    }
}

.widget .widget-title > .icon-title {
    padding-left: 4px;
}

.widget .widget-title .interact {
    font-size: 0.8em;
    margin-left: 17px;
    margin-top: 5px;
    vertical-align: top;
}

/* Mobile Styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    .widget .widget-title .interact {
        margin-top: 6px;
    }
}

.widget .widget-subtitle {
    background-color: #edede6;
    border-bottom: 2px solid #83b552;
    line-height: 28px;
    padding: 0 3px 1px 10px;
}

#widgets-primary .widget .widget-subtitle {
    border-bottom-color: #44a0d4;
}

.widget .widget-subtitle .widget-action {
    float: right;
}

.widget.narrow .widget-subtitle {
    margin: 0 -6px;
}

.widget .ctl-tab {
    font-weight: normal;
}

.widget .ctl-bar .widget-edit {
    color: #ededee !important;
    font-size: 0.846em;
}

#widgets-primary .widget .ctl-bar .widget-edit {
    color: #9ad2eb !important;
}

.widget .widget-tabs {
    padding: 3px 0 0;
}

.widget .widget-tabs li {
    display: inline-block;
    margin-left: 3px;
}

.widget .widget-tabs li a {
    background-color: #83b552;
    border: 1px solid transparent;
    border-bottom: 0;
    color: white;
    display: block;
    font-size: 0.923em;
    line-height: 25px;
    min-width: 96px;
    position: relative;
    bottom: -2px;
    text-align: center;
}

#widgets-primary .widget .widget-tabs li a {
    background-color: #44a0d4;
}

.widget .widget-tabs .ui-tabs-active a {
    background-color: white;
    border-color: #c7c8c9;
    color: #5b9c2d;
}

#widgets-primary .widget .widget-tabs .ui-tabs-active a {
    background-color: white;
    color: #4088b8;
}

.widget .widget-content {
    color: #666;
    overflow: hidden;
}

/* Widget - narrow */
.widget.narrow .widget-content {
    border: solid #cecfd0;
    border-width: 0 1px 1px;
    padding: 0 6px;
}

/* Mobile Styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    .widget.narrow .widget-content {
        border: none;
        padding: 0;
        border: solid #cecfd0;
        border-width: 0 1px 1px;
    }
}


/* Widget - generic items */

.widget-table {
    border-bottom: 1px solid #44a0d4;
    border-spacing: 0;
    width: 100%;
}

.widget-table th {
    background-color: #edede6;
    border-bottom: 2px solid #44a0d4;
    color: #808080;
    padding: 6px 3px;
    text-transform: lowercase;
}

.widget-table th:first-child {
    padding-left: 12px;
}

.widget-table td {
    border-top: 1px dotted #ccc;
    color: #808080;
    padding: 10px 3px;
}

.widget-table tr:first-child td {
    border-top: none;
}

.widget .widget-list {
    color: #808080;
    line-height: 1.231em;
}

/* Mobile Styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    .widget .widget-list {
        collapse: inherit;
        line-height: 20px;
    }
}

.wide .widget-list {
    border-bottom: 1px solid #44a0d4;
    padding-bottom: 4px;
}

.widget-list > li {
    padding: 6px 0;
    line-height: 18px;
}
.widget-list > li:not(:first-child) {
    border-top: 1px dotted #dbdcdd;
}

/* Desktop Only */
@media screen and (min-width: 481px) {
    #widget-discussions .widget-list > li,
    #widget-scrolling-discussions .widget-list > li {
        line-height: 18px;
    }

    /* Orange update bar breaks otherwise */
    #widget-discussions .widget-list>li.update-bar,
    #widget-scrolling-discussions .widget-list>li.update-bar {
        line-height: 32px;
    }
}

/* Mobile Styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    .widget-list > li {
        padding: 10px 0;
    }
}

.widget-list > li:first-child {
    border-top: none;
}

.widget-list .title .threadbit-icon {
    vertical-align: middle;
}

.widget-wide-item {
    border-collapse: separate;
    display: table;
    width: 100%;
}

.widget-wide-item .item,
.widget-wide-item .from {
    display: table-cell;
    vertical-align: top;
}

.lte7 .widget-wide-item .item,
.lte7 .widget-wide-item .from {
    float: left;
}

.widget-wide-item .item {
    color: #505050;
    padding-right: 10px;
    width: 70%;
}

.widget-wide-item .from {
    color: #808080;
    font-size: 0.923em;
}

.widget-wide-item .from a {
    color: #21669c !important;
}

.widget-wide-item .from .avatar {
    float: left;
    margin-right: 8px;
}

.widget-wide-item .from .avatar img {
    border: 1px solid #c3c5c6;
    height: 32px;
    width: 32px;
}

.widget-wide-item .from .avatar ~ .details {
    display: block;
    margin-left: 37px;
}

.widget-wide-item .from .byline,
.widget-wide-item .from .date {
    display: block;
}

.widget-action {
    text-align: right;
}

.widget-action .interact.tertiary {
    padding-bottom: 0;
    padding-top: 0;
}

/* Widget - expandable */

.widget .expander {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/widget/expander.png") no-repeat 0 0;
    cursor: pointer;
    float: left;
    height: 15px;
    margin-top: 3px;
    width: 16px;
    display:none;
}

/* Mobile Styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    .widget .expander {
        margin-top: 3px;
    }
}


.widget .expander ~ a,
.widget .expander ~ span {
    display: block;
    margin-left: 5px;
}

.widget .expanded .expander {
    background-position: 0 -20px;
}

.widget .expand {
    display: none;
    font-size: 0.923em;
    padding-left: 21px;
}

.widget .expanded .expand {
    display: block;
}

/* Widget - see more */

.widget-seemore-content {
    display: none;
}

/* Overrides for legacy widgets */

.widget .alt1 {
    background: none;
    border-bottom: 1px dotted #ccc;
}

.widget.tborder {
    margin-top: 1px;
}

.widget .tcat {
    background: #94c566 !important;
    border-radius: 0 !important;
    border: none !important;
    font-size: 1.154em;
    font-weight: normal;
    padding: 6px 10px !important;
}

/* Mobile Styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    .widget .tcat {
        background: #3ba7d2 !important;
        font-size:inherit;
        font-weight: inherit;
        padding: inherit;
    }
}

.widget .widget_edit {
    font-size: 0.846em;
    font-weight: normal;
    padding-right: 0 !important;
}

.widget .widget_edit a {
    color: #ededee !important;
}

#widgets-primary .widget .widget_edit a {
    color: #9ad2eb !important;
}

.widget .widget_buttons {
    padding: 6px 5px !important;
    width: 0;
}

.widget.always-on .widget_buttons .widget_options {
    width: 14px;
}

/* Mobile Styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    .widget table.tborder tr td {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.widget table.tborder tr:first-child td {
    border-top: none;
}

table.tborder.widget > tbody > tr > td {
    border-color: #cecfd0 !important;
    border-radius: 0 !important;
}

/* Widget - All */

#widget-reputation-received .item .details,
#widget-watched-forums .info .action,
#widget-watched-forums .last-post .date {
    display: block;
}

#widget-discussions.wide .widget-content,
#widget-related-discussions .widget-content,
#widget-watched-threads .widget-content,
#widget-reputation-received .item .reason,
#widget-reputation-received .item .details,
#widget-watched-forums .info .action,
#widget-watched-forums .last-post .date {
    font-size: 0.923em;
}


/* Widget - Discussions, Who Quoted Me and Watched Threads */
/* See also: forum.css .thread */

/* Mobile Styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    #primary_content #widget-discussions {
        margin-bottom: 15px;
    }

    #primary_content #widget-discussions .recent-thread a {
        font-size: 13px;
        line-height: 1.2;
    }

    #primary_content #widget-discussions .recent-thread .forum_name,
    #primary_content #widget-discussions .recent-thread .byline{
        font-size: 12px;
        line-height: 1.3;
    }

    #primary_content .region-sidebar-second #widget-discussions .recent-thread a,
    #primary_content .region-sidebar-second #widget-scrolling-discussions .recent-thread a {
        font-size: 16px;
    }
}

#widget-discussions th.title,
#widget-scrolling-discussions th.title {
    width: 60%;
}

#widget-watched-threads th.title {
    width: 50%;
}

#widget-discussions th.forum-link,
#widget-scrolling-discussions th.forum-link,
#widget-watched-threads th.forum-link {
    width: 20%;
}

.lte7 #widget-discussions .widget-content,
.lte7 #widget-scrolling-discussions .widget-content {
    clear: both;
}

#widget-discussions .ctl-tab,
#widget-scrolling-discussions .ctl-tab {
    padding: 0 8px;
}

#widget-discussions .ui-tabs-active .ctl-tab,
#widget-scrolling-discussions .ui-tabs-active .ctl-tab {
    font-size: 0.933em;
}

#widget-discussions .thread_reply_counter,
#widget-scrolling-discussions .thread_reply_counter,
#cont_discussions .forum_name,
#cont_scrolling-discussions .forum_name {
    display: none;
}

#widget-discussions .ctl-tab .title_compact,
#widget-scrolling-discussions .ctl-tab .title_compact {
    display: none;
}

#widget-discussions .ctl-tab .title_wide,
#widget-scrolling-discussions .ctl-tab .title_wide {
    display: inline;
}

#page_home #widget-discussions .ctl-tab,
#page_forumdisplay #widget-discussions .ctl-tab,
#page_forumdisplay #widget-scrolling-discussions .ctl-tab,
#page_quoted #widget-discussions .ctl-tab,
#page_quoted #widget-scrolling-discussions .ctl-tab,
#page_showthread #widget-discussions .ctl-tab,
#page_showthread #widget-scrolling-discussions .ctl-tab,
#page_vbcms #widget-discussions .ctl-tab,
#page_vbcms #widget-scrolling-discussions .ctl-tab {
    padding: 0 11px;
    -webkit-font-smoothing: subpixel-antialiased;
}

#page_home #widget-discussions .ui-tabs-active .ctl-tab,
#page_forumdisplay #widget-discussions .ui-tabs-active .ctl-tab,
#page_forumdisplay #widget-scrolling-discussions .ui-tabs-active .ctl-tab,
#page_quoted #widget-discussions .ui-tabs-active .ctl-tab,
#page_quoted #widget-scrolling-discussions .ui-tabs-active .ctl-tab,
#page_showthread #widget-discussions .ui-tabs-active .ctl-tab,
#page_showthread #widget-scrolling-discussions .ui-tabs-active .ctl-tab,
#page_vbcms #widget-discussions .ui-tabs-active .ctl-tab,
#page_vbcms #widget-scrolling-discussions .ui-tabs-active .ctl-tab {
    font-size: 1em;
}

#widget-discussions .widget-content,
#widget-scrolling-discussions .widget-content {
    overflow: visible;
}

#widget-discussions .update-bar-row > td,
#widget-scrolling-discussions .update-bar-row > td {
    padding: 0;
}

#widget-discussions .update-bar-row + tr > td,
#widget-discussions .update-bar-row + li,
#widget-scrolling-discussions .update-bar-row + tr > td,
#widget-scrolling-discussions .update-bar-row + li {
    border-top: 0;
}

#widget-discussions .update-bar,
#widget-scrolling-discussions .update-bar {
    background-color: #7B63DB;
    color: white;
    cursor: pointer;
    display: none;
    font-size: 13px;
    font-weight: bold;
    line-height: 32px;
    padding: 0;
    text-align: center;
}

#widget-discussions .widget_placeholder_personalisation_unregistered h2,
#widget-scrolling-discussions .widget_placeholder_personalisation_unregistered h2{
    font-family: Arial,sans-serif;
    font-size: 15px;
    font-weight: bold;
    line-height: 1.33;
    text-align: center;
    color: #666666;
    padding: 11px;
}

#widget-discussions .widget_placeholder_personalisation_unregistered p,
#widget-scrolling-discussions .widget_placeholder_personalisation_unregistered p{
    font-family: Arial,sans-serif;
    font-size: 13px;
    line-height: 1.46;
    text-align: center;
    color: #666666;
    padding: 5px;
}

#widget-discussions .widget_placeholder_personalisation_unregistered div.get_started,
#widget-scrolling-discussions .widget_placeholder_personalisation_unregistered div.get_started {
    padding-bottom: 20px;
    padding-top: 10px;
}



/* Mobile Styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    #widget-discussions .update-bar,
    #widget-scrolling-discussions .update-bar {
        font-size: 14px;
        line-height: 35px;
    }
}

#widget-discussions .update-bar:hover,
#widget-scrolling-discussions .update-bar:hover {
    text-decoration: underline;
}

#widget-discussions .update-bar:after,
#widget-scrolling-discussions .update-bar:after {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/widget/update.png") no-repeat 0 0;
    content: "\a0";
    display: inline-block;
    height: 16px;
    margin: 7px 0 0 4px;
    vertical-align: top;
    width: 13px;
}

/* Mobile Styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    #widget-discussions .update-bar:after,
    #widget-scrolling-discussions .update-bar:after {
        margin: 10px 0 0 6px;
    }
}

#widget-discussions.narrow .update-bar,
#widget-scrolling-discussions.narrow .update-bar {
    margin: 0 -7px;
}

/* Mobile Styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    #widget-discussions.narrow .update-bar,
    #widget-scrolling-discussions.narrow .update-bar {
        margin: 0;
    }
}

/* Widget - Featured stories */

#widget-featured-stories .widget-title .cms-button {
    display: inline-block;
    float: none;
    vertical-align: middle;
}

#widget-featured-stories.wide .widget-content,
#widget-latest-content.wide .widget-content {
    border-bottom: 1px solid #3ba7d2;
}

#widget-featured-stories .widget-content {
    padding: 12px 0;
    position: relative;
}

#widget-featured-stories .featured-story {
    display: table-cell;
    font-size: 0.923em;
    padding-left: 20px;
    width: 130px;
    vertical-align: top;
}

#widget-featured-stories.narrow .featured-story {
    padding-left: 13px;
}
/* ## start-device-block desktop ##*/

/* Widget - Moderators */

#widget-moderators {
    margin: 20px 0 0;
}

#widget-moderators .widget-content {
    overflow: visible;
}

#widget-moderators p,
#widget-moderators .moderators-list {
    padding: 8px 0;
}

#widget-moderators p {
    border-bottom: 1px dotted #dbdcdd;
}

#widget-moderators .moderators-list {
    padding-right: 80px;
    position: relative;
}

#widget-moderators .moderators-list a {
    color: #7B63DB;
}

#widget-moderators ul {
    padding-top: 4px;
}

#widget-moderators ul li {
    display: inline;
}

#widget-moderators ul li:before {
    content: ",";
}

#widget-moderators ul li:first-child:before {
    content: "";
    content: none;
}

#widget-moderators ul li strong {
    font-weight: normal;
}


@media screen and (max-width: 480px),
(-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px),
(min-resolution: 120dpi) and (max-width: 480px) {

    /* BAU-150 Hide the moderator widget on mobile */
    #widget-moderators {
        display : none;
    }

}


/* Widget - Moderator Tools */

#widget_moderator-tools .count {
    color: #ff643f;
}

/* Widget - Reputation Received */

#widget-reputation-received .widget-wide-item .item,
#widget-reputation-received .widget-wide-item .from {
    vertical-align: middle;
}

#widget-reputation-received .rep-item {
    border-collapse: collapse;
    display: table;
}

#widget-reputation-received .rep-icon,
#widget-reputation-received .rep-info {
    display: table-cell;
    vertical-align: middle;
}

#widget-reputation-received .rep-icon {
    padding: 0 6px 0 4px;
}

#widget-reputation-received .rep-thumb.has-amount {
    margin-bottom: 1.25em;
}

#widget-reputation-received .rep-thumb .amount {
    margin: 28px 0 0;
    text-align: center;
}

#widget-reputation-received .rep-thumb.personal .amount {
    margin-top: 33px;
}

#widget-reputation-received.narrow .rep-gem {
    top: 1px;
}

#widget-reputation-received.narrow .amount {
    font-weight: bold;
}

#widget-reputation-received.narrow .amount.pos {
    color: #7ba524;
}

#widget-reputation-received.narrow .amount.neg {
    color: #d40514;
}

.lte7 #primary_content #widget-reputation-received li {
    white-space: normal;
}

#widget-reputation-received .reputation-points {
    color: #666;
}

#widget-reputation-received .reputation-points .count {
    color: #505050;
    font-weight: bold;
}

#widget-reputation-received .reputation-points .interact {
    border-top: none;
    float: right;
    padding-top: 0;
}

#widget-reputation-received .widget-action .interact {
    padding-left: 0;
}
/* ## end-device-block ## */

#widget-reputation-received .mobile {
    display: none;
}

/* Widget - Useful resources */

#widget-useful-resources {
    position: relative;
}

#widget-useful-resources .widget-title:after {
    content: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/widget/booky-blob.png");
    position: absolute;
    right: 10px;
    margin-top: -5px;
}

#widget-useful-resources .widget-content {
    padding: 0 10px;
}

#widget-useful-resources .snippet-h2 {
    border-bottom: 1px dotted #dbdcdd;
    font-size: 1em;
    font-weight: normal;
    padding-bottom: 3px;
}

#widget-useful-resources .snippet-h2,
#widget-useful-resources .snippet-p,
#widget-useful-resources .snippet-ils,
#widget-useful-resources .snippet-date,
#widget-useful-resources .snippet-uniguide {
    margin: 8px 0 4px;
}

#widget-useful-resources .useful-groups {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/miscellaneous/useful-groups.png") no-repeat;
    display: inline-block;
    height: 23px;
    line-height: 23px;
    margin: 2px 0 10px;
    padding-left: 37px;
}

/* Widget - Watched Forums */

#widget-watched-forums > .widget-content,
#widget-watched-threads > .widget-content {
    overflow: visible;
}

#widget-watched-forums .widget-title > span,
#widget-watched-threads .widget-title > span {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/statusicons/sprite-forum.png") no-repeat 8px -22px;
    padding-left: 35px;
}

#widget-watched-forums .widget-table {
    table-layout: fixed;
}

#widget-watched-forums th.info {
    width: 35%;
}

#widget-watched-forums th.last-post {
    width: 30%;
}

#widget-watched-forums .forum .info {
    padding-top: 8px;
    padding-bottom: 10px;
}

#widget-watched-forums .forum .title {
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
}

#widget-watched-forums .forum .status-icons {
    vertical-align:top;
    top:0px;
}

#widget-watched-forums .forum .title a {
    white-space: nowrap;
}

#widget-watched-forums .info .action img,
#widget-watched-forums .stop-watching img,
#widget-watched-threads .stop-watching img {
    padding-right: 4px;
    vertical-align: middle;
}

#widget-watched-forums .threadbit-icon {
    vertical-align: middle;
}

/* Mobile Styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {

    #widget-watched-forums .widget-title,
    #widget-watched-threads .widget-title {
        font-size: 1em;
    }

    #widget-watched-forums .widget-table th,
    #widget-watched-threads .widget-table th {
        font-size: 13px;
    }

    #widget-watched-forums .widget-content,
    #widget-watched-threads .widget-content,
    #widget-watched-forums .info .action,
    #widget-watched-threads .info .action,
    #widget-watched-forums a,
    #widget-watched-threads a,
    #widget-watched-forums a:link,
    #widget-watched-threads a:link {
        font-size: 13px;
    }

    #widget-watched-forums .byline,
    #widget-watched-threads .byline,
    #widget-watched-forums .last-post .date,
    #widget-watched-threads .last-post .date{
        font-size: 12px;
    }
}

/* ## start-device-block desktop ##*/

/* Widget - Moderator Tools, PS helper  */
#widget_ps-helper .widget-content,
#widget_moderator-tools .widget-content {
    padding:0px !important;
}
#widget_moderator-tools .widget-content table.tborder > :first-child > tr:first-child > :only-child {
    background-color: transparent !important;
    color: #272727;
    padding: 6px !important;
    font-size: 13px;
    height: inherit !important;
}

#gutter_content .widget > thead > tr > td:last-child {
    border-right:0px !important;
}

#widget_ps-helper.tborder > :last-child > tr:last-child > :only-child,
#widget_moderator-tools.tborder > :last-child > tr:last-child > :only-child {
    border-bottom:1px solid #CECFD0 !important;
}

/* Mobile Styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    #widget_ps-helper.tborder > :last-child > tr:last-child > :only-child,
    #widget_moderator-tools.tborder > :last-child > tr:last-child > :only-child {
        border-bottom: none !important;
    }
}

#widget_ps-helper.tborder > tbody > tr > :last-child,
#widget_moderator-tools.tborder > tbody > tr > :last-child {
    border-right:1px solid #CECFD0 !important;
}

/* Mobile Styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    #widget_ps-helper.tborder > tbody > tr > :last-child,
    #widget_moderator-tools.tborder > tbody > tr > :last-child {
        border-right: none !important;
    }
}

#widget_ps-helper.tborder > tbody > tr > :first-child,
#widget_moderator-tools.tborder > tbody > tr > :first-child {
    border-left:1px solid #CECFD0 !important;
}

/* Mobile Styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    #widget_ps-helper.tborder > tbody > tr > :first-child,
    #widget_moderator-tools.tborder > tbody > tr > :first-child {
        border-left: none !important;
    }
}

#primary_content #widget_ps-helper.tborder > :last-child > tr:last-child > :only-child,
#primary_content #widget_moderator-tools.tborder > :last-child > tr:last-child > :only-child {
    border-bottom:1px solid #44A0D4 !important;
}

#primary_content #widget_ps-helper.tborder > tbody > tr > :last-child,
#primary_content #widget_moderator-tools.tborder > tbody > tr > :last-child {
    border-right:0px !important;
}

#primary_content #widget_ps-helper.tborder > tbody > tr > :first-child,
#primary_content #widget_moderator-tools.tborder > tbody > tr > :first-child {
    border-left:0px !important;
}

#widget-tsrl-promo {
    margin-top: 20px;
}

#widget-tsrl-promo .widget-content{
    border: none;
    padding: 0;
    overflow: visible;
}

#widget-tsrl-promo .widget-content a:nth-of-type(even) {
    margin-right: 0px;
}

#widget-tsrl-promo .widget-content a {
    float: left;
    width: 49.67%;
    margin: 5px 5px 0 0;
}

#widget-tsrl-promo .widget-content .tsrl-box {
    float: left;
    width: 100%;
    height: 74px;
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/learning/tsrl_promo_icons.png") no-repeat;
}

#widget-tsrl-promo .widget-content .tsrl-box.essays {
    background-color: #95C575;
    background-position: 0px -24px;
}
#widget-tsrl-promo .widget-content .tsrl-box.crosswords {
    background-color: #95C575;
    background-position: 0px -330px;
}
#widget-tsrl-promo .widget-content .tsrl-box.mindmaps {
    background-color: #3BB5DF;
    background-position: 0px -125px;
}
#widget-tsrl-promo .widget-content .tsrl-box.revision-notes {
    background-color: #8BC7A5;
    background-position: 0px -225px;
}
#widget-tsrl-promo .widget-content .tsrl-box.quizzes {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/learning/quizzes-icon.png") no-repeat 9px #5D89B7;
}
#widget-tsrl-promo .widget-content .tsrl-box.study-planner {
    background-color: #439FD3;
    background-position: 0px -424px;
}
#widget-tsrl-promo .widget-content .tsrl-box.discussion {
    background-color: #FFB100;
    background-position: 0px -515px;
}
#widget-tsrl-promo .widget-content .tsrl-box p {
    font-size: 32px;
    font-family: "Museo Sans Cyrillic",Arial,Geneva,Lucida,sans-serif !important;
    margin: 12px 0 0 85px;
    line-height: 28px;
    color: #fff;
}

#widget-tsrl-promo .widget-content .tsrl-box p span {
    font-size: 22px;
    font-weight: normal;
    font-family: Arial, Geneva, Lucida, sans-serif !important;
}

#widget-tsrl-promo .widget-content .tsrl-box img {
    float: right;
    position: relative;
    top: -40px;
    right: 10px;
}

#widget-tsrl-promo .widget-content .tsrl-searchbox {
    background-color: #93C95F;
    margin-top: 5px;
    padding: 9px 10px;
}

#widget-tsrl-promo .widget-content .tsrl-searchbox ul {
    margin: 0;
}

#widget-tsrl-promo .widget-content .tsrl-searchbox ul li {
    list-style-type: none;
}

#widget-tsrl-promo .widget-content .tsrl-searchbox p {
    font-size: 18px;
    color: #fff;
    margin: 0;
    float: left;
    padding: 7px 10px 0 0;
    line-height: 17px;
}


@media screen and (max-width: 1280px){
    #widget-tsrl-promo .widget-content a {
        width: 49%;
    }
}

#widget-tsrl-promo .widget-content .tsrl-searchbox fieldset {
    margin: 0;
    padding: 0;
    border: none;
}

#widget-tsrl-promo .widget-content .tsrl-searchbox .search-form .dropdown-holder {
    display: inline-block;
    position: relative;
    left: 4px;
    top: 0;
}

#widget-tsrl-promo .widget-content .tsrl-searchbox .search-form .dropdown {
    background-color: #5c9d2e;
    border: 1px solid #5c9d2e;
    border-right: 0;
    display: inline-block;
    line-height: 32px;
    padding: 0 8px;
    min-width: 80px;
    text-align: center;
    border-radius: 3px 0 0 3px;
}

#widget-tsrl-promo .widget-content .tsrl-searchbox .search-form .dropdown-js {
    z-index: 6;
}

#widget-tsrl-promo .widget-content .tsrl-searchbox .search-form .dropdown-js .value {
    color: #3bb5e0;
    padding: 7px 10px;
    width: 78px;
}

#widget-tsrl-promo .widget-content .tsrl-searchbox  button {
    position: absolute;
    border: 0;
    cursor: pointer;
    margin: 0;
    padding-top: 3px;
    padding-left: 3px;
    background: 0;
}

#widget-tsrl-promo .widget-content .tsrl-searchbox input {
    border-left: 0 !important;
    height: 32px;
    margin: 0;
    padding: 0 6px;
    vertical-align: top;
    border-radius: 0 3px 3px 0;
    width: 50%;
}
/* ## end-device-block ## */

#widget-study-resources.narrow ul.widget-list li.item div {
    display:table-cell;
}

#widget-study-resources ul.widget-list li.item div.content {
    min-height:32px;
    vertical-align:middle;
    padding-left:5px;
    color:#44A0D4;
    background: none;
    border: none;
}


/* Mobile Styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    #widget-study-resources ul.widget-list li.item div.content {
        font-size: 16px;
    }
}




#widget-study-resources .wide a.avatar:hover {
    text-decoration: none;
}

#widget-study-resources ul.widget-list li div span.sprite-icons,
#widget-study-resources.wide .avatar {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/widget/study-resources-icons.png") no-repeat;
    width:32px;
    height:32px;
    display:inline-block;
    position:relative;
    content:" ";
    background-position: 0px -168px;
}

#widget-study-resources ul.widget-list li div span.quizsearch,
#widget-study-resources.wide .avatar.quizsearch { background-position: 0px 0px; }
#widget-study-resources ul.widget-list li div span.grid,
#widget-study-resources.wide .avatar.grid { background-position: 0px -42px; }
#widget-study-resources ul.widget-list li div span.mindmap,
#widget-study-resources.wide .avatar.mindmap { background-position: 0px -84px; }
#widget-study-resources ul.widget-list li div span.quiz,
#widget-study-resources.wide .avatar.quiz { background-position: 0px -126px; }
#widget-study-resources ul.widget-list li div span.resources,
#widget-study-resources.wide .avatar.resources { background-position: 0px -168px; }
#widget-study-resources ul.widget-list li div span.cardset,
#widget-study-resources.wide .avatar.cardset { background-position: 0px -210px; }
#widget-study-resources ul.widget-list li div span.flashcards,
#widget-study-resources.wide .avatar.flashcards { background-position: 0px -252px; }
#widget-study-resources ul.widget-list li div span.notes,
#widget-study-resources.wide .avatar.notes { background-position: 0px -294px; }
#widget-study-resources ul.widget-list li div span.crossword,
#widget-study-resources.wide .avatar.crossword { background-position: 0px -336px; }
#widget-study-resources ul.widget-list li div span.essays,#widget-study-resources ul.widget-list li div span.essay,
#widget-study-resources.wide .avatar.essays { background-position: 0px -378px; }

/* EMBEDDED */

#widget-study-resources.embed .widget-list li {
    list-style: none;
    font: 13px/1.231 arial,helvetica,clean,sans-serif;
}

#widget-study-resources.embed .widget-list {
    padding: 0px!important;
}

#widget-study-resources.embed .date {
    float: right;
    margin-bottom: 5px;
}

#widget-study-resources li.item {
    clear: both;
}

#widget-study-resources .cmsRating {
    display: block!important;
    margin: 5px 0px;
}

/**********************************************************************************************************************************
/* LEARNING SEARCH */

#widget-learn-together-search .widget-content {
    background-color: #93c762;
    color: #666;
    padding-top: 10px;
    margin-top: 10px;
}
#widget-learn-together-search.wide .widget-content {
    overflow: visible;
    height: 180px;
}
.wide .learning-search-widget {
    background: #93c762 url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/learning/learning-search-icons.png") no-repeat;
    background-position: right top;
    padding-left: 10px;
    height: 102px;
}
.learning-search-widget .learning-search-input {
    position: relative;
    width: 100%;
}
.learning-search-widget .interact {
    float: right;
    margin-bottom: 8px;
    margin-right: 13px;
}
.learning-search-widget h2 {
    margin-left: 10px;
    margin-bottom: 0.1em;
    color: #FFF;
    font-weight: normal;
    font-family: "Museo Sans Cyrillic", Arial, Geneva, Lucida, sans-serif !important;
    margin-top: 10px;
    font-size: 20px;
}

.widget.narrow .learning-search-widget h2
{
    margin-top: 2px;
    margin-bottom: 5px;
    line-height: 20px;
}

#cont_learn-together-search {
    border: none;
}

.widget.narrow .learning-search-widget .resource-list li {
    height: 24px;
}

.learning-search-widget p {
    margin-left: 10px;
    color: #FFF;
    font-weight: normal;
    font-size: 13px;
}
.learning-search-widget .search-form .dropdown {
    background-color: #5c9d2e;
    border: 1px solid #5c9d2e;
    border-right: 0;
    display: inline-block;
    line-height: 32px;
    padding: 0 8px;
    min-width: 80px;
    text-align: center;
    border-radius: 3px 0 0 3px;
}
.learning-search-widget .search-form .dropdown-holder {
    display: inline-block;
    position: relative;
    left: 4px;
    top: 0;
}
.learning-search-widget .search-form a {
    display: inline-block;
    text-transform: lowercase;
    vertical-align: top;
    width: 1px;
}
.learning-search-widget .nojs .search-form .dropdown {
    width: 80px;
}
.learning-search-widget .search-form .dropdown-js {
    z-index: 6;
}
.learning-search-widget .search-form .dropdown-js .value {
    color: #3bb5e0;
    padding: 7px 10px;
    width: 78px;
}
.learning-search-widget .search-form .dropdown-js .value:after {
    background-position: -96px center;
}
.learning-search-widget .search-form fieldset {
    background-color: #93c762;
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left:0;
    position: relative;
    border-radius: 0;
}
.wide .learning-search-widget .search-form fieldset {
    width: 400px;
}
#widget-learn-together-search .learning-search-widget .search-form input {
    border-left: 0 !important;
    height: 32px;
    margin: 0;
    padding: 0 6px;
    vertical-align: top;
    border-radius: 0 3px 3px 0;
}
.narrow .learning-search-widget .search-form input {
    width: 300px;
    margin-left: 0;
}
.wide .learning-search-widget .search-form input {
    width: 245px;
}
.learning-search-widget button {
    position: absolute;
    border: none;
    cursor: pointer;
    margin: 0;
    padding-top: 3px;
    padding-left: 3px;
    background: none;
}
.narrow .learning-search-widget .resourse-list-holder {
    padding-left: 10px;
    background: #93c762 url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/learning/widget-icons.png") no-repeat;
    background-position: right 12%;
    width: 283px;
}

.narrow .learning-search-widget a.interact {
    bottom: 14px;
}

.learning-search-widget .resource-list li {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/learning/widget-tick.png") no-repeat;
    padding-left: 25px;
    text-decoration: none;
    color: #FFF;
}
.wide .learning-search-widget .resource-list li {
    line-height:1.5em;
    float: left;
}
.narrow .learning-search-widget .resourse-list-holder ul.resource-list li {
    height: 22px;
    vertical-align: middle;
    display: list-item;
    width: 200px;
}
.learning-search-widget .resourse-list-holder .description {
    padding: 0;
    margin-bottom: 10px;
    padding-left: 90px;
}
.wide #resource-list-holder {
    width: 490px;
    overflow: hidden;
    padding-left: 10px;
}
.wide .learning-search-widget .resource-list ul {
    margin-bottom:20px;
    overflow:hidden;
    border-top:1px solid #ccc;
}
.wide .learning-search-widget .resource-list li {
    line-height: 16px;
    float: left;
    width: 27%;
    padding-bottom: 8px;
}
.resource-bottom-item {
    margin-top: 10px;
}
.learning-search-widget #resource-bottom-wrapper {
    position: relative;
}
.resource-bottom-middle {
    position: absolute;
    top: 7px;
    right: 7px;
}

/* Reply Stream */
#widget-latest_replies {
    width: 50%;
    min-width: 300px;
    background-color: white;
}

#widget-latest_replies .lr_section {
    position: relative;
    height: 50%;
    padding: 15px 0px;
    margin-bottom: 10px;
}

#widget-latest_replies #lr_replied {
    clear: both;
}

#widget-latest_replies.show_quoted.show_replied #lr_quoted {
    border-bottom: 1px dotted #999;
}

#widget-latest_replies .reply_thread {
    margin: 3px 0px;
    font-weight: 700;
    color: #333;
}

#widget-latest_replies .reply_info {
    margin: 0px 10px 10px;
}

#widget-latest_replies .reply_info img {
    width: 35px;
    float: left;
    border: 1px solid #DBDCD8;
}

#widget-latest_replies .reply_post {
    margin: 10px;
    overflow-wrap: break-word;
    margin: 0px 10px;
}

#widget-latest_replies .reply_post:after {
    content:url( //static.thestudentroom.co.uk/67e2a42d66799/forum/images/widget/bubble-flick.png );
}

#widget-latest_replies .reply_post span.read_more {
    float: right;
    margin: 3px 0px;
}

#widget-latest_replies .reply_info_text {
    margin-left: 40px;
}

#widget-latest_replies .reply_reply {
    margin: 0px 10px;
}

#widget-latest_replies .reply_reply div {
    position: relative;
}

#widget-latest_replies .reply_reply div:after {
    content:url( //static.thestudentroom.co.uk/67e2a42d66799/forum/images/widget/bubble-flick2.png );
    position: absolute;
    right: 0;
    bottom: -10px;
}

#widget-latest_replies .reply_reply.expanded div:after {
    content: '';
}

#widget-latest_replies textarea {
    border-radius: 0;
    border: 1px solid #ccc;
    box-sizing: border-box;
    font-size: 1em;
    height: 2.8em; /* 1.385 * 8 lines */
    margin-top: 6px;
    padding: 8px;
    resize: vertical;
    width: 100%;
    resize: none;
    box-sizing: content-box !important;
}
#widget-latest_replies .expanded {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: -128px;
}

#widget-latest_replies .expanded > div {
    text-align: center;
}

#widget-latest_replies .expanded textarea {
    height: 7em;
    padding-bottom: 35px;
    margin: 0 !important;
    display: inline-block;
}


#widget-latest_replies .reply_text {
    background-color: #f1fafc;
    padding: 15px 5px;
}

#widget-latest_replies .reply_text > div {
    margin: 0 5px;
    position: relative;
    top: 25%;
    overflow: hidden;
}

#widget-latest_replies .reply_buttons {
    display: none;
    top: 0;
    height: 25px;
    background-color: white;
    border: 1px solid #ccc;
    border-top: 1px dotted #ccc;
    padding: 5px;
}

#widget-latest_replies .reply_buttons a {
    float: right;
}

#widget-latest_replies .reply_thanks {
    margin: 25px auto 0px;
    background-color: #EEEEE4;
    width: 85%;
    height: 80px;
}

#widget-latest_replies .reply_thanks > div {
    position: relative;
    top: 30%;
}

#widget-latest_replies .reply_thanks_link {
    text-align: center;
}

#widget-latest_replies .reply_thanks_text {
    text-align: center;
    font-weight: 700;
    color: #333;

}

#widget-latest_replies.widget .widget-content {
    overflow: visible;
}

#widget-latest_replies .reply_expand {
    display: none;
    width: 300px;
    max-height: 400px;
    padding: 10px;
    border: 1px solid #cecfd0;
    position: absolute;
    background-color: white;
    z-index: 2;
}

#widget-latest_replies .reply_expand div {
    overflow: auto;
}

#widget-latest_replies .reply_expand:before {
    border: 7px solid;
    border-color: transparent transparent #C4C6C6 transparent;
    content: ' ';
    height: 0;
    position: absolute;
    left: 10px;
    top: -15px;
    width: 0;
}

#widget-latest_replies .reply_expand:after {
    border: 7px solid;
    border-color: transparent transparent #fff transparent;
    content: ' ';
    height: 0;
    position: absolute;
    left: 10px;
    top: -14px;
    width: 0;
}

#widget-latest_replies .advanced-button {
    margin-left: 12px;
}

#widget-latest_replies .reply_reply .action-reply.fake {
    bottom: 32px;
    float: right;
    right: 7px;
    position: relative;
}

/* Widget AJAX placeholder */

.widget-ajaxplaceholder {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/images/widget/placeholder.png") no-repeat center 30px;
    border: 1px solid #cecfd0;
    text-align: center;
    padding: 100px 25px 25px;
    margin: 0 0 16px;
}

.widget-ajaxplaceholder .title {
    color: #c2c3c4;
    font-size: 1.333em;
}

.widget-ajaxplaceholder .alternative {
    color: #666;
    display: block;
    font-size: 1.083em;
    margin-top: 16px;
}

.widget-ajaxplaceholder .alternative a {
    color: #21669c;
}

#widget-scrolling-discussions {
    display:block;
}

/* Follow members */

.follow-feed .user {
    margin-top: 6px;
}

tbody.follow-feed .user {
    width: 160px;
}

.follow-feed .user > a {
    color: #666;
    font-weight: bold;
    text-decoration: none;
}

.follow-feed .user > a > .avatar {
    border: 1px solid #c3c5c6;
    float: left;
    height: 30px;
    margin-right: 7px;
    width: 30px;
}

.follow-feed .user > .post-count,
.follow-feed .user > .date {
    display: block;
    font-size: 0.923em;
    margin-top: 1px;
}

tbody.follow-feed .user > .date {
    display: none;
}

.follow-feed .last-post {
    text-align: right;
}

/* Useful resources mobile */
.useful-resources-mobile {
    display: none;
}

/* ## start-device-block mobile ##*/
@media screen and (max-width: 480px),(-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px),(min-resolution: 120dpi) and (max-width: 480px) {

    .useful-resources-mobile {
        display: inline-block;
        width: 100%;
        margin: 10px 0;
        border-bottom: 2px solid #95c676;
    }

    .useful-resources-mobile .title {
        line-height: 30px;
        background-color: #95c676;
        padding: 0 10px;

        color: white;
        font-size: 1.25em;
        font-family: "Museo Sans Cyrillic", Arial, Geneva, Lucida, sans-serif;

    }

    .useful-resources-mobile .content {
        display : none;
        margin: 10px 0;
    }

}
/* ## end-device-block ## */

/* ## start-device-block desktop ##*/

/* Poll widget */
#widget-poll {
    position: relative;
}


#widget-poll .poll-icon {
    position: absolute;
    top: 5px;
    left: 8px;
    display: none;
}

#widget-poll .title {
    font-size: 13px;
    font-weight: bold;
    padding: 10px 0;
    border-bottom: 1px dotted #ccc;
}

/* Mobile Styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    #widget-poll .title {
        font-size: inherit;
        line-height: 22px;
        padding: 6px 0;
    }
}

/* Mobile Styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    #widget-poll .widget-content {
        overflow: visible;
    }

    .region-sidebar-second .widget.tsr-widget:not(#widget-uni-connect) {
        font-size: 15px;
    }
}

#widget-poll .poll-result-option {
    border-bottom: 1px dotted #ccc;
    padding: 0 0 7px;
}

#widget-poll .poll-result-option .poll_result_question {
    font-weight: bold;
    padding: 7px 0 5px;
}

#widget-poll .poll-result-option .poll_results_votes,
#widget-poll .poll-result-option .poll_results_percent {
    display: inline;
}

#widget-poll .poll-result-option .poll_results_bar {
    padding-bottom: 5px;
}

#widget-poll .poll-result-option .poll_results_bar > div {
    background-color: #7B63DB !important;
}

#widget-poll .result-footer {
    line-height: 1;
    padding: 15px 0;
}

#widget-poll .result-footer strong {
    font-weight: bold;
}

#widget-poll .result-footer .link {
    float: right;
    margin-top: -6px;
}

#widget-poll .result-footer .link a {
    color: #7B63DB;
}

#widget-poll .poll_results_bar div {
    height: 15px !important;
}

#widget-poll .vote li {
    line-height: 16px;
    padding: 4px 0;
}

#widget-poll .vote li input {
    margin-bottom: 0 !important;
    margin-right: 7px;
}

#widget-poll .vote .vote-footer {
    padding: 6px 0;
    text-align: right;
}

#widget-poll .vote .vote-footer .interact {
    font-size: 12px;
}

#widget-poll .vote ul {
    border-bottom: 1px dotted #ccc;
    padding: 3px 0;
}

#widget-poll .pollanchor {
    display: block;
    pointer-events: none;
}

#widget-poll .pollanchor:before {
    display: block;
    content: "\a0";
    height: 80px;
    margin: -80px 0 0;
    visibility: hidden;
}

#widget-poll .vote .poll-option-item {
    align-items: center;
    display: flex;
}

#widget-poll .vote .poll-option-text {
    font-size: 13px;
}

/* Sidebar */

.widget.expandable .widget-title > div {
    cursor: pointer;
    position: relative;
}

.widget.expandable .widget-title > div:before {
    border: 8px solid;
    border-color: white transparent transparent;
    content: "\a0";
    height: 0;
    position: absolute;
    top: 12px;
    right: 10px;
    width: 0;
}

/* Mobile Styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    .widget.expandable .widget-title > div:before {
        background: url("//static.thestudentroom.co.uk/67e2a42d66799/images/widget/title-expand.svg") no-repeat 0 0 / contain;
        height: 9px;
        top: 13px;
        right: 9px;
        width: 18px;
        border: none;
    }
}


.widget.expandable.expanded .widget-title > div:before {
    border-color: transparent transparent white;
    top: 4px;
}

/* Below posts */

#widget-useful-resources .widget-title:after {
    display: none;
}


#widget-useful-resources.expanded .widget-title:after {
    display: block;
}

/* Mobile Styles */
@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi) {
    .widget.expandable.expanded .widget-title > div:before {
        background-image: url("//static.thestudentroom.co.uk/67e2a42d66799/images/widget/title-contract.svg");
        border: none;
        top: 13px;
    }
}



/* Countdown clock - BAU-221 */
#tsr-countdown {
    background: #93c67c;
    background: -moz-linear-gradient(45deg, #93c67c 0%, #8dc79f 100%);
    background: -webkit-linear-gradient(45deg, #93c67c 0%,#8dc79f 100%);
    background: linear-gradient(45deg, #93c67c 0%,#8dc79f 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#93c67c', endColorstr='#8dc79f',GradientType=1 );
    color: #fff;
    padding: 12px 10px;
    text-align: center;
    box-sizing: border-box;
    height: 175px;
    width: 300px;
    margin-bottom: 15px;
}
#tsr-countdown a {
    color: #fff;
    text-decoration: none;
}
#tsr-countdown span {
    display: inline-block;
    width: 33.3%;
    position: relative;
}
#tsr-countdown .tsr-countdown-clock,
#tsr-countdown .tsr-countdown-dhm {
    padding: 0 20px;
}
#tsr-countdown .tsr-countdown-clock span {
    font-size: 4.4em;
}
#tsr-countdown .tsr-countdown-clock span:not(:last-child):after {
    content: ":";
    position: absolute;
    left: 93%;
    bottom: 13px;
    font-size: 0.7em;
    font-family: Arial, Geneva, Lucida, sans-serif;
}
#tsr-countdown h2 {
    font-size: 1.7em;
}
#tsr-countdown .tsr-countdown-dhm {
    font-size: 1.3em;
    margin: -9px 0 10px;
}
#tsr-countdown .interact {
    text-transform: none;
    font-size: 1.3em;
}
#tsr-countdown .tsr-countdown-submit {
    height: 36px;
    background: url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/buttons/countdown_button.png) no-repeat;
    position: relative;
    width: 238px;
    margin: 0 auto;
}
#tsr-countdown .tsr-countdown-submit p {
    font-size: 1.2em;
    position: relative;
    top: 8px;
    text-align: left;
    padding-left: 60px;
}
#tsr-countdown .tsr-countdown-submit .arrow-right:after {
    font-size: 11px;
    margin-left: 10px;
}

/* Related discussions */

.related-discussions {
    font-family: Gibson, "Myriad Web Pro", "Myriad Web", "Myriad Std", "Frutiger LT Std", "Segoe UI", Arial, sans-serif;
    font-size: 16px;
    margin: 20px 0 35px;
}

.related-discussions > .title {
    color: #666;
    font-weight: bold;
    line-height: 38px;
}

.related-discussions > .threads {
    border: solid #e2e3e4;
    border-width: 2px 0;
}

.related-discussions > .threads > ol {
    list-style: none;
    margin: 0 -5px -1px;
    overflow: hidden;
}

.related-discussions > .threads > ol > li {
    border-bottom: 1px dotted #e2e3e4;
    box-sizing: border-box;
    color: #7B63DB;
    float: left;
    line-height: 20px;
    overflow: hidden;
    padding: 9px 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 50%;
}

.related-discussions > .threads > ol > li > p {
    overflow: hidden;
    text-overflow: ellipsis;
}

.related-discussions > .threads > ol > li > a {
    color: #7B63DB;
}

.related-discussions > .threads > ol > li span {
    display: block;
    color: #666;
    font-size: 0.8em;
}

.related-discussions > .threads > ol > li .student-stars {
    transform: scale(0.75);
    position: relative;
    left: -11px;
}

@media screen and (max-width: 480px),(-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px),(min-resolution: 120dpi) and (max-width: 480px) {
    .related-discussions > .threads > ol {
        margin-left: 0;
        margin-right: 0;
        max-height: 195px;
    }

    .related-discussions.related-discussions-courses > .threads > ol {
        max-height: none;
    }

    .related-discussions.related-discussions-courses > .threads > ol > li {
        overflow: auto;
        white-space: normal;
    }

    .related-discussions.related-discussions-courses > .threads > ol > li:nth-child(n+6) {
        display: none;
    }

    .related-discussions > .threads > ol > li {
        float: none;
        padding-left: 0;
        padding-right: 0;
        width: auto;
    }
}

div#dpc_personalised_blank {
    padding-top: 10px;
    padding-bottom: 10px;
}



.widget .widget-title .create_personalised,
.widget .widget-title .edit_personalised {
    cursor: pointer;
    float: right;
    font-weight: normal;
    margin-right: 7px;
}

@media screen and (min-width: 481px) {
    .traditional_pos {
        position: relative!important;
        top: 0px !important;
        left: 0px !important;
    }
    .traditional_pos_narrow {
        position: relative !important;
        top: 0px !important;
        left: 0px !important;
    }

    .dropdown_pos {
        position: absolute !important;
        top: 8px !important;
        left: 245px !important;
    }
    .dropdown_pos_narrow {
        position: relative !important;
        top: -76px !important;
        left: 157px !important;
    }

    /*
    a.interact.arrow-right.edit_personalised_unregistered {
    //    position: absolute!important;
    //    top: 9px!important;
    //    left: 240px!important;
    }
    */

    .dpc-form.wide {
        position: relative;
        top: -22px;
//        left: 338px;
        left: 354px;
    }
    .discussions_dropdown-wide {
        max-height : 55px;
    }
    span.dpc-wide-heading {
        position: relative;
        top: 11px;
        left: 10px;
        margin-left: 0px!important;
    }
    .dpc-form .select select {
        border: none!important;
        filter: alpha(opacity = 0);
        height: 29px;
        margin: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        margin-bottom: 0px!important;
        padding-top: 0px!important;
    }
    .dpc-form  .select{
        width: 300px;
        background-color: #fff;
        border: 1px solid #b2b2b2;
        color: #444;
        display: block;
        height: 33px;
        line-height: 33px;
        overflow: hidden;
        position: relative;
    }
    .dpc-form .select:before {
        background: url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/miscellaneous/sprite-ordered-form.png) no-repeat;
    }

    .dpc-form .select:before {
        background-color: #d4d4cd;
        background-position: 8px -110px;
        border-left: 1px solid #acaca7;
        content: "\a0";
        float: right;
        height: 100%;
        width: 30px;
    }



    .dpcwide {
        width: 250px !important;
    }
    .dpcnarrow {
        width: 95% !important;
        margin-left: 2%;
        margin-bottom: 2%;
    }


    .dpc_text {
        font-weight: normal;
    }

    .discussions_dropdown-wide select {
        border: none!important;
        filter: alpha(opacity= 0);
        height: 55px;
        margin: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        color: black;
    }
    .discussions_dropdown-narrow select,
    .scrolling-discussions_dropdown-narrow select {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        padding-bottom: 5px;
        margin-bottom: 5px;
        padding-top: 5px;
        max-width:400px;
    }

    .discussions_dropdown span,
    .scrolling-discussions_dropdown span {
        margin-left: 3%;
        padding-bottom: 5px;
        margin-bottom: 5px;
        padding-top: 5px;
    }

}

@media screen and (max-width: 480px) {
    .widget .widget-title .create_personalised,
    .widget .widget-title .edit_personalised {
        position: absolute;
        float: none;
        left: 50%;
        top: 45px;
        line-height: normal;
        margin: 0;
        transform: translateX(-50%);
        background-color: #2eb3e2;
        padding: 3px 4px 2px;
        z-index: 40;
    }

    #widget-discussions.widget-no-feed .widget-content > table tr.thread:nth-child(2),
    #discussions-personalised tr.thread:nth-child(2) {
        padding-top: 32px;
    }

    #discussions-personalised tr.thread:nth-child(2) td {
        border: none;
    }

    #widget-discussions.widget-no-feed .widget-content > table tr.update-bar-row,
    #discussions-personalised tr.update-bar-row {
        position: relative;
        z-index: 45;
    }

    .traditional_pos {
        position: relative !important;
        top: 0px !important;
        left: 0px !important;
    }

    .traditional_pos_narrow {
        position: relative !important;
        top: 0px !important;
        left: 0px !important;
    }

    .dropdown_pos {
        position: absolute !important;
        top: -4px !important;
        left: 245px !important;
    }

    .dropdown_pos_narrow {
        position: relative !important;
        top: -76px !important;
        left: 157px !important;
    }

    /*a.interact.arrow-right.edit_personalised_unregistered {
        position: absolute!important;
        top: 9px!important;
        left: 240px!important;
    }*/

    .dpc-form .select {
        width: 300px;
        background-color: #fff;
        border: 1px solid #b2b2b2;
        color: #444;
        display: block;
        height: 33px;
        line-height: 33px;
        overflow: hidden;
        position: relative;
    }

    .dpc-form.wide {
        position: relative;
        top: -5px;
        left: 10px;
    }

    .dpc-form .select:before {
        background: url(//static.thestudentroom.co.uk/67e2a42d66799/forum/images/miscellaneous/sprite-ordered-form.png) no-repeat;
    }

    .dpc-form .select:before {
        background-color: #d4d4cd;
        background-position: 8px -110px;
        border-left: 1px solid #acaca7;
        content: "\a0";
        float: right;
        height: 100%;
        width: 30px;
    }

    .dpcwide {
        width: 250px !important;
    }

    .dpcnarrow {
        width: 95% !important;
        margin-left: 2%;
        margin-bottom: 2%;
    }

    .dpc_text {
        font-weight: normal;
    }

    .discussions_dropdown-wide select   {
        border: none !important;
        filter: alpha(opacity=0);
        height: 32px;
        margin: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        color: black;
    }

    .discussions_dropdown-narrow select,
    .scrolling-discussions_dropdown-narrow select{
        border: none !important;
        filter: alpha(opacity=0);
        height: 23px;
        margin: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;

        color: black;

        width: 100% !important;
        margin-left: 2%;
    }

    .discussions_dropdown-narrow select,
    .scrolling-discussions_dropdown-narrow select {

        margin-left: 0px;
        margin-right: 5%;
        padding-bottom: 5px;
        margin-bottom: 5px;
        padding-top: 5px;
        max-width: 400px;
    }

    .discussions_dropdown span,
    .scrolling-discussions_dropdown span {
        margin-left: 3%;
        padding-bottom: 5px;
        margin-bottom: 5px;
        padding-top: 5px;
    }

    .dpc_dropdown {
        overflow:visible!important;
    }
}


/* Stats Widget */
.widget.stats {
    border: 1px solid #cccccc;
    padding: 10px;
}

.widget.stats div.stat-block {
    float: left;
    width: calc(50% - 1px);
    text-align: center;
    margin: 5px 0;
}

.widget.stats span.glyph {
    position: relative;
    top: 3px;
}

.widget.stats div.clear {
    clear: both;
}

.widget.stats span.glyph:before {
    color: #94C565;
}

.widget.stats h3 {
    font-weight: bold;
    font-size: 16px;
}

.widget.stats div.stat-block h3 {
    margin-bottom: 5px;
    font-size: 18px;
}

.widget.stats div.stat-block:first-child {
    border-right: 1px solid #C5C5C5;
}

.widget.stats h3.find-exam-discussion {
    text-align: center;
    margin: 10px 0 5px 0;
    padding-top: 10px;
    border-top: 1px solid #C5C5C5;
}

.widget.stats h3.find-exam-discussion > a {
    color: #444444;
}

.widget.stats span.circle {
    display: inline-block;
    position: relative;
    top: 1px;
    border: none;
    width: 19px;
    height: 19px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color: #94C565;
}

.widget.stats span.circle:after {
    color: #ffffff;
    height: 19px;
    width: 19px;
    font-size: 10px;
    text-align: center;
    padding-left: 1px;
    margin-top: 4px;
    content: "\e91b";
}



/* Stats Clearing Widget */
.widget.stats-clearing .widget-title {
    display: none;
    background-color: #ff4aa9;
    color: #fff;
}

.widget.stats-clearing .widget-content {
    border: 1px solid #cecfd0;
    padding: 10px 0;
}

.widget.stats-clearing .online-users {
    box-sizing: border-box;
    float:left;
    text-align: center;
    padding: 5px;
    border-right: dotted 1px #9b9b9b;
    width: 45%;
}

.widget.stats-clearing .custom-metric {
    box-sizing: border-box;
    float:left;
    text-align: center;
    padding: 5px;
    width: 55%;
}

.widget.stats-clearing span.glyph:before {
    color: #44A0D4;
    font-size: 16px;
    padding: 1px 5px 0 0;
}

.widget.stats-clearing h3 {
    font-family: "Gibson", Arial, Geneva, Lucida, sans-serif !important;
    font-weight: bold;
    font-size: 18px;
    margin: 0;
}

.widget.stats-clearing .text {
    font-family: "Gibson", Arial, Geneva, Lucida, sans-serif !important;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.widget.stats-clearing .widget-content .main:after {
    content: "";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}

.widget.stats-clearing .widget-action {
    display: none;
    clear: both;
    text-align: center;
    padding: 10px 10px 2px;
    margin-top: 10px;
    position: relative;
}

.widget.stats-clearing .widget-action .btn {
    font-size: 15px;
    font-weight: 600;
    color: #ffffff;
    background-color: #ff4aa9;
    border-radius: 12px;
    display: inline-block;
    line-height: 16px;
    margin: 0;
    padding: 2px 2px 2px 8px;
    font-family: Gibson, Arial, sans-serif;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: none!important;
}

.widget.stats-clearing .widget-action .btn:after {
    color: #ffffff;
    height: 19px;
    width: 12px;
    font-size: 10px;
    text-align: center;
    padding-left: 1px;
    margin-top: 4px;
    content: "\e91b";
    font-family: "tsrglyph";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
}

.widget.stats-clearing .widget-action .btn:hover {
    text-decoration: none;
    background-color: #ec4a9f;
}

/* Stats Clearing Widget - Display Full Widget */
.widget.stats-clearing.full .widget-title {
    display: block;
}

.widget.stats-clearing.full .widget-content {
    border-top: 1px solid #fff;
}

.widget.stats-clearing.full .widget-action {
    display: block;
}

/* Stats Clearing Widget - Display Bunting Widget */
.widget.stats-clearing.bunting {
    background: url("//static.thestudentroom.co.uk/67e2a42d66799/widget/image/bunting.svg") repeat 0 0;
    background-size: 125px;
    background-color: #fff;
}

.widget.stats-clearing.bunting .widget-title {
    display: block;
    background-color: transparent;
    color: #555;
    font-size: 18px;
    text-align: center;
    padding-top: 10px;
}

.widget.stats-clearing.bunting .widget-content {
    border: none;
}

.widget.stats-clearing.bunting .widget-content .main {
    border: 1px solid #f3f3f3;
    background-color: #fff;
    margin: 0 8px;
}

.widget.stats-clearing.bunting .widget-action:before {
    display: none;
}

.widget.stats-clearing.bunting .widget-action {
    display: block;
    margin-top: 0;
}

/* Stats Clearing Widget - Display Slim Widget */
.widget.stats-clearing.slim .widget-content {
    border: none;
}

.widget.stats-clearing.slim .widget-action {
    display: block;
}

/* Homepage Guest Clearing Widget */
.clearing-guest {
    padding: 15px 0px 0px 15px;
    width: 50%;
    text-align: center;
}

.clearing-guest .aaq-clearing__wrapper {
    border: 2px solid #f3f3f3;
    margin-bottom: 5px;
}

.clearing-guest .large-title svg {
    margin-bottom: -3px;
    margin-left: -8px;
}

.clearing-guest .large-title svg path{
    fill: #44A0D4;
}

.clearing-guest #ask-clearing-question .gizmo-askaquestion .legend .large-title {
    font-size: 17px;
}

.clearing-guest .gizmo-askaquestion .ordered-form .input-text {
    font-size: 15px;
}

.clearing-guest .gizmo-askaquestion .message .input-text {
    max-height: 132px;
}

.clearing-guest #ask-clearing-question form {
    margin: 10px;
}

.clearing-guest .stats-clearing__wrapper .title {
    display: none;
}

.clearing-guest .stats-clearing__wrapper .widget-content {
    border: 2px solid #f3f3f3;
    margin-bottom: 15px;
}

.clearing-guest #WidgetsStatsClearing {
    margin: 0px;
}

.clearing-guest .clearing-guest.bubble {
    display: inline;
    padding: 4px 12px;
}

/* HomePage Clearing Widget Group */
.widget-clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

#clearing-widgetgroup {
    display:none;
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 980px;
    width: 100%;
    background: #fff;
    padding: 0;
}

#clearing-widgetgroup.show {
    display: block;
}

#clearing-widgetgroup .mobile-clearing {
    display: none;
}

#clearing-widgetgroup > .title {
    text-align: center;
    margin: 0;
    padding: 20px 0 15px;
}

#clearing-widgetgroup > .title h1 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1;
    color: #4a4a4a;
    display: inline-block;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#clearing-widgetgroup .bubble {
    font-size: 15px;
}

#clearing-widgetgroup .large-title svg path {
    fill: #44A0D4;
    padding-top: 3px;
}

#clearing-widgetgroup .group-one,
#clearing-widgetgroup .group-two {
    width: 100%;
    box-sizing: border-box;
}

#clearing-widgetgroup .gizmo-askaquestion .ordered-form .forum {
    margin-top: 3px;
    font-size: 15px;
}

#clearing-widgetgroup .gizmo-askaquestion {
    margin: 0;
    padding: 10px;
    background-color: #fff;
}

.member > #clearing-widgetgroup .gizmo-askaquestion {
    display: none;
}

#clearing-widgetgroup .logo-clearing__wrapper {
    display: none;
}

/* Mobile Only */
@media screen and (max-width: 480px) {

    #clearing-widgetgroup.show {
        margin-top: 10px;
    }

    #clearing-widgetgroup .group-one {
        display: none;
    }

    #clearing-widgetgroup > .title {
        background-color: #3ba7d2;
        height: 35px;
        text-align: left;
        display: flex;
        margin: 0 10px;
        padding: 0 10px;
    }

    #clearing-widgetgroup > .title h1 {
        font-size: 15px;
        font-weight: bold;
        line-height: 35px;
        color: white;
    }

    #clearing-widgetgroup .stats-clearing {
        margin-bottom: 0;
    }

    #clearing-widgetgroup .mobile-clearing {
        margin: 10px;
        display: inline-block;
    }

    #clearing-widgetgroup .stats-clearing__wrapper {
        text-align: center;
        margin: 0 10px;
        border: solid 2px #f3f3f3;
        border-top: 0;
        padding-top: 10px;
    }

    #clearing-widgetgroup .widget.stats-clearing .title {
        display: none;
    }

    #clearing-widgetgroup .widget.stats-clearing .widget-content {
        border: none;
        padding: 0 10px;
    }

    #clearing-widgetgroup .widget.stats-clearing .widget-content .main {
        border: 2px solid #f3f3f3;
        background-color: #fff;
    }

    #clearing-widgetgroup .widget.stats-clearing .widget-action:before {
        display: none;
    }

    #page-columns #widgets-gutter #WidgetsStatsClearing 
    .widget-content .widget-action {
        display: block;
    }

    #pre-posts-content .widget.stats-clearing .widget-title > div,
    #primary_content .widget.stats-clearing .widget-title > div,
    .widget_column .widget.stats-clearing .widget-title > div {
        font-size: 20px;
        line-height: 1.2;
        padding: 0;
    }

    #pre-posts-content .widget.stats-clearing .widget-content .glyph:before,
    #primary_content .widget.stats-clearing .widget-content .glyph:before {
        color: #ff4aa9;
    }

    #pre-posts-content .widget.stats-clearing .widget-action,
    #primary_content .widget.stats-clearing .widget-action {
        display: none;
    }
}

/* Tablet Up */
@media screen and (min-width: 481px) {
    #clearing-widgetgroup {
        border-bottom: 10px solid #f3f3f3;
        padding: 0px 20px 10px;
    }

    #clearing-widgetgroup > .title h1 {
        font-size: 30px;
    }


    #clearing-widgetgroup .group-one {
        float: left;
        width: 65%;
        padding-right: 20px;
    }

    #clearing-widgetgroup .group-two {
        float: right;
        width: 35%;
    }

    #clearing-widgetgroup .gizmo-askaquestion {
        border: 2px solid #f3f3f3;
        padding: 20px;
    }

    .member > #clearing-widgetgroup .gizmo-askaquestion {
        display: block;
    }

    #clearing-widgetgroup .logo-clearing__wrapper {
        background-color: #fff;
        border: 2px solid #f3f3f3;
        box-sizing: border-box;
        display: block;
        margin-bottom: 15px;
        padding: 24px 20px 24px;
        text-align: center;
    }

    #clearing-widgetgroup .logo-clearing__wrapper h3 {
        color: #555;
        font-family: "Gibson", Arial, Geneva, Lucida, sans-serif !important;
        font-size: 18px;
        margin: 0;
    }

    #clearing-widgetgroup .logo-clearing__wrapper .title {
        color: #44A0D4;
        font-weight: bold;
        padding-bottom: 5px;
    }

    #clearing-widgetgroup .logo-clearing__wrapper .content {
        padding-bottom: 10px;
    }

    #clearing-widgetgroup .widget.stats-clearing .widget-content {
        border: 2px solid #f3f3f3;
        background-color: #fff;
    }

    #clearing-widgetgroup .widget.stats-clearing .title {
        display: none;
    }


    /* Sidebar Widget - Desktop */
    .widget_column .widget.stats-clearing .widget-title {
        display: block;
    }

    .widget_column .widget.stats-clearing .widget-content {
        border-top: 1px solid #fff;
    }

    .widget_column .widget.stats-clearing .widget-action {
        display: block;
    }
}

#ask-clearing-question > .gizmo-askaquestion> .legend > .large-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
}

#ask-clearing-question > .gizmo-askaquestion .ordered-form .input-text {
    color: #444;
    font-style: normal;
}

#ask-clearing-question > .gizmo-askaquestion .ordered-form textarea.input-text {
    height: 92px;
    min-height: 92px;
}

.new-widget {
    color: #666;
}

.new-widget > .title {
    align-items: center;
    background-color: #94c566;
    color: white;
    display: flex;
    height: 32px;
    justify-content: space-between;
    padding: 0 10px;
}

.new-widget > .title > h3 {
    font-size: 15px;
    font-weight: bold;
}

.new-widget > .title > a {
    color: white;
}

.new-widget .item-list {
    border: solid #cecfd0;
    border-width: 0 1px 1px;
    padding: 0 6px;
}

.new-widget .item-list > li {
    border-bottom: 1px dotted #dbdcdd;
    padding: 8px 0;
}

.new-widget .item-list > li:last-child {
    border-bottom: none;
}

.new-widget .item-list > .featured-img {
    display: flex;
}

.new-widget .item-list > .featured-img img {
    border: 1px solid #a1c8e2;
    height: 70px;
    width: 90px;
}

.new-widget .item-list > .featured-img > .right-content {
    margin-left: 10px;
}

.new-widget .item-list > .button-cta {
    text-align: center;
}
@media screen and (max-width: 480px),
    (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px),
    (max-width: 480px) and (min-resolution: 120dpi) {
    .new-widget > .title {
        background-color: #3ba7d2;
        height: 35px;
    }

    .new-widget .item-list {
        border: none;
        padding: 0;
    }

    .new-widget .item-list > li:last-child {
        border-bottom: 1px dotted #dbdcdd;
    }
}

.widget-header.ctl-bar .ctl-tab {
    border: none !important;
}
