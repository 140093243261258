/**
* An auto generated set of font import definitions.
* @author A TSR Developer
* @device all
*/



/*
 * Web Fonts from fontspring.com
 *
 * All OpenType features and all extended glyphs have been removed.
 * Fully installable fonts can be purchased at http://www.fontspring.com
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Fontspring. The fonts are protected under domestic and international trademark and
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2010-2012 Fontspring
 *
 * The fonts included are copyrighted by the vendor listed below.
 *
 * Vendor:      exljbris
 * License URL: http://www.fontspring.com/fflicense/exljbris
 */

/* Museo Sans Cyrillic 900 */
@font-face {
    font-family: "Museo Sans Cyrillic";
    src: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/museo/MuseoSansCyrl-900.eot");
    src: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/museo/MuseoSansCyrl-900.eot?#iefix") format("embedded-opentype"),
         url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/museo/MuseoSansCyrl-900.woff2") format("woff2"),
         url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/museo/MuseoSansCyrl-900.woff") format("woff"),
         url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/museo/MuseoSansCyrl-900.ttf") format("truetype"),
         url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/museo/MuseoSansCyrl-900.svg#museo_sans_cyrl_900regular") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* Changing to Gibson in case any html is missed */
.museo {
    font-family: "Gibson", Verdana, Arial, Geneva, Lucida, sans-serif;
}

.gibson-semibold {
    font-weight: bold;
}

/* Gibson Regular */
@font-face {
    font-family: "Gibson";
    src: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/gibson/regular/gibson-regular.eot");
    src: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/gibson/regular/gibson-regular.eot?#iefix") format("embedded-opentype"),
         url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/gibson/regular/gibson-regular.woff2") format("woff2"),
         url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/gibson/regular/gibson-regular.woff") format("woff"),
         url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/gibson/regular/gibson-regular.ttf") format("truetype"),
         url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/gibson/regular/gibson-regular.svg#GibsonRegular") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* Gibson Semibold */
@font-face {
    font-family: "Gibson";
    src: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/gibson/semibold/gibson-semibold.eot");
    src: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/gibson/semibold/gibson-semibold.eot?#iefix") format("embedded-opentype"),
         url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/gibson/semibold/gibson-semibold.woff2") format("woff2"),
         url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/gibson/semibold/gibson-semibold.woff") format("woff"),
         url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/gibson/semibold/gibson-semibold.ttf") format("truetype"),
         url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/gibson/semibold/gibson-semibold.svg#GibsonSemiBold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

/* Gibson Italic */
@font-face {
    font-family: "Gibson";
    src: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/gibson/italic/gibson-italic.eot");
    src: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/gibson/italic/gibson-italic.eot?#iefix") format("embedded-opentype"),
         url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/gibson/italic/gibson-italic.woff2") format("woff2"),
         url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/gibson/italic/gibson-italic.woff") format("woff"),
         url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/gibson/italic/gibson-italic.ttf") format("truetype"),
         url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/gibson/italic/gibson-italic.svg#GibsonRegular") format("svg");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

/* Gibson Semibold Italic */
@font-face {
    font-family: "Gibson";
    src: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/gibson/semibold-italic/gibson-semibold-italic.eot");
    src: url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/gibson/semibold-italic/gibson-semibold-italic.eot?#iefix") format("embedded-opentype"),
         url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/gibson/semibold-italic/gibson-semibold-italic.woff2") format("woff2"),
         url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/gibson/semibold-italic/gibson-semibold-italic.woff") format("woff"),
         url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/gibson/semibold-italic/gibson-semibold-italic.ttf") format("truetype"),
         url("//static.thestudentroom.co.uk/67e2a42d66799/forum/fonts/gibson/semibold-italic/gibson-semibold-italic.svg#GibsonSemiBold") format("svg");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}
